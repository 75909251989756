import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { SubSink } from 'subsink';
import { Page1 } from '../ECMPage1';
import { Page10 } from '../ECMPage10';
import { Page2 } from '../ECMPage2';
import { Page3 } from '../ECMPage3';
import { Page4 } from '../ECMPage4';
import { Page5 } from '../ECMPage5';
import { Page6 } from '../ECMPage6';
import { Page7 } from '../ECMPage7';
import { Page8 } from '../ECMPage8';
import { Page9 } from '../ECMPage9';
import { manipulateData } from '../related-content';
import { FEEDOUT_AIR_DATE_FILTERS, FEEDOUT_API_FILTERS, FEEDOUT_COLUMNS, FEEDOUT_DEFAULT_TABLE_PARAMS, FEEDOUT_REQUEST_STATUS_DETAILS, FEEDOUT_STATUS_FILTERS, TableColumns } from '../related-content-utils';


@Component({
  selector: 'feedout-related-content',
  templateUrl: './feedout-related-content.component.html',
  styleUrls: ['./feedout-related-content.component.scss'],
})
export class FeedoutRelatedContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  // Story information
  @Input() storyId: number = 0;

  @Input() relatedContentHeight: number = 0;

  @Input() relatedContentTop: number = 0;

  @Output() totalRecords: EventEmitter<number> = new EventEmitter<number>();

  isLoaded: boolean = true;

  relatedContentData: any = [];

  headerHeight: number = 0;

  tableHeight: number = 0;

  heightToBeRemoved: number = 0;

  preferenceColumns: { [key: string]: TableColumns } = {};

  columns: any;

  private subs = new SubSink();

  submittedDateSortOrder: string = null;
  apiQueryFilters: any;
  tableParams: any;
  statusFilters: any;
  requestStatusDetails: any;
  submittedDateFilters: any;

  idSearchVisible: boolean = false;
  idSearchText: string = '';

  showOrProjectSearchVisible: boolean = false;
  showOrProjectSearchText: string = '';

  requesterSearchVisible: boolean = false;
  requesterSearchText: string = '';

  destinationSearchVisible: boolean = false;
  destinationSearchText: string = '';

  mediaIdSearchVisible: boolean = false;
  mediaIdSearchText: string = '';

  constructor(
    private breakpointService: BreakpointService,
    private cService: CommonService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {

    this.submittedDateFilters = JSON.parse(JSON.stringify(FEEDOUT_AIR_DATE_FILTERS));

    this.apiQueryFilters = JSON.parse(JSON.stringify(FEEDOUT_API_FILTERS));

    this.tableParams = JSON.parse(JSON.stringify(FEEDOUT_DEFAULT_TABLE_PARAMS));

    this.statusFilters = JSON.parse(JSON.stringify(FEEDOUT_STATUS_FILTERS));

    this.requestStatusDetails = JSON.parse(JSON.stringify(FEEDOUT_REQUEST_STATUS_DETAILS));

    this.loadColumns();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.storyId && changes.storyId.currentValue) {

      this.loadRelatedContent();

    }

  }

  ngAfterViewChecked() {

    this.windowResizeEvent();

  }


  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    this.heightToBeRemoved = (16 * 3) + (24 * 2) + (40 * 2);

    this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Make API call to get the related content for the story
   *
   */
  loadRelatedContent() {

    this.isLoaded = false;

    const queryStr = `/1729084383048`;

    let apiResponse = this.cService.serviceRequestCommon('get', environment.getStoriesAPI, queryStr);

    apiResponse.subscribe((res: any) => {

      let response: any;

      let tempPage = this.tableParams.page > 10 ? this.tableParams.page % 10 : this.tableParams.page;

      switch (tempPage) {
        case 1:
          response = Page1;
          break;
        case 2:
          response = Page2;
          break;
        case 3:
          response = Page3;
          break;
        case 4:
          response = Page4;
          break;
        case 5:
          response = Page5;
          break;
        case 6:
          response = Page6;
          break;
        case 7:
          response = Page7;
          break;
        case 8:
          response = Page8;
          break;
        case 9:
          response = Page9;
          break;
        case 10:
          response = Page10;
          break;
      }

      let results = response?.results || [];

      if (Array.isArray(results)) {

        manipulateData(results);

        this.relatedContentData = [...results];

        // console.log('related Content', { relatedContent: this.data, response });

        this.tableParams.total = response.total || 0;

        this.totalRecords.emit(this.tableParams.total);

      }

    }, (error: any) => {

      console.error('Load Related Content', error);

      this.toastService.createMessage('error', 'Error while loading related content. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }


  /**
    * Changing pages
    * @param page {number}
    *
    */
  onChangePage(page: number) {

    this.tableParams.page = page;
    this.apiQueryFilters.start = ((page - 1) * this.apiQueryFilters.count) + 1;

    this.loadRelatedContent();

  }

  /**
   * Changing number of items to display per page
   * @param limit {number}
   *
   */
  onChangePageSize(limit: number) {

    this.tableParams.limit = limit;
    this.apiQueryFilters.count = limit;

    // If Page size changed then we need to reset page index as 1 as well start also
    const page = 1;
    this.tableParams.page = page;
    this.apiQueryFilters.start = ((page - 1) * this.apiQueryFilters.count) + 1;

    this.loadRelatedContent();
  }

  navigationTo(id: string) {

  }



  loadColumns() {

    this.preferenceColumns = {};

    this.columns = JSON.parse(JSON.stringify(FEEDOUT_COLUMNS));;

    this.columns.forEach((column: TableColumns) => {
      this.preferenceColumns[column.value] = column;
    });

  }

  typeFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.Type = [];
    else
      this.apiQueryFilters.Type = event;

    this.loadRelatedContent();

  }

  statusFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.Status = [];
    else
      this.apiQueryFilters.Status = event;

    this.loadRelatedContent();

  }

  submittedDateFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.submittedDate = [];
    else
      this.apiQueryFilters.submittedDate = event;

    this.loadRelatedContent();

  }

  submittedDateSortEvent(event: any) {

    if (event === null && this.apiQueryFilters.sortField !== 'submittedDate') return;
    this.resetTableParams();

    if (event == null) {
      delete this.apiQueryFilters.sortField;
      delete this.apiQueryFilters.sortOrder;
    } else {
      this.apiQueryFilters.sortField = 'submittedDate';
      this.apiQueryFilters.sortOrder = (event == 'ascend') ? 'ascending' : 'descending';
    }

    this.submittedDateSortOrder = event;

    this.loadRelatedContent();
  }

  resetTableParams() {

    const currentTableParams = JSON.parse(JSON.stringify(this.tableParams));

    let defaultTableParams = JSON.parse(JSON.stringify(FEEDOUT_DEFAULT_TABLE_PARAMS));

    defaultTableParams.total = currentTableParams.total;

    this.tableParams = JSON.parse(JSON.stringify(defaultTableParams));

  }

  /** 
   * Search for a guest
  */
  searchId(): void {
    this.resetTableParams();
    this.idSearchVisible = false;
    if (this.idSearchText.length === 0) {
      delete this.apiQueryFilters.filter.requestorName;
    } else {
      this.apiQueryFilters.filter.requestorName = this.idSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetIdSearch(): void {
    this.idSearchVisible = false;
    this.idSearchText = '';
    delete this.apiQueryFilters.filter.requestorName;
    this.loadRelatedContent();
  }

  /** 
  * Search for a show
 */
  searchShowOrProject(): void {
    this.resetTableParams();
    this.showOrProjectSearchVisible = false;
    if (this.showOrProjectSearchText.length === 0) {
      delete this.apiQueryFilters.filter.requestorName;
    } else {
      this.apiQueryFilters.filter.requestorName = this.showOrProjectSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetShowOrProjectSearch(): void {
    this.showOrProjectSearchVisible = false;
    this.showOrProjectSearchText = '';
    delete this.apiQueryFilters.filter.showOrProject;
    this.loadRelatedContent();
  }


  /**
   * Search for a Requester
   */
  searchRequester(): void {
    this.resetTableParams();
    this.requesterSearchVisible = false;
    if (this.requesterSearchText.length === 0) {
      delete this.apiQueryFilters.filter.requestorName;
    } else {
      this.apiQueryFilters.filter.requestorName = this.requesterSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetRequesterSearch(): void {
    this.requesterSearchVisible = false;
    this.requesterSearchText = '';
    delete this.apiQueryFilters.filter.requestorName;
    this.loadRelatedContent();
  }


  /**
 * Search for a destination
 */
  searchDestination(): void {
    this.resetTableParams();
    this.destinationSearchVisible = false;
    if (this.destinationSearchText.length === 0) {
      delete this.apiQueryFilters.filter.destinationName;
    } else {
      this.apiQueryFilters.filter.destinationName = this.destinationSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetDestinationSearch(): void {
    this.destinationSearchVisible = false;
    this.destinationSearchText = '';
    delete this.apiQueryFilters.filter.destinationName;
    this.loadRelatedContent();
  }


  /**
* Search for a Media Id
*/
  searchMediaId(): void {
    this.resetTableParams();
    this.mediaIdSearchVisible = false;
    if (this.mediaIdSearchText.length === 0) {
      delete this.apiQueryFilters.filter.mediaIdName;
    } else {
      this.apiQueryFilters.filter.mediaIdName = this.mediaIdSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetMediaIdSearch(): void {
    this.mediaIdSearchVisible = false;
    this.mediaIdSearchText = '';
    delete this.apiQueryFilters.filter.mediaIdName;
    this.loadRelatedContent();
  }



}


