export const GUEST_BOOKING_COLUMNS: any = [
    {
        text: 'Date',
        value: 'DATE',
        width: 110,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Time',
        value: 'TIME',
        width: 104,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'App',
        value: 'APP',
        width: 78,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Guest',
        value: 'GUEST',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show',
        value: 'SHOW',
        width: 349,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Segment',
        value: 'SEGMENT',
        width: 349,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },

];

export const GUEST_BOOKING_API_FILTERS = {
    "filter": {},
    "boolfilter": {},
    "intfilter": {}, "daterange": {},
    "status": [1, 2, 3, 4, 5, 6],
    "showunit": [],
    "loginName": "",
    "search": "", "start": 1,
    "deviceStatus": [],
    "requestType": ["Feed Out", "File Ingest", "MTD"],
    "cameraType": [],
    "source": [], "count": 20,
    "editType": [],
    "division": [],
    "destination": [],
    "stratusFFTranscodeComplete": [],
    "avidFFIngestComplete": [],
    "stratusFFIngestComplete": [],
    "enteredStorm": [],
    "producerOrEditor": [],
    "location": []
}

export const GUEST_BOOKING_APP_FILTERS = [
    { text: 'Recurring', value: 'Recurring value', byDefault: false },
    { text: 'Cancelled', value: 'Cancelled Iteration value', byDefault: false }
];

export enum GUEST_BOOKING_METRICS {
    'ALL' = 'All',
    'BOOKINGS' = 'Bookings',
    // 'CALLOUTS' = 'Callouts',
    'APPEARANCES' = 'Appearances'
}

export const GUEST_BOOKING_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};

export const columns: any = [
    {
        text: 'TYPE',
        value: 'TYPE',
        width: 240,
        checked: true,
        rowSpan: 2,
        colSpan: 0,
        superHeader: '250px',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }, ,
    {
        text: 'ID/Status',
        value: 'REQUESTID',
        width: 240,
        checked: true,
        rowSpan: 2,
        colSpan: 0,
        superHeader: '250px',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Submitted Date',
        value: 'SUBMITTED',
        width: 190,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Air Date',
        value: 'AIRDATE',
        width: 190,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Destination(s)',
        value: 'DESTINATION',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Media Id',
        value: 'MEDIAID',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester(s)',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: 'Production Team',
        extraSpan: true,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Producer(s)',
        value: 'PRODUCER',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project(s)',
        value: 'SHOWUNIT',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Shoot Date',
        value: 'SHOOTDATE',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Shoot Time',
        value: 'SHOOTTIME',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Location',
        value: 'LOCATION',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Crew Count',
        value: 'CREWCOUNT',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Date',
        value: 'DATE',
        width: 104,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Time',
        value: 'TIME',
        width: 104,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'App',
        value: 'APP',
        width: 104,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Guest',
        value: 'GUEST',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show',
        value: 'SHOW',
        width: 349,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Segment',
        value: 'SEGMENT',
        width: 349,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },

];




export const CREW_TYPE_FILTERS = [
    { text: 'NBC News Crew', value: 'NBC News Crew', byDefault: false },
    { text: 'Breaking News Crew', value: 'Breaking News Crew', byDefault: false },
    { text: 'CNBC Crew', value: 'CNBC Crew', byDefault: false },
    { text: 'Telemundo Crew', value: 'Telemundo Crew', byDefault: false },
    { text: 'Digital Journalist / DJ Shoot Crew', value: 'Digital Journalist / DJ Shoot Crew', byDefault: false },
];

export const CREW_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'Efforting', value: 'Efforting', byDefault: false },
    { text: 'ROFR', value: 'ROFR', byDefault: false },
];

export const CREW_COLUMNS: any = [
    {
        text: 'Type',
        value: 'TYPE',
        width: 180,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 196,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Start',
        value: 'STARTDATE',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Location',
        value: 'LOCATION',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Crew Count',
        value: 'CREWCOUNT',
        width: 128,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },

];

export const CREW_API_FILTERS = {
    "filter": {},
    "boolfilter": {},
    "intfilter": {}, "daterange": {},
    "status": [1, 2, 3, 4, 5, 6],
    "showunit": [],
    "loginName": "",
    "search": "", "start": 1,
    "deviceStatus": [],
    "requestType": ["Feed Out", "File Ingest", "MTD"],
    "cameraType": [],
    "source": [], "count": 20,
    "editType": [],
    "division": [],
    "destination": [],
    "stratusFFTranscodeComplete": [],
    "avidFFIngestComplete": [],
    "stratusFFIngestComplete": [],
    "enteredStorm": [],
    "producerOrEditor": [],
    "location": []
}

export enum requestStatus {
    queue = 'QUEUE',
    new = 'NEW',
    inprogress = 'IN PROGRESS',
    revised = 'REVISED',
    completed = 'COMPLETED',
    confirmcancellation = 'CONFIRM CANCELLATION',
    cancelled = 'CANCELLED',
    fulfilled = 'FULFILLED'
};

export const CREW_REQUEST_STATUS_DETAILS: any = {
    '0': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    '1': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    '2': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    '3': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    '4': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    '5': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    '6': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const CREW_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};



export const EDIT_TYPE_FILTERS = [
    { text: 'Standard', value: 'Standard', byDefault: false },
    { text: 'Long Form', value: 'Long Form', byDefault: false },
    { text: 'Crash', value: 'Crash', byDefault: false }
];

export const EDIT_AIR_DATE_FILTERS = [
    { text: 'TBD', value: 'TBD', byDefault: false },
];

export const EDIT_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'In Progress', value: 'In Progress', byDefault: false },
    { text: 'Completed', value: 'Completed', byDefault: false },
];

export const EDIT_COLUMNS: any = [
    {
        text: 'Type',
        value: 'TYPE',
        width: 218,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 205,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Air Date',
        value: 'AIRDATE',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Producer',
        value: 'PRODUCER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'SrProducer',
        value: 'SRPRODUCER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }
];

export const EDIT_API_FILTERS = {
    "filter": {},
    "boolfilter": {},
    "intfilter": {}, "daterange": {},
    "status": [1, 2, 3, 4, 5, 6],
    "showunit": [],
    "loginName": "",
    "search": "", "start": 1,
    "deviceStatus": [],
    "requestType": ["Feed Out", "File Ingest", "MTD"],
    "cameraType": [],
    "source": [], "count": 20,
    "editType": [],
    "division": [],
    "destination": [],
    "stratusFFTranscodeComplete": [],
    "avidFFIngestComplete": [],
    "stratusFFIngestComplete": [],
    "enteredStorm": [],
    "producerOrEditor": [],
    "location": []
}
export const EDIT_REQUEST_STATUS_DETAILS: any = {
    '0': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    '1': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    '2': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    '3': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    '4': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    '5': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    '6': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const EDIT_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};




export const FILE_INGEST_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'In Progress', value: 'In Progress', byDefault: false },
    { text: 'Completed', value: 'Completed', byDefault: false },
];

export const FILE_INGEST_COLUMNS: any = [
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 205,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Destination',
        value: 'DESTINATION',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Submitted',
        value: 'SUBMITTED',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Modified',
        value: 'MODIFIED',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Air Date',
        value: 'AIRDATE',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
];

export const FILE_INGEST_API_FILTERS = {
    "filter": {},
    "boolfilter": {},
    "intfilter": {}, "daterange": {},
    "status": [1, 2, 3, 4, 5, 6],
    "showunit": [],
    "loginName": "",
    "search": "", "start": 1,
    "deviceStatus": [],
    "requestType": ["Feed Out", "File Ingest", "MTD"],
    "cameraType": [],
    "source": [], "count": 20,
    "editType": [],
    "division": [],
    "destination": [],
    "stratusFFTranscodeComplete": [],
    "avidFFIngestComplete": [],
    "stratusFFIngestComplete": [],
    "enteredStorm": [],
    "producerOrEditor": [],
    "location": []
}
export const FILE_INGEST_REQUEST_STATUS_DETAILS: any = {
    '0': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    '1': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    '2': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    '3': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    '4': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    '5': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    '6': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const FILE_INGEST_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};




export const FEEDOUT_AIR_DATE_FILTERS = [
    { text: 'TBD', value: 'TBD', byDefault: false },
];

export const FEEDOUT_STATUS_FILTERS = [
    { text: 'New', value: 'New', byDefault: false },
    { text: 'Revised', value: 'Revised', byDefault: false },
    { text: 'In Progress', value: 'In Progress', byDefault: false },
    { text: 'Completed', value: 'Completed', byDefault: false },
];

export const FEEDOUT_COLUMNS: any = [
    {
        text: 'ID / Status',
        value: 'IDSTATUS',
        width: 205,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Submitted',
        value: 'SUBMITTED',
        width: 120,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Destination',
        value: 'DESTINATION',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Media Id',
        value: 'MEDIAID',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Show / Project',
        value: 'SHOWPROJECT',
        width: 280,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }
];

export const FEEDOUT_API_FILTERS = {
    "filter": {},
    "boolfilter": {},
    "intfilter": {}, "daterange": {},
    "status": [1, 2, 3, 4, 5, 6],
    "showunit": [],
    "loginName": "",
    "search": "", "start": 1,
    "deviceStatus": [],
    "requestType": ["Feed Out", "File Ingest", "MTD"],
    "cameraType": [],
    "source": [], "count": 20,
    "editType": [],
    "division": [],
    "destination": [],
    "stratusFFTranscodeComplete": [],
    "avidFFIngestComplete": [],
    "stratusFFIngestComplete": [],
    "enteredStorm": [],
    "producerOrEditor": [],
    "location": []
}
export const FEEDOUT_REQUEST_STATUS_DETAILS: any = {
    '0': { name: requestStatus.queue, index: 0, color: '#D4380D', class: 'queue', icon: 'close-circle' },
    '1': { name: requestStatus.new, index: 1, color: '#EB2F96', class: 'new', icon: 'star' },
    '2': { name: requestStatus.inprogress, index: 2, color: '#1890FF', class: 'in-progress', icon: 'sync' },
    '3': { name: requestStatus.revised, index: 3, color: '#FA8C16', class: 'revised', icon: 'exclamation-circle' },
    '4': { name: requestStatus.completed, index: 4, color: '#52C41A', class: 'completed', icon: 'check-circle' },
    '5': { name: requestStatus.confirmcancellation, index: 5, color: '#808080', class: 'confirm-cancellation', icon: 'close-circle' },
    '6': { name: requestStatus.cancelled, index: 6, color: '#000000', class: 'cancelled', icon: 'close-circle' }
};

export const FEEDOUT_DEFAULT_TABLE_PARAMS = {
    page: 1,
    total: 10,
    limit: 10,
};


export interface TableColumns {
    text: string,
    value: string,
    width: number,
    checked: boolean,
    rowSpan: number,
    colSpan: number,
    superHeader: string,
    extraSpan: boolean,
    reporting: boolean,
    firstLevelHeader: boolean
}

