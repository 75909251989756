<nz-layout
   class="story-landing"
   [attr.data-component]="'ncx/stories/landing'">
   <nz-layout class="main-content">
      <div
         id="storyHeader"
         class="header-container">
         <div class="story-header">
            <!-- STORY TITLE -->
            <div
               class="title"
               *ngIf="storyLandingDetails?.storyTitle">
               <div class="text-container">
                  <button
                     (click)="goBack()"
                     class="back">
                     <i
                        nz-icon
                        nzType="arrow-left"
                        nzTheme="outline"></i>
                  </button>

                  <app-story-status
                     [showStatusText]="false"
                     [status]="storyLandingDetails?.storyState"></app-story-status>
                  <span
                     class="text"
                     [innerHtml]="storyLandingDetails?.storyTitle | safeHtml"></span>
               </div>

               <!-- SCROLL UP BUTTON -->
               <button
                  nz-button
                  *ngIf="isSticky"
                  class="story-arrow"
                  (click)="scrollTop()">
                  <i
                     nz-icon
                     nzType="vertical-align-top"
                     nzTheme="outline"></i>
               </button>
            </div>

            <!-- STORY DESCIPTION -->
            <div
               class="desc"
               *ngIf="!isSticky && storyLandingDetails?.storyContent">
               {{ storyLandingDetails.storyPlaintContent }}
            </div>

            <!-- HEADER BUTTONS -->
            <div class="header-buttons">
               <nz-button-group
                  class="button-group"
                  *ngIf="!isSticky && storyLandingDetails">
                  <!-- FOLLOW BUTTON -->
                  <app-follow-story
                     *ngIf="storyLandingDetails.storyId"
                     [isFollowing]="storyLandingDetails.isUserFollowing"
                     [storyId]="storyLandingDetails.storyId"
                     [showFollowCount]="false"
                     [showFollowStatus]="isMobile ? false : true"
                     (updatedFollower)="updateFollowStatus($event)"></app-follow-story>

                  <!-- FOLLOWER COUNT -->
                  <button
                     nz-button
                     class="story-button followers groupbutton"
                     (click)="viewFollowers()">
                     <i
                        class="icon"
                        nz-icon
                        nzType="team"
                        nzTheme="outline"></i>
                     <span *ngIf="!isMobile">{{ storyLandingDetails?.storyFollowerCount }}</span>
                  </button>

                  <button
                     nz-button
                     class="story-button story-details"
                     (click)="viewStoryDetails()">
                     <i
                        class="icon"
                        nz-icon
                        nzType="file-text"
                        nzTheme="outline"></i>
                     <span *ngIf="!isMobile">Story Details</span>
                  </button>
               </nz-button-group>
               <div
                  class="push-right"
                  *ngIf="storyLandingDetails?.autoGeneratedStoryId">
                  {{ storyLandingDetails?.autoGeneratedStoryId }}
                  <i
                     class="copy-icon"
                     nz-icon
                     nzType="copy"
                     nzTheme="outline"
                     (click)="copyStoryIdInClipboard(storyLandingDetails.autoGeneratedStoryId)"></i>
               </div>
            </div>
         </div>
      </div>

      <nz-content>
         <!-- POSTS/ANGLES TABS -->
         <div class="desktop-header">
            <nz-tabset
               class="tabs"
               [(nzSelectedIndex)]="selectedTab"
               (nzSelectedIndexChange)="tabMenuChanges($event)">
               <!-- POSTS TAB -->
               <nz-tab nzTitle="Posts">
                  <div *ngIf="selectedTab === 0">
                     <ng-container [ngTemplateOutlet]="storyPostsTpl"></ng-container>
                  </div>
               </nz-tab>

               <!-- ANGLES TAB -->
               <nz-tab nzTitle="Angles">
                  <div *ngIf="selectedTab === 1">
                     <ng-container [ngTemplateOutlet]="storyAnglesTpl"></ng-container>
                  </div>
               </nz-tab>

               <nz-tab nzTitle="Related Content">
                  <div *ngIf="selectedTab === 2">
                     <ng-container [ngTemplateOutlet]="storyRelatedContentTpl"></ng-container>
                  </div>
               </nz-tab>
            </nz-tabset>
         </div>

         <!-- FOOTER -->
         <nz-footer class="footer-container">
            <div class="footer">
               <div class="left-side">
                  <button
                     nz-button
                     [nzShape]="!isMobile ? 'round' : 'circle'"
                     (click)="toggleInfoCenter()">
                     <i
                        nz-icon
                        nzType="info-circle"
                        nzTheme="outline"></i>
                     <span>Infocenter</span>
                  </button>
               </div>

               <div class="right-side">
                  <a
                     nz-button
                     (click)="addPostViaEmail()"
                     href="{{ getEmail() }}"
                     *ngIf="canAddPostViaEmail"
                     [nzShape]="!isMobile ? 'round' : 'circle'">
                     <i
                        nz-icon
                        nzType="mail"
                        nzTheme="outline"></i>
                     <span *ngIf="!isMobile">Add Post via Email</span>
                  </a>

                  <button
                     *ngIf="canCreatePost"
                     [class.add-post-btn]="!canAddPostViaEmail"
                     nz-button
                     nzShape="round"
                     nzType="primary"
                     (click)="addPost()">
                     <i
                        nz-icon
                        nzType="plus-circle"
                        nzTheme="outline"></i>
                     Add New
                  </button>
               </div>
            </div>
         </nz-footer>
      </nz-content>
   </nz-layout>

   <!-- DESKTOP FILTER DRAWER -->
   <nz-sider
      *ngIf="!isMobile && isFilterDrawerOpen && !(selectedTab === 2)"
      class="filters-desktop"
      nzCollapsible
      nzCollapsedWidth="0"
      [nzWidth]="300"
      [nzTrigger]="null">
      <div class="right-sider">
         <ng-container *ngIf="selectedTab === 0">
            <ng-container [ngTemplateOutlet]="postFilterDrawer"></ng-container>
         </ng-container>

         <ng-container *ngIf="selectedTab === 1">
            <ng-container [ngTemplateOutlet]="angleFilterDrawer"></ng-container>
         </ng-container>
      </div>
   </nz-sider>
</nz-layout>

<!-- INFO CENTER MODAL -->
<app-infocenter-research
   [Id]="storyId"
   [isinfocenterVisible]="infoCenterVisible"
   [storyTitle]="storyLandingDetails?.storyTitle"
   (closeinfoCenter)="toggleInfoCenter()"></app-infocenter-research>

<!-- MOBILE FILTER DRAWER -->
<nz-drawer
   *ngIf="isMobile"
   nzPlacement="right"
   nzWrapClassName="filters-mobile"
   [nzWidth]="300"
   [nzMaskClosable]="true"
   [nzClosable]="false"
   (nzOnClose)="toggleFilterDrawer()"
   [nzVisible]="isFilterDrawerOpen">
   <ng-container *nzDrawerContent>
      <ng-container *ngIf="selectedTab === 0">
         <ng-container [ngTemplateOutlet]="postFilterDrawer"></ng-container>
      </ng-container>
      <ng-container *ngIf="selectedTab === 1">
         <ng-container [ngTemplateOutlet]="angleFilterDrawer"></ng-container>
      </ng-container>
   </ng-container>
</nz-drawer>

<!-- STORY POST -->
<ng-template #storyPostsTpl>
   <app-story-posts
      [filters]="postFilters"
      [storyId]="storyId"
      [standards]="standards"
      [footerHeight]="footerHeight"
      (isScrolling)="onScrollContainer()"
      (selectLabel)="selectLabel($event.label, { checked: $event.checked, disable: $event.disable })"
      (toggleFilterDrawer)="toggleFilterDrawer()"></app-story-posts>
</ng-template>

<!-- POST -->
<ng-template #storyAnglesTpl>
   <app-story-angles
      [filters]="angleFilters"
      [storyId]="storyId"
      [footerHeight]="footerHeight"
      (isScrolling)="onScrollContainer()"
      (toggleFilterDrawer)="toggleFilterDrawer()"></app-story-angles>
</ng-template>

<!-- POST FILTERS -->
<ng-template #postFilterDrawer>
   <div class="sider-close">
      <span>Filters</span>
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"
         (click)="toggleFilterDrawer()"></i>
   </div>
   <app-scroll-container
      [sideBarContainer]="true"
      additionalPadding="2">
      <ng-template bodyTemplate>
         <div class="sider-content">
            <!-- EDITORIAL/STANDARD OPTIONS -->
            <nz-collapse
               nzBordered="false"
               nzExpandIconPosition="right"
               class="mb-24">
               <nz-collapse-panel
                  [nzHeader]="editorialStandardsHeader"
                  nzActive="true"
                  class="elements">
                  <ul class="editor-standard">
                     <li *ngFor="let opt of filterOptions.markAsType">
                        <label
                           nz-checkbox
                           [ngModel]="opt.checked"
                           [nzDisabled]="opt.disabled"
                           (ngModelChange)="selectLabel(opt.value, { checked: $event })">
                           {{ opt.label }}
                        </label>
                     </li>
                  </ul>
               </nz-collapse-panel>
            </nz-collapse>

            <!-- RC/LEGAL OPTIONS -->
            <nz-collapse
               nzExpandIconPosition="right"
               nzBordered="false"
               class="mb-24">
               <nz-collapse-panel
                  [nzHeader]="rcLegalHeader"
                  nzActive="true"
                  class="elements">
                  <ul class="editor-standard">
                     <li *ngFor="let opt of filterOptions.statusType">
                        <label
                           nz-checkbox
                           [ngModel]="opt.checked"
                           [nzDisabled]="opt.disabled"
                           (ngModelChange)="selectLabel(opt.value, { checked: $event })">
                           {{ opt.label }}
                        </label>
                     </li>
                  </ul>
               </nz-collapse-panel>
            </nz-collapse>

            <!-- SORT BY DATE -->
            <nz-collapse
               nzExpandIconPosition="right"
               nzBordered="false"
               class="margin-space">
               <nz-collapse-panel
                  [nzHeader]="sortByTpl"
                  nzActive="true"
                  class="elements">
                  <nz-radio-group
                     class="sort-by"
                     [ngModel]="postFilters.controls['sort'].value">
                     <ng-container *ngFor="let opt of postFilterOrder">
                        <label
                           nz-radio
                           [nzValue]="opt.value"
                           (click)="selectSortFilter(opt.value)">
                           {{ opt.label }}
                        </label>
                     </ng-container>
                  </nz-radio-group>
               </nz-collapse-panel>
            </nz-collapse>
            <div class="mb-24"></div>

            <!-- DATE RANGE PICKER -->
            <nz-collapse
               nzExpandIconPosition="right"
               nzBordered="false"
               class="mb-24">
               <nz-collapse-panel
                  [nzHeader]="dateTpl"
                  nzActive="true"
                  class="elements">
                  <nz-range-picker
                     [nzDateRender]="tplRender"
                     [ngModel]="postFilters.controls['date'].value"
                     (ngModelChange)="selectDateFilter($event)"></nz-range-picker>
                  <ng-template
                     #tplRender
                     let-current>
                     <div class="ant-picker-cell-inner">
                        {{ current.getDate() }}
                     </div>
                  </ng-template>
               </nz-collapse-panel>
            </nz-collapse>

            <!-- CLEAR BUTTON -->
            <button
               class="mb-24 clear-btn"
               nz-button
               nzBlock
               (click)="resetPostFilters()">
               <span
                  nz-icon
                  nzType="clear"
                  nzTheme="outline"></span>
               Clear
            </button>
         </div>
      </ng-template>
   </app-scroll-container>
</ng-template>

<!-- ANGLE FILTERS -->
<ng-template #angleFilterDrawer>
   <div class="sider-close">
      <span>Filters</span>
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"
         (click)="toggleFilterDrawer()"></i>
   </div>

   <app-scroll-container [sideBarContainer]="true">
      <ng-template bodyTemplate>
         <div class="sider-content">
            <form
               [formGroup]="angleFilters"
               (keydown.enter)="$event.preventDefault()"
               class="filter-form">
               <!-- SORT BY DATE -->
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="margin-space">
                  <nz-collapse-panel
                     [nzHeader]="sortByTpl"
                     nzActive="true"
                     class="elements">
                     <nz-radio-group
                        class="sort-by"
                        formControlName="sort">
                        <ng-container *ngFor="let obj of angleFilterOrder">
                           <label
                              nz-radio
                              [nzValue]="obj.value">
                              {{ obj.label }}
                           </label>
                        </ng-container>
                     </nz-radio-group>
                  </nz-collapse-panel>
               </nz-collapse>

               <!-- Filter BY PRIVACY -->
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="margin-space">
                  <nz-collapse-panel
                     [nzHeader]="privacyTpl"
                     nzActive="true"
                     class="elements">
                     <nz-radio-group
                        class="sort-by"
                        formControlName="privacy">
                        <ng-container *ngFor="let obj of anglePrivacyOrder">
                           <label
                              nz-radio
                              [nzValue]="obj.value">
                              {{ obj.label }}
                           </label>
                        </ng-container>
                     </nz-radio-group>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="margin-space">
                  <nz-collapse-panel
                     [nzHeader]="authorTpl"
                     nzActive="true"
                     class="elements">
                     <nz-input-group [nzSuffix]="suffixIconSearch">
                        <input
                           nz-input
                           #authorSearch
                           placeholder="Search Author..."
                           name="author"
                           [value]="authorName"
                           (keyup)="searchAuthors(authorSearch.value, $event)"
                           [nzAutocomplete]="autoPeoplePicker" />
                        <ng-template #suffixIconSearch>
                           <i
                              nz-icon
                              (click)="!!authorName ? resetAuthorSearch() : null"
                              [ngClass]="{ 'search-btn': true, 'clear': !!authorName }"
                              [nzType]="!!authorName ? 'close-circle' : 'search'"></i>
                        </ng-template>
                     </nz-input-group>

                     <nz-autocomplete #autoPeoplePicker>
                        <nz-auto-option *ngIf="!isAuthorListLoaded">
                           <i
                              nz-icon
                              nzType="loading"
                              class="loading-icon"></i>
                           Loading Names...
                        </nz-auto-option>

                        <nz-auto-option
                           *ngFor="let author of authorList"
                           (selectionChange)="selectedAuthor($event, author)"
                           [nzValue]="Common.formatName(author.name)"
                           [nzLabel]="Common.formatName(author.name)">
                           {{ Common.formatName(author.name) }}
                        </nz-auto-option>
                     </nz-autocomplete>
                  </nz-collapse-panel>
               </nz-collapse>
               <button
                  class="mb-24 clear-btn"
                  nz-button
                  nzBlock
                  (click)="resetAngleFilters()">
                  <span
                     nz-icon
                     nzType="clear"
                     nzTheme="outline"></span>
                  Clear
               </button>
            </form>
         </div>
      </ng-template>
   </app-scroll-container>
</ng-template>

<ng-template #sortByTpl>
   <span class="filter-collapse-header">
      <i
         nz-icon
         nzType="sort-ascending"
         nzTheme="outline"></i>
      Sort By
   </span>
</ng-template>

<ng-template #privacyTpl>
   <span class="filter-collapse-header">
      <i
         nz-icon
         nzType="eye"
         nzTheme="outline"></i>
      Privacy
   </span>
</ng-template>

<ng-template #authorTpl>
   <span class="filter-collapse-header">
      <i
         nz-icon
         nzType="user"
         nzTheme="outline"></i>
      Author
   </span>
</ng-template>

<ng-template #dateTpl>
   <span class="filter-collapse-header">
      <i
         nz-icon
         nzType="calendar"
         nzTheme="outline"></i>
      Date
   </span>
</ng-template>

<ng-template #editorialStandardsHeader>
   <i
      nz-icon
      nzType="audit"></i>
   Editorial / Standards
</ng-template>

<ng-template #rcLegalHeader>
   <i
      nz-icon
      nzType="trademark"></i>
   R&C / Legal
</ng-template>

<ng-template #storyRelatedContentTpl>
   <app-story-related-content
      [storyId]="storyId"
      [footerHeight]="footerHeight"
      [isVisible]="selectedTab === 2"
      (isScrolling)="onScrollContainer()"></app-story-related-content>
</ng-template>
