import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AngleSearchResult, ISearch } from '@models/ncx/global-search';
import { BreakpointService } from '@services/breakpoint.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

type AngleParams = {
  contentType: 'Angle',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string,
  authorSSO?: number
};

type AnglePayload = {
  angleAccess: string;
};

@Component({
  selector: 'app-angle-results-new',
  templateUrl: './angle-results.component.html',
  styleUrls: ['../search-results-common.scss']
})
export class AngleResultsNewComponent implements OnChanges, OnDestroy {

  @Input() isVisible: boolean = false;

  @Input() allResultsPage: boolean = false;

  // Search Results from API
  searchResults: AngleSearchResult[] = [];

  // Current page of search results
  page: number = 1;

  // Total number of results
  total: number = 0;

  // Number of results per page (API does not allow us to make it more than 10)
  limit: number = 10;

  // Loader
  isLoaded: boolean = true;

  private subs = new SubSink();

  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();

  public readonly Common = Common;

  tabOrder = ['ALL', 'STORIES', 'POSTS', 'ELEMENTS', 'ANGLES', 'GROUPS', 'PEOPLE'];

  angleSortOptions = [
    { key: 'Newest', value: 'descending' },
    { key: 'Oldest', value: 'ascending' }
  ];

  isMobile: boolean = false;

  isCollapsed: boolean = true;

  constructor(
    private toastService: ToastService,
    public searchService: GlobalSearchService,
    private gaService: GoogleAnalyticsEventService,
    public breakpointService: BreakpointService
  ) {

    // When search term changes, load results if component is visible
    this.subs.sink = this.searchService.searchTerm.subscribe(() => {


      // When search term changes, reset results regardless of whether the component is visible or not
      // When the user returns to this tab, a new list of search results will load
      this.resetResults();
      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.loadSearchResults();

      }

    });

    // When filters change, load results if component is visible
    this.subs.sink = this.searchService.filterChanged.subscribe(() => {

      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.resetResults();
        this.loadSearchResults();

      }

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    // When visibility changes, load search results if visible and if the results weren't already loaded
    if ('isVisible' in changes) {

      if (changes.isVisible.currentValue && !this.searchResults.length) {

        this.loadSearchResults();

      }
      if (!changes.isVisible.currentValue) {

        this.cancelRequest.next(true);

      }

    }

  }

  ngOnInit() {
    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });
  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Load search results
   *
   */
  loadSearchResults() {

    this.isLoaded = false;

    const params = this.buildParams();

    const payload = this.buildPayload();

    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&');

    this.searchService.loadSearchResults(queryString, payload).pipe(takeUntil(this.cancelRequest.pipe(filter(value => value === true)))).subscribe({
      next: (res: any) => {

        const results: ISearch = res as ISearch;

        const searchResults = results.angleSearchResult || [];

        this.searchResults = this.page === 1 ? searchResults : [...this.searchResults, ...searchResults];

        this.total = results.angleCount;

        console.log('loadSearchResults', { searchResults: this.searchResults, total: this.total });

        this.searchService.setSuggestedSearch(results.contentSuggestedPhrase);

        this.sendGATracking(params, payload);

      },
      error: (error: any) => {

        this.toastService.createMessage('error', 'Unable to load search results');

        console.error('loadSearchResults', error);

      }
    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * View Angle event
   *
   */
  public viewAngle(angle: AngleSearchResult, index: number) {

    const searchText = this.searchService.searchTerm.value;

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, searchText, 'Selected Angle Index', 'Post Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(this.page, searchText, 'Selected Angle Page Number', 'Post Content');

  }

  /**
   * Build the query string portion of the API call
   *
   */
  buildParams(): AngleParams {

    // API Params
    const params: AngleParams = {
      contentType: 'Angle',
      startIndex: 0,
      endIndex: this.limit,
      sortField: 'modificationdate',
      sortOrder: 'descending'
    };

    try {

      // Start/End Index
      const { start, end } = this.startEndIndex;

      params.startIndex = start;
      params.endIndex = end;

      // Sort & Author
      const { sort, authorSSO } = this.searchService.filters.ANGLES;

      let sortField = 'modificationdate';

      let sortOrder = 'ascending';

      switch (sort) {

        case 'descending':
        case 'ascending':
          sortField = 'modificationdate';
          sortOrder = sort;
          break;

        case 'relevancescore':
          sortField = 'relevancescore';
          sortOrder = '';
          break;

      }

      params.sortOrder = sortOrder;
      params.sortField = sortField;

      // Author
      if (authorSSO) {

        params.authorSSO = authorSSO;

      }

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as AngleParams;

    }

  }

  /**
   * Build payload for API call
   *
   */
  buildPayload(): AnglePayload {

    const payload: AnglePayload = {
      angleAccess: 'all'
    };

    const { privacy } = this.searchService.filters.ANGLES;

    payload.angleAccess = privacy;


    return payload as AnglePayload;

  }

  /**
   * Track Google Analytics
   *
   */
  sendGATracking(params: AngleParams, payload: AnglePayload) {

    const searchTerm = this.searchService.searchTerm.value;

    console.log('Google Analytics Tracking (Angles)', { searchTerm, params, payload });

    this.gaService.trackContentResultsCount(searchTerm, this.total, 'Angle Results Count', 'Angle Content');

    // Relevancy
    this.gaService.trackRecencyRelevancySearchAction(searchTerm, params.sortField === 'relevancescore' ? 'relevancy' : 'recency', this.total);

    // Sort
    if (params.sortOrder) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Sort - ${params.sortOrder}`, this.total, 'Angle Content');

    }

    // Post Author
    if (params.authorSSO) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Author Filter - ${params.authorSSO}`, this.total, 'Angle Content');

    }

  }

  /**
   * On page scroll, load next page
   *
   */
  onScrollDown() {

    console.log('onScrollDown (Angles)');

    if (this.page * this.limit >= this.total) {

      console.log('No More Results');
      return;

    }

    this.page += 1;

    this.cancelRequest.next(false);

    this.loadSearchResults();

  }

  /**
   * Reset all results
   *
   */
  resetResults() {

    this.total = 0;
    this.page = 1;
    this.searchResults = [];

  }

  get resultCount(): string {

    return `${Common.formatNumber(this.total)} Result${this.total !== 1 ? 's' : ''}`;

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }

  onSortOptionChange(sort: string) {

    this.searchService.filters.ANGLES.sort = sort;

    this.searchService.filterChanged.next();
  }

  toggleFilters(): void {

    this.isCollapsed = !this.isCollapsed;

  }

}
