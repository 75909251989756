export const Page7 = {
    "total": 25133,
    "results": [
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265350",
                "creationdate": 1721405557,
                "modificationdate": 1721405558,
                "targetURL": null,
                "storyID": 1721391045828,
                "description": ""
            },
            "fileIngestID": "FI2024265350",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test_Merged_Story_0719_2",
                "storySlugID": "1721391045828",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:42 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:42 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722238920,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Solid State Drive",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Other",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "5",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "We Transfer",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "8",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265350",
                "fileIngestRequestID": "FI2024265350",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721405558,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721405557,
                "createdBy": "Peddina, Sainath",
                "modified": 1721405558,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265348",
                "creationdate": 1721405555,
                "modificationdate": 1721405555,
                "targetURL": null,
                "storyID": 1721391045828,
                "description": ""
            },
            "fileIngestID": "FI2024265348",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Corporate",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test_Merged_Story_0719_2",
                "storySlugID": "1721391045828",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:42 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:42 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722238920,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Bureau NAS",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 1",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Producer Mailbox",
                        "deviceTypeOther": null,
                        "quantity": "4",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "4",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Remote",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "6",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265348",
                "fileIngestRequestID": "FI2024265348",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721405555,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721405555,
                "createdBy": "Peddina, Sainath",
                "modified": 1721405555,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265333",
                "creationdate": 1721405323,
                "modificationdate": 1721405324,
                "targetURL": null,
                "storyID": 1721391045828,
                "description": ""
            },
            "fileIngestID": "FI2024265333",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Acorn",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test_Merged_Story_0719_2",
                "storySlugID": "1721391045828",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:38 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:38 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722238680,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "VHS",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Panasonic GH4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CD",
                        "deviceTypeOther": null,
                        "quantity": "4",
                        "cameraType": [
                            "Panasonic GH4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "4",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Media Shuttle",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Panasonic GH4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "7",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265333",
                "fileIngestRequestID": "FI2024265333",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721405324,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721405323,
                "createdBy": "Peddina, Sainath",
                "modified": 1721405324,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265323",
                "creationdate": 1721403677,
                "modificationdate": 1721403677,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265323",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Universal Kids",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:10 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:10 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722237000,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 5",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 7",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265323",
                "fileIngestRequestID": "FI2024265323",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403677,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403677,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403677,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265311",
                "creationdate": 1721403653,
                "modificationdate": 1721403653,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265311",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Olympics",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:10 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:10 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722237000,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "XDCAM DISC",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 1",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SXS Card",
                        "deviceTypeOther": null,
                        "quantity": "3",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "3",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Hard Drive",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 6",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265311",
                "fileIngestRequestID": "FI2024265311",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403653,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403653,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403653,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265308",
                "creationdate": 1721403652,
                "modificationdate": 1721403652,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265308",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "News Digital",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:10 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:10 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722237000,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 5",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 7",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265308",
                "fileIngestRequestID": "FI2024265308",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403652,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403652,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403652,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265291",
                "creationdate": 1721403602,
                "modificationdate": 1721403603,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265291",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "MSNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:09 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:09 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722236940,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 5",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 7",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265291",
                "fileIngestRequestID": "FI2024265291",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403603,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403602,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403603,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265295",
                "creationdate": 1721403602,
                "modificationdate": 1721403603,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265295",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "NBC News",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:09 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:09 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722236940,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "10",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 10",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "5",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SD",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "8",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265295",
                "fileIngestRequestID": "FI2024265295",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403603,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403602,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403603,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265287",
                "creationdate": 1721403599,
                "modificationdate": 1721403599,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265287",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "CNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:09 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:09 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722236940,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "XDCAM DISC",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 1",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SXS Card",
                        "deviceTypeOther": null,
                        "quantity": "3",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "3",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Hard Drive",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 6",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265287",
                "fileIngestRequestID": "FI2024265287",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403599,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403599,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403599,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265285",
                "creationdate": 1721403598,
                "modificationdate": 1721403598,
                "targetURL": null,
                "storyID": 1721399792999,
                "description": ""
            },
            "fileIngestID": "FI2024265285",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "ERG Events",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST TEST TEST STORY_CET5rtUJhPfC",
                "storySlugID": "1721399792999",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "12:09 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "7:09 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722236940,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "XDCAM DISC",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 1",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SXS Card",
                        "deviceTypeOther": null,
                        "quantity": "3",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "3",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Hard Drive",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 6",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265285",
                "fileIngestRequestID": "FI2024265285",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721403598,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721403598,
                "createdBy": "Peddina, Sainath",
                "modified": 1721403598,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265274",
                "creationdate": 1721397083,
                "modificationdate": 1721397083,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "fileIngestID": "FI2024265274",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "ERG Events",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "10:20 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "5:20 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722230400,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "XDCAM DISC",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 1",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SXS Card",
                        "deviceTypeOther": null,
                        "quantity": "3",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "3",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Hard Drive",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 6",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265274",
                "fileIngestRequestID": "FI2024265274",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721397083,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721397083,
                "createdBy": "Peddina, Sainath",
                "modified": 1721397083,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FI2024265263",
                "creationdate": 1721397081,
                "modificationdate": 1721397081,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "fileIngestID": "FI2024265263",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "MSNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "fiAirDate": null,
                "airDate": 1721586600,
                "airDateTBD": "No",
                "airTime": "10:20 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "5:20 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722230400,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test 2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 5",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " 7",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265263",
                "fileIngestRequestID": "FI2024265263",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721397082,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1721397081,
                "createdBy": "Peddina, Sainath",
                "modified": 1721397081,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002780",
                "creationdate": 1721395682,
                "modificationdate": 1721395682,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002780",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "Telemundo",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395682,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395682,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395682,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002780",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002778",
                "creationdate": 1721395680,
                "modificationdate": 1721395681,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002778",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "Peacock",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395681,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395680,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395681,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002778",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002750",
                "creationdate": 1721395680,
                "modificationdate": 1721395680,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002750",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "Operations",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395680,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395680,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395680,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002750",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002752",
                "creationdate": 1721395680,
                "modificationdate": 1721395680,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002752",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "Olympics",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395680,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395680,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395680,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002752",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002764",
                "creationdate": 1721395680,
                "modificationdate": 1721395680,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002764",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "Universal Kids",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395680,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395680,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395680,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002764",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002743",
                "creationdate": 1721395679,
                "modificationdate": 1721395679,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002743",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "News Digital",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395679,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395679,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395679,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002743",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002737",
                "creationdate": 1721395678,
                "modificationdate": 1721395678,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002737",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:57 PM",
                "airTimeFlexible": "No",
                "division": "NBC Sports",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:57 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395678,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395678,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395678,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002737",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722229020,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002723",
                "creationdate": 1721395595,
                "modificationdate": 1721395595,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002723",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "NBC News Studios",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395595,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395595,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395595,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002723",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        }
    ]
}