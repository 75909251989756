<!-- STORY INFORMATION -->
<div
   class="ncx-story-card"
   data-component="shared/cards/story/search">
   <div class="days-story-id">
      <div class="days">{{ calculateDays(story.createDateTime) }} days</div>
      <div
         *ngIf="story.autoGeneratedStoryId"
         class="story-id">
         {{ story.autoGeneratedStoryId }}
         <i
            class="copy-icon"
            nz-icon
            nzType="copy"
            nzTheme="outline"
            (click)="copyStoryIdInClipboard(story.autoGeneratedStoryId)"></i>
      </div>
   </div>
   <div class="title-container">
      <div
         class="title"
         [href]="story.storyUrl"
         (click)="onViewStory()"
         nz-tooltip
         nzTooltipPlacement="bottomLeft"
         [nzTooltipMouseEnterDelay]="0.5"
         [nzTooltipTitle]="story.storyTitle"
         [innerHTML]="(story.snippet.title || story.storyTitle || '').trim() | safeHtml"></div>
   </div>
   <div class="related-records-count">
      <div>{{ story.storyPostCount }} Angles</div>
      <div class="dot-count">.</div>
      <div>{{ story.storyPostCount }} Posts</div>
      <div class="dot-count">.</div>
      <div>{{ story.storyPostCount }} Elements</div>
      <div class="right-align">
         <app-follow-story
            [storyId]="story.storyID"
            [isFollowing]="story.followedByUser"
            [showCircularIcon]="true"
            [showFollowCount]="false"
            [showFollowStatus]="true"
            (updatedFollower)="updateFollowers($event)"></app-follow-story>
      </div>
   </div>
</div>

<!-- AUTHOR -->

<!-- <div class="created-user">
   <span>Created By:</span>
   <span
      class="user-name"
      nz-popover
      [nzPopoverContent]="profileCardTemplate1"
      [nzPopoverPlacement]="'topLeft'">
      {{ authorName }}
   </span>
   <ng-template #profileCardTemplate1>
      <app-profile-overlay
         [profileData]="{ userId: story.userId, displayName: story.authorDisplayName }"></app-profile-overlay>
   </ng-template>
   <span>
      on {{ date | utcToLocal: 'DATE' }} at
      {{ date | utcToLocal: 'TIME' }}
   </span>
</div> -->

<!-- <div class="author">
   <button
      class="name"
      nz-popover
      [nzPopoverContent]="profileCardPerson"
      nzPopoverPlacement="topLeft">
      {{ authorName }}
   </button>

   <span class="time">&nbsp;{{ date }}</span>

   <ng-template #profileCardPerson>
      <app-profile-overlay
         [profileData]="{ userId: story.userId, displayName: story.authorDisplayName }"></app-profile-overlay>
   </ng-template>
</div> -->

<!-- FOOTER -->
<!-- <div class="ncx-story-footer">
   <div class="left-side">
      <app-info-tags
         class="item"
         [total]="story.storyContentTags?.length || 0"></app-info-tags>
   </div>

   <div class="right-side"></div>
</div> -->
