<!-- RESULT COUNT -->

<div
   [ngClass]="{ 'grid-container-full-frame': true }"
   *ngIf="allResultsPage">
   <div [ngClass]="{ 'flexible-width': true }">
      <ng-container *ngTemplateOutlet="resultBodyHeaderTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="resultBodyTemplate"></ng-container>
   </div>
</div>

<div
   [ngClass]="{ 'grid-container': true }"
   *ngIf="!allResultsPage">
   <div
      class="filters"
      *ngIf="!isMobile">
      <app-search-filters-new
         [isCollapsed]="false"
         [filterState]="tabOrder[1]"></app-search-filters-new>
   </div>
   <div [ngClass]="{ 'flexible-width': true, 'ml-24': isMobile }">
      <ng-container *ngTemplateOutlet="resultBodyHeaderTemplate"></ng-container>

      <app-scroll-container [sideBarContainer]="true">
         <ng-template bodyTemplate>
            <ng-container *ngTemplateOutlet="resultBodyTemplate"></ng-container>
         </ng-template>
      </app-scroll-container>
   </div>
</div>

<nz-drawer
   *ngIf="isMobile"
   nzPlacement="left"
   nzWrapClassName="no-padding drawer-filter"
   [nzWidth]="320"
   [nzMaskClosable]="true"
   [nzClosable]="true"
   (nzOnClose)="toggleFilters()"
   [nzVisible]="!isCollapsed">
   <ng-container *nzDrawerContent>
      <app-search-filters-new
         [isCollapsed]="isCollapsed"
         [filterState]="tabOrder[1]"></app-search-filters-new>
   </ng-container>
</nz-drawer>

<ng-template #resultBodyHeaderTemplate>
   <div class="result-body-header">
      <div *ngIf="allResultsPage">
         <span
            class="mr-8"
            nz-icon
            nzType="read"
            nzTheme="outline"></span>
         Stories
      </div>
      <!-- <div *ngIf="!allResultsPage">
         <button
            nz-button
            nzType="primary"
            class="filter-btn"
            (click)="toggleFilters()">
            <span
               nz-icon
               nzType="filter"
               nzTheme="outline"></span>
            Filters
         </button>
      </div> -->
      <div *ngIf="!allResultsPage">
         <!-- {{ searchService.filters.STORIES.sort }} -->
         <nz-select
            class="sort-options"
            nzMode="default"
            [(ngModel)]="searchService.filters.STORIES.sort"
            (ngModelChange)="onSortOptionChange($event)"
            [nzAllowClear]="true"
            [nzShowArrow]="true">
            <nz-option
               *ngFor="let option of sort"
               [nzLabel]="option.key"
               [nzValue]="option.value"></nz-option>
         </nz-select>
      </div>
      <div
         *ngIf="isLoaded || (total > 0 && !isLoaded)"
         class="result-count">
         {{ resultCount }}
         <button
            *ngIf="searchService.suggestedSearch.show"
            (click)="searchService.performSuggestedSearch()"
            class="suggested-search">
            Did you mean {{ searchService.suggestedSearch.keyword }}?
         </button>
      </div>
   </div>
</ng-template>

<ng-template #resultBodyTemplate>
   <div
      [ngClass]="{
         'search-result-container': true,
         'no-results': !searchResults.length,
      }"
      data-scrollable-container
      infiniteScroll
      [infiniteScrollDistance]="scrollDistance"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
      [attr.data-component]="'ncx/search/page/results/stories'">
      <div class="search-result-body">
         <!-- LOADER -->
         <app-loader-component
            loaderType="SECTION_LOADER"
            [isLoaded]="isLoaded"></app-loader-component>

         <!-- CARD TO DISPLAY SEARCH RESULTS -->
         <ncx-story-search-new
            *ngFor="let story of searchResults; let index = index"
            (viewStory)="viewStory($event, index)"
            [story]="story"></ncx-story-search-new>

         <div *ngIf="!searchResults.length && isLoaded">
            <nz-empty [nzNotFoundContent]="contentTpl">
               <ng-template #contentTpl>
                  <span>No Stories Found</span>
               </ng-template>
            </nz-empty>
         </div>
      </div>
   </div>
</ng-template>
