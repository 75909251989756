import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ISearch, ProfileSearchResult } from '@models/ncx/global-search';
import { BreakpointService } from '@services/breakpoint.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

type PeopleParams = {
  contentType: 'Profile',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string
};

type PeoplePayload = object;

@Component({
  selector: 'app-people-results-new',
  templateUrl: './people-results.component.html',
  styleUrls: ['../search-results-common.scss']
})
export class PeopleResultsNewComponent implements OnChanges, OnDestroy {

  @Input() isVisible: boolean = false;

  @Input() allResultsPage: boolean = false;

  // Search Results from API
  searchResults: ProfileSearchResult[] = [];

  // Current page of search results
  page: number = 1;

  // Total number of results
  total: number = 0;

  // Number of results per page
  limit: number = 10;

  // Loader
  isLoaded: boolean = true;

  private subs = new SubSink();

  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();

  public readonly Common = Common;

  peopleSortOptions = [
    { key: 'Alphabetical - A-Z', value: 'ascending' },
    { key: 'Alphabetical - Z-A', value: 'descending' }
  ];

  isMobile: boolean = false;

  constructor(
    private router: Router,
    public searchService: GlobalSearchService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService,
    public breakpointService: BreakpointService
  ) {

    // When search term changes, load results if component is visible
    this.subs.sink = this.searchService.searchTerm.subscribe(() => {

      // When search term changes, reset results regardless of whether the component is visible or not
      // When the user returns to this tab, a new list of search results will load
      this.resetResults();
      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.loadSearchResults();

      }

    });

    // When filters change, load results if component is visible
    this.subs.sink = this.searchService.filterChanged.subscribe(() => {

      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.resetResults();
        this.loadSearchResults();

      }

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    // When visibility changes, load search results if visible and if the results weren't already loaded
    if ('isVisible' in changes) {

      if (changes.isVisible.currentValue && !this.searchResults.length) {

        this.loadSearchResults();

      }
      if (!changes.isVisible.currentValue) {

        this.cancelRequest.next(true);

      }

    }

    if ('allResultsPage' in changes && changes.allResultsPage.currentValue) {

      this.limit = this.allResultsPage ? 10 : 50;

    }

  }

  ngOnInit() {
    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });
  }


  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Load search results
   *
   */
  loadSearchResults() {

    this.isLoaded = false;

    const params = this.buildParams();

    const payload = this.buildPayload();

    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&');

    this.searchService.loadSearchResults(queryString, payload).pipe(takeUntil(this.cancelRequest.pipe(filter(value => value === true)))).subscribe({
      next: (res: any) => {

        const results: ISearch = res as ISearch;

        const searchResults = results.profileResult || [];

        this.searchResults = this.page === 1 ? searchResults : [...this.searchResults, ...searchResults];

        this.total = results.profileCount;

        console.log('loadSearchResults', { searchResults: this.searchResults, total: this.total });

        this.searchService.setSuggestedSearch(results.contentSuggestedPhrase);

        this.sendGATracking(params, payload);

      },
      error: (error: any) => {

        this.toastService.createMessage('error', 'Unable to load search results');

        console.error('loadSearchResults', error);

      }
    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Build the query string portion of the API call
   *
   */
  buildParams(): PeopleParams {

    // API Params
    const params: PeopleParams = {
      contentType: 'Profile',
      startIndex: 1,
      endIndex: this.limit,
      sortField: 'lastName',
      sortOrder: 'ascending'
    };

    try {

      // Start/End Index
      const { start, end } = this.startEndIndex;

      params.startIndex = start;
      params.endIndex = end;

      // Sort
      const { sort } = this.searchService.filters.PEOPLE;

      params.sortOrder = sort;

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as PeopleParams;

    }

  }

  /**
   * Build payload for API call
   *
   */
  buildPayload(): PeoplePayload {

    return {} as PeoplePayload;

  }

  /**
   * Track Google Analytics
   *
   */
  sendGATracking(params: PeopleParams, payload: PeoplePayload) {

    const searchTerm = this.searchService.searchTerm.value;

    console.log('Google Analytics Tracking (People)', { searchTerm, params, payload });

    this.gaService.trackContentResultsCount(searchTerm, this.total, 'People Results Count', 'People Content');

    // Sort
    if (params.sortOrder) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Sort - ${params.sortOrder}`, this.total, 'People Content');

    }

  }

  /**
   * View Profile
   *
   */
  viewProfile(profile: ProfileSearchResult, index: number) {

    const searchText = this.searchService.searchTerm.value;

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, searchText, 'Selected People Index', 'People Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(this.page, searchText, 'Selected People Page Number', 'People Content');

    this.router.navigate(['ncx/profile/:' + profile.userId]);

  }

  /**
   * On page scroll, load next page
   *
   */
  onScrollDown() {

    const { end } = this.startEndIndex;

    console.log('onScrollDown (People)');

    // If the end index is >= number of results
    if (end >= this.total) {

      console.log('No More Results');
      return;

    }

    this.page += 1;

    this.cancelRequest.next(false);

    this.loadSearchResults();

  }

  /**
   * Reset all results
   *
   */
  resetResults() {

    this.total = 0;
    this.page = 1;
    this.searchResults = [];

  }

  get resultCount(): string {

    return `${Common.formatNumber(this.total)} Result${this.total !== 1 ? 's' : ''}`;

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }


  onSortOptionChange(sort: string) {

    this.searchService.filters.GROUPS.sort = sort;

    this.searchService.filterChanged.next();
  }



}
