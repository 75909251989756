<nz-collapse
   nzExpandIconPosition="right"
   [nzBordered]="false">
   <nz-collapse-panel
      [nzHeader]="dateHeader"
      nzActive>
      <div class="date-panel">
         <nz-range-picker
            class="date-picker input-style"
            [(ngModel)]="searchService.filters.POSTS.modificationDate"
            (ngModelChange)="dateChanged($event)"></nz-range-picker>

         <nz-radio-group
            class="list text-style"
            [(ngModel)]="searchService.filters.POSTS.dateOption"
            (ngModelChange)="dateOptionChanged($event)">
            <label
               nz-radio
               nzValue="alltime">
               All Time
            </label>
            <label
               nz-radio
               nzValue="today">
               Today
            </label>
            <label
               nz-radio
               nzValue="past2days">
               Past 2 Days
            </label>
            <label
               nz-radio
               nzValue="past3days">
               Past 3 days
            </label>
            <label
               nz-radio
               nzValue="pastweek">
               Past Week
            </label>
         </nz-radio-group>

         <nz-divider
            class="horizontal-line"
            nzType="horizontal"></nz-divider>

         <nz-radio-group
            class="full-width-radio-group"
            [(ngModel)]="searchService.filters.POSTS.createdOrModified"
            (ngModelChange)="onFilterChanged()"
            [nzButtonStyle]="'solid'">
            <label
               nz-radio-button
               nzValue="modified">
               Modified
            </label>
            <label
               nz-radio-button
               nzValue="created">
               Created
            </label>
         </nz-radio-group>
      </div>
   </nz-collapse-panel>
</nz-collapse>

<div class="no-top-border">
   <nz-collapse
      nzExpandIconPosition="right"
      [nzBordered]="false">
      <nz-collapse-panel
         [nzHeader]="postTypeHeader"
         nzActive>
         <div class="post-type">
            <div class="full-width-buttons mb-12 input-style">
               <label>Elements</label>
               <nz-switch
                  class="float-right"
                  [(ngModel)]="searchService.filters.POSTS.includeElements"
                  (click)="includeExcludeElements($event)"
                  nzSize="small"></nz-switch>
            </div>

            <label
               class="text-style"
               nz-checkbox
               [(ngModel)]="searchService.filters.POSTS.isAllMediaChecked"
               (ngModelChange)="selectMediaOption(true, $event)">
               All
            </label>

            <nz-divider
               class="horizontal-line"
               nzType="horizontal"></nz-divider>

            <div class="media-labels text-style">
               <nz-checkbox-wrapper>
                  <label
                     nz-checkbox
                     *ngFor="let opt of searchService.filters.POSTS?.media"
                     [(ngModel)]="opt.checked"
                     (ngModelChange)="selectMediaOption(false, $event)">
                     {{ opt.label }}
                  </label>
               </nz-checkbox-wrapper>
            </div>
         </div>

         <div class="post-type mt-12 input-style">
            <div class="full-width-buttons">
               <label>Include Discussions</label>
               <nz-switch
                  class="float-right"
                  [(ngModel)]="searchService.filters.POSTS.includeDiscussions"
                  (click)="includeExcludeDiscussions($event)"
                  nzSize="small"></nz-switch>
            </div>
         </div>
      </nz-collapse-panel>
   </nz-collapse>
</div>

<div class="no-top-border">
   <nz-collapse
      nzExpandIconPosition="right"
      [nzBordered]="false">
      <nz-collapse-panel
         [nzHeader]="editorialStandardsHeader"
         nzActive>
         <div class="">
            <div class="full-width-buttons mb-12 input-style">
               <label>Include Published / Aired</label>
               <nz-switch
                  class="float-right"
                  [(ngModel)]="searchService.filters.POSTS.includePublishedAired"
                  (click)="includeExcludePublishedOrAired($event)"
                  nzSize="small"></nz-switch>
            </div>

            <nz-divider
               class="horizontal-line"
               nzType="horizontal"></nz-divider>

            <div class="editorial-labels text-style">
               <nz-checkbox-wrapper class="list">
                  <ng-container *ngFor="let opt of searchService.filters.POSTS?.editorial">
                     <label
                        nz-checkbox
                        *ngIf="opt.label !== 'None'"
                        [(ngModel)]="opt.checked"
                        (ngModelChange)="onFilterChanged()">
                        {{ opt.label }}
                     </label>
                  </ng-container>
               </nz-checkbox-wrapper>
            </div>
         </div>
      </nz-collapse-panel>
   </nz-collapse>
</div>

<div class="no-top-border">
   <nz-collapse
      nzExpandIconPosition="right"
      [nzBordered]="false">
      <nz-collapse-panel
         [nzHeader]="rcLegalHeader"
         nzActive>
         <div class="">
            <div class="editorial-labels text-style">
               <nz-checkbox-wrapper class="list">
                  <ng-container *ngFor="let opt of searchService.filters.POSTS?.legal">
                     <label
                        nz-checkbox
                        *ngIf="opt.label !== 'None'"
                        [(ngModel)]="opt.checked"
                        (ngModelChange)="onFilterChanged()">
                        {{ opt.label }}
                     </label>
                  </ng-container>
               </nz-checkbox-wrapper>
            </div>
         </div>
      </nz-collapse-panel>
   </nz-collapse>
</div>

<div class="no-top-border">
   <topic-search
      [selectedTopics]="searchService.filters.POSTS.topics"
      (onTopicSelected)="onTopicSelected($event)"></topic-search>
</div>

<div class="no-top-border">
   <tag-search
      [selectedTags]="searchService.filters.POSTS.tags"
      (onTagSelected)="onTagSelected($event)"
      (onIncludeExcludeChanged)="onIncludeExcludeChanged()"></tag-search>
</div>
<div class="no-top-border bottom-border">
   <nz-collapse
      nzExpandIconPosition="right"
      [nzBordered]="false">
      <nz-collapse-panel
         [nzHeader]="authorHeader"
         nzActive>
         <nz-input-group [nzSuffix]="searchService.filters.POSTS.authorName ? suffixIconClear : suffixIconSearch">
            <input
               type="text"
               class="filterAuthor"
               nz-input
               placeholder="Search Author..."
               [nzAutocomplete]="auto"
               [(ngModel)]="searchService.filters.POSTS.authorName"
               (keydown)="onSearchAuthor($event)"
               (keyup)="onSearchAuthor($event)"
               (ngModelChange)="onSelectAuthor($event, 'POSTS')" />
         </nz-input-group>
         <nz-autocomplete #auto>
            <ng-container *ngFor="let profile of profileOptions">
               <nz-auto-option [nzValue]="profile.name">{{ profile.name }}</nz-auto-option>
            </ng-container>
            <nz-auto-option
               *ngIf="
                  searchService.filters.POSTS.authorName !== '' && profileOptions.length === 0 && profileLoaded === true
               ">
               No Authors Found
            </nz-auto-option>
         </nz-autocomplete>
      </nz-collapse-panel>
   </nz-collapse>
</div>

<ng-template #dateHeader>
   <i
      nz-icon
      nzType="calendar"></i>
   Date
</ng-template>

<ng-template #postTypeHeader>
   <i
      nz-icon
      nzType="FileText"></i>
   Post Type
</ng-template>

<ng-template #editorialStandardsHeader>
   <i
      nz-icon
      nzType="audit"></i>
   Editorial / Standards
</ng-template>

<ng-template #rcLegalHeader>
   <i
      nz-icon
      nzType="trademark"></i>
   R&C / Legal
</ng-template>

<ng-template #authorHeader>
   <i
      nz-icon
      nzType="user"></i>
   Author
</ng-template>

<ng-template #suffixIconSearch>
   <i
      nz-icon
      nzType="search"></i>
</ng-template>

<ng-template #suffixIconClear>
   <button (click)="onClearAuthor()">
      <i
         nz-icon
         nzType="close-circle"></i>
   </button>
</ng-template>
