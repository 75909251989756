import { CrewShowUnit, requestType } from './edit';

export function manipulateData(dataFromAPI: any[]) {
    dataFromAPI?.forEach(record => {
        manipulateCMData(record);
    });
}

export function manipulateCMData(record: any) {
    setIdAndRouterLink(record);
    record.expand = false;
    record.content.requestCreated = formatDateToShowTimeZone(convertUnixToDate(record.content.created));
    record.content.requestModified = formatDateToShowTimeZone(convertUnixToDate(record.content.modified));

    let showUnits = buildShowUnits(record);
    record.content.zShowUnitsDisplayString = showUnits.truncated;
    record.content.zShowUnitsDisplayList = showUnits.list;
    record.content.zShowUnitsRemaining = showUnits.remaining;
    record.content.zShowUnitsRemainingLength = showUnits.remainingLength;

    let destinations = buildDestinations(record);
    record.content.zDestinationsDisplayString = destinations.truncated;
    record.content.zDestinationsDisplayList = destinations.list;
    record.content.zDestinationsRemaining = destinations.remaining;
    record.content.zDestinationsRemainingLength = destinations.remainingLength;

    let requesters = buildRequesters(record);
    record.content.zRequestersDisplayString = requesters.truncated;
    record.content.zRequestersDisplayList = record.content.requesters;
    record.content.zRequestersTruncatedList = requesters.truncatedList;

    record.content.requestType = record.content.editType == 'Long Form Edit Request' ? 'Long Form' : 'Standard';

    record.content.zRequestersRemaining = requesters.remaining;
    record.content.zRequestersRemainingLength = requesters.remainingLength;


    let producer = truncateString(record.content.producerName);
    record.content.zProducerDisplayString = producer.truncated;
    record.content.zProducerTruncatedList = producer.truncatedList;
    record.content.zProducerDisplayList = [record.content.zProducer];
    record.content.zProducerRemaining = producer.remaining;
    record.content.zProducerRemainingLength = producer.remainingLength;
}

export function setIdAndRouterLink(record: any) {
    if (record.content.requestType === requestType.feedout) {
        record.content.zid = record.content.feedOutRequestID;
        record.content.zrouterlink = '/unified-requests/feed-out';
    } else if (record.content.requestType === requestType.fileingest) {
        record.content.zid = record.content.fileIngestID;
        record.content.zrouterlink = '/unified-requests/file-ingest';
    } else if (record.content.requestType === requestType.mtd) {
        record.content.zid = record.content.mtdRequestID;
        record.content.zrouterlink = '/unified-requests/mtd';
    }
}

/*
* Build Show Units information for tables
*/
export function buildShowUnits(record: any): any {
    let showUnits: CrewShowUnit[] = record.content.requestType === requestType.fileingest ? record.content.showunits : record.content.showunit;
    return truncateArray(showUnits?.map((showUnit, index) => { return showUnit?.showunittitle; }));
}

/*
* Build Requesters information for tables
*/
export function buildRequesters(record: any) {
    return truncateArray(record.content.requesters?.map((requester: any) => { return requester.displayName; }));
}

/*
 * Build Destinations information for tables
 */
export function buildDestinations(record: any): any {
    return truncateArray(record.content.requestType === requestType.mtd
        ? record.content.reqDestinations.map((x: any) => { return x.destinations; })
        : record.content.destinations);
}

/**
* Truncates a list to X num of characters
*
* @param values {string[]}
* @param appendedChar {string}
* @param length {number}
*
*/
export function truncateArray(values: string[] = [], appendedChar = ',', length = 32) {
    try {
        values = values ? values : [];
        let str = values[0];
        let truncated = str.length > length ? str.substring(0, length) + '...' : str;
        let truncatedList = values;
        let remainingLength = values.length - 1;
        let remaining = values.length !== 1;
        return {
            list: values,
            truncated: truncated,
            truncatedList: truncatedList,
            remaining: remaining,
            remainingLength: remainingLength == 0 ? '' : '+' + remainingLength
        };
    } catch (error) {
        return { list: [], truncated: '', truncatedList: [], remaining: '', remainingLength: 0 };
    }
};

export function formatDateToShowTimeZone(date: Date) {
    let month = date.getMonth() + 1;
    let dateOfMonth = date.getDate();
    let year = date.getFullYear();
    let timeFormat = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    let timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

    return `${month + '/' + dateOfMonth + '/' + year + " " + timeFormat + " (" + timezone + ")"}`
}

/**
* Convert unix or epoch seconds to a Date object
* @param unix {number}
*
*/
export function convertUnixToDate(unix: number): Date {

    try {

        if (String(unix).length === 10) {
            return new Date(unix * 1000);
        } else {
            return new Date(unix);
        }

    } catch (error) {
        return new Date();
    }

}

/**
   * Truncates a string to X num of characters
   *
   * @param value {string}
   * @param length {number}
   *
   */
export function truncateString(value: string = '', length = 28) {
    try {
        let str = value ? value : '';
        let truncated = str.length > length ? str.substring(0, length) + '...' : str;
        let truncatedList = [truncated];
        let remainingLength = 0;
        let remaining = str.length > length;
        return {
            list: [str],
            truncated: truncated,
            truncatedList: truncatedList,
            remaining: remaining,
            remainingLength: remainingLength == 0 ? '' : '(+' + remainingLength + ')'
        };
    } catch (error) {
        return { list: [], truncated: '', truncatedList: [], remaining: '', remainingLength: 0 };
    }
};