import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchiveBannerComponent } from '@components/banners/archive/archive-banner.component';
import { DraftBannerComponent } from '@components/banners/draft/draft-banner.component';
import { PostUnlockBannerComponent } from '@components/banners/posts/unlock/unlock-banner.component';
import { TopicBannerComponent } from '@components/banners/topic/topic-banner.component';
import { BetaComponent } from '@components/beta/beta.component';
import { AngleSearchNewComponent } from '@components/cards-new/angle/search/angle-search.component';
import { ElementNewComponent } from '@components/cards-new/element/element.component';
import { ElementSearchNewComponent } from '@components/cards-new/element/search/element-search.component';
import { GroupSearchNewComponent } from '@components/cards-new/group/search/group-search.component';
import { PostSearchNewComponent } from '@components/cards-new/post/search/post-search.component';
import { ProfileSearchNewComponent } from '@components/cards-new/profile/search/profile-search.component';
import { StorySearchNewComponent } from '@components/cards-new/story/search/story-search.component';
import { AngleDetailsComponent } from '@components/cards/angle/angle-details.component';
import { AngleSearchComponent } from '@components/cards/angle/search/angle-search.component';
import { ElementComponent } from '@components/cards/element/element.component';
import { GroupSearchComponent } from '@components/cards/group/search/group-search.component';
import { PostComponent } from '@components/cards/post/post.component';
import { PostSearchComponent } from '@components/cards/post/search/post-search.component';
import { ProfileSearchComponent } from '@components/cards/profile/search/profile-search.component';
import { StorySearchComponent } from '@components/cards/story/search/story-search.component';
import { ContentTypesComponent } from '@components/content-types/content-types.component';
import { SectionInsertComponent } from '@components/section/insert/section-insert.component';
import { SectionComponent } from '@components/section/section.component';
import { DebounceClickDirective } from '@directives/debounce';
import { StandardHeaderComponent } from '@layout/standard-header/standard-header.component';
import { AddAngleComponent } from '@modals/ncx/add-angle/add-angle.component';
import { MergeStoriesComponent } from '@modals/ncx/merge-stories/merge-stories.component';
import { ConnectionsComponent } from '@ncx/following/connections/connections.component';
import { FollowingStoriesComponent } from '@ncx/following/stories/following-stories.component';
import { TopicsComponent } from '@ncx/following/topics/topics.component';
import { HistoryComponent } from '@ncx/profile/history/history.component';
import { ProfileComponent } from '@ncx/profile/profile/profile.component';
import { AngleFiltersNewComponent } from '@ncx/search/page-new/filters/filter-angles/angle-filters.component';
import { ElementFiltersComponent } from '@ncx/search/page-new/filters/filter-elements/element-filters.component';
import { PostFiltersNewComponent } from '@ncx/search/page-new/filters/filter-posts/post-filters.component';
import { StoriesFiltersNewComponent } from '@ncx/search/page-new/filters/filter-stories/stories-filters.component';
import { SearchFiltersNewComponent } from '@ncx/search/page-new/filters/search-filters.component';
import { AllResultsComponent } from '@ncx/search/page-new/results/all/all-results.component';
import { AngleResultsNewComponent } from '@ncx/search/page-new/results/angles/angle-results.component';
import { ElementResultsComponent } from '@ncx/search/page-new/results/elements/element-results.component';
import { GroupResultsNewComponent } from '@ncx/search/page-new/results/groups/group-results.component';
import { PeopleResultsNewComponent } from '@ncx/search/page-new/results/people/people-results.component';
import { PostResultsNewComponent } from '@ncx/search/page-new/results/posts/post-results.component';
import { StoriesResultsNewComponent } from '@ncx/search/page-new/results/stories/stories-results.component';
import { SearchResultsNewComponent } from '@ncx/search/page-new/search-results.component';
import { SearchFiltersComponent } from '@ncx/search/page/filters/search-filters.component';
import { AngleResultsComponent } from '@ncx/search/page/results/angles/angle-results.component';
import { GroupResultsComponent } from '@ncx/search/page/results/groups/group-results.component';
import { PeopleResultsComponent } from '@ncx/search/page/results/people/people-results.component';
import { PostResultsComponent } from '@ncx/search/page/results/posts/post-results.component';
import { StoriesResultsComponent } from '@ncx/search/page/results/stories/stories-results.component';
import { SearchResultsComponent } from '@ncx/search/page/search-results.component';
import { SearchResultsPreviewComponent } from '@ncx/search/results-preview/results-preview.component';
import { SearchTagComponent } from '@ncx/search/shared/tag/search-tag.component';
import { SearchTopicComponent } from '@ncx/search/shared/topic/search-topic.component';
import { SettingsComponent } from '@ncx/settings/settings.component';
import { CreateStoryComponent } from '@ncx/stories/create/create-story.component';
import { NewStoriesLandingComponent } from '@ncx/stories/landing/story-landing.component';
import { StoryAnglesComponent } from '@ncx/stories/landing/tabs/angles/story-angles.component';
import { StoryPostsComponent } from '@ncx/stories/landing/tabs/posts/story-posts.component';
import { CrewRelatedContentComponent } from '@ncx/stories/landing/tabs/related-content/crew/crew-related-content.component';
import { EditRelatedContentComponent } from '@ncx/stories/landing/tabs/related-content/edit/edit-related-content.component';
import { FeedoutRelatedContentComponent } from '@ncx/stories/landing/tabs/related-content/feedout/feedout-related-content.component';
import { FileIngestRelatedContentComponent } from '@ncx/stories/landing/tabs/related-content/fileIngest/fileIngest-related-content.component';
import { GuestBookingRelatedContentComponent } from '@ncx/stories/landing/tabs/related-content/guestBooking/guest-booking-related-content.component';
import { StoryRelatedContentComponent } from '@ncx/stories/landing/tabs/related-content/story-related-content.component';
import { SupportFaqComponent } from '@ncx/support/faq/support-faq.component';
import { SupportTrainingComponent } from '@ncx/support/training/support-training.component';
import { SupportVersionsComponent } from '@ncx/support/versions/support-versions.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilterColumnPipe } from '@pipes/filter-column.pipe';
import { HighlightTextPipe } from '@pipes/highlight.pipe';
import { SafeHtmlPipe } from '@pipes/html-style.pipe';
import { TruncateTextPipe } from '@pipes/truncate.pipe';
import { NavigationService } from '@services/navigation-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { MenuService } from 'ng-zorro-antd/menu';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HeaderLeftTemplateDirective, HeaderRightTemplateDirective } from '../layout/header-directives';
import { TransferInformationComponent } from '../shared/components/TransferInformation/TransferInformation.component';
import { DemoNgZorroAntdModule } from '../shared/modules/ng-zorro-antd.module';
import { SharedModule } from '../shared/shared.module';
import { ActivityFilterComponent } from './activity/activity-filter/activity-filter.component';
import { ActivityPage } from './activity/activity-page';
import { AlertsPage } from './alerts/alerts-page';
import { CreateAngleComponent } from './angles/create/create-angle.component';
import { AngleLandingComponent } from './angles/landing/angle-landing.component';
import { AngleLogComponent } from './angles/landing/angle-log/angle-log.component';
import { AngleMaterialComponent } from './angles/landing/angle-material/angle-material.component';
import { ViewAngleComponent } from './angles/view/view-angle.component';
import { CustomColumnComponent } from './customization/custom-column/custom-column.component';
import { ReadyWorkingFilterComponent } from './customization/ready-working-filter/ready-working-filter.component';
import { DraftsFilterComponent } from './drafts/drafts-filter/drafts-filter.component';
import { DraftsComponent } from './drafts/drafts.component';
import { FeedsFilterComponent } from './feeds/feeds-filter/feeds-filter.component';
import { FeedsComponent } from './feeds/feeds.component';
import { FollowingComponent } from './following/following.component';
import { CreateDiscussionComponent } from './group/create-discussion/create-discussion.component';
import { CreateGroupComponent } from './group/create-group/create-group.component';
import { DiscussionDetailsComponent } from './group/discussion-details/discussion-details.component';
import { GroupDetailsComponent } from './group/group-details/group-details.component';
import { GroupFollowersComponent } from './group/group-followers/group-followers.component';
import { GroupLandingComponent } from './group/group-landing/group-landing.component';
import { GroupVersionsComponent } from './group/group-versions/group-versions.component';
import { ManageMembersComponent } from './group/manage-members/manage-members.component';
import { ncxRoutingModule } from './ncx-routing.module';
import { OtherToolsComponent } from './other-tools/other-tools.component';
import { CreatePostComponent } from './posts/create/create-post.component';
import { PostsMetadataComponent } from './posts/metadata/posts-metadata.component';
import { PostVersionsComponent } from './posts/versions/post-versions.component';
import { ViewPostsComponent } from './posts/view/view-posts.component';
import { GenericDashboardComponent } from './stories/dashboard/generic-dashboard/generic-template-section.component';
import { StoriesDashboardComponent } from './stories/dashboard/stories-dashboard.component';
import { DetailStoryComponent } from './stories/detail/detail-story.component';
import { StoriesFollowingComponent } from './stories/following/stories-following.component';
import { ViewStoryComponent } from './stories/view/view-story.component';
import { SupportPageComponent } from './support/support-page.component';
import { ToggleSectionComponent } from './toggle-section/toggle-section.component';


export const createTranslateLoader = (http: HttpClient) => {

  return new TranslateHttpLoader(http);

};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    NzPaginationModule,
    ReactiveFormsModule,
    NzSegmentedModule,
    DemoNgZorroAntdModule,
    ncxRoutingModule,
    InfiniteScrollModule,
    TranslateModule,
    SharedModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    SettingsComponent,
    ProfileComponent,
    HistoryComponent,
    DebounceClickDirective,
    PostComponent,
    PostVersionsComponent,
    TransferInformationComponent,
    StoriesDashboardComponent,
    GenericDashboardComponent,
    ViewStoryComponent,
    ToggleSectionComponent,
    MergeStoriesComponent,
    ViewPostsComponent,
    PostsMetadataComponent,
    StoriesFollowingComponent,
    GroupLandingComponent,
    CreateGroupComponent,
    GroupDetailsComponent,
    FollowingComponent,
    CreateDiscussionComponent,
    DiscussionDetailsComponent,
    ManageMembersComponent,
    GroupFollowersComponent,
    GroupVersionsComponent,
    DraftsComponent,
    ConnectionsComponent,
    OtherToolsComponent,
    ActivityPage,
    AlertsPage,
    ActivityFilterComponent,
    DraftsFilterComponent,
    FeedsComponent,
    FeedsFilterComponent,
    SearchResultsNewComponent,
    StoriesResultsNewComponent,
    PostResultsNewComponent,
    AngleResultsNewComponent,
    GroupResultsNewComponent,
    PeopleResultsComponent,
    SearchResultsComponent,
    StoriesResultsComponent,
    PostResultsComponent,
    AngleResultsComponent,
    GroupResultsComponent,
    PeopleResultsComponent,
    PeopleResultsNewComponent,
    CustomColumnComponent,
    FilterColumnPipe,
    ReadyWorkingFilterComponent,
    TopicsComponent,
    SafeHtmlPipe,
    SectionComponent,
    SectionInsertComponent,
    CreateAngleComponent,
    ViewAngleComponent,
    NewStoriesLandingComponent,
    CreateStoryComponent,
    CreatePostComponent,
    ElementComponent,
    ElementNewComponent,
    StandardHeaderComponent,
    HeaderLeftTemplateDirective,
    HeaderRightTemplateDirective,
    AngleLandingComponent,
    AngleLogComponent,
    TruncateTextPipe,
    HighlightTextPipe,
    DraftBannerComponent,
    TopicBannerComponent,
    ArchiveBannerComponent,
    PostUnlockBannerComponent,
    AngleMaterialComponent,
    AddAngleComponent,
    AngleDetailsComponent,
    StoryAnglesComponent,
    StorySearchComponent,
    PostSearchComponent,
    GroupSearchComponent,
    ProfileSearchComponent,
    StorySearchNewComponent,
    PostSearchNewComponent,
    GroupSearchNewComponent,
    ProfileSearchNewComponent,
    StoryAnglesComponent,
    StoryPostsComponent,
    AngleSearchComponent,
    AngleSearchNewComponent,
    FollowingStoriesComponent,
    SupportPageComponent,
    SupportFaqComponent,
    SupportVersionsComponent,
    SupportTrainingComponent,
    BetaComponent,
    DetailStoryComponent,
    ContentTypesComponent,
    StoryRelatedContentComponent,
    GuestBookingRelatedContentComponent,
    CrewRelatedContentComponent,
    EditRelatedContentComponent,
    FeedoutRelatedContentComponent,
    FileIngestRelatedContentComponent,
    DetailStoryComponent,
    SearchTopicComponent,
    SearchTagComponent,
    StoriesFiltersNewComponent,
    PostFiltersNewComponent,
    AngleFiltersNewComponent,
    ElementFiltersComponent,
    ElementSearchNewComponent,
    SearchFiltersComponent,
    SearchFiltersNewComponent,
    ElementResultsComponent,
    AllResultsComponent,
    SearchResultsPreviewComponent
  ],
  exports: [
    TranslateModule,
    CustomColumnComponent,
    ReadyWorkingFilterComponent,
    SectionComponent,
    SectionInsertComponent,
    ElementComponent,
    StandardHeaderComponent,
    DebounceClickDirective,
    SafeHtmlPipe,
    FilterColumnPipe,
    TruncateTextPipe,
    HighlightTextPipe,
    AlertsPage,
    ArchiveBannerComponent,
    DraftBannerComponent,
    TopicBannerComponent,
    PostUnlockBannerComponent,
    GroupSearchComponent,
    PostComponent,
    PostSearchComponent,
    ProfileSearchComponent,
    StorySearchComponent,
    BetaComponent,
    SupportPageComponent,
    SupportFaqComponent,
    SupportVersionsComponent,
    SupportTrainingComponent,
    DetailStoryComponent,
    ContentTypesComponent,
  ],
  providers: [
    PermissionsService,
    NavigationService,
    ToastService,
    MenuService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})

// tslint:disable-next-line: class-name
export class NcxModule { }

