<!-- RESULT COUNT -->

<app-scroll-container [sideBarContainer]="true">
   <ng-template bodyTemplate>
      <div class="grid-container-all-results-frame">
         <div class="">
            <app-element-results
               [isVisible]="true"
               [allResultsPage]="true"></app-element-results>

            <app-post-results-new
               [isVisible]="true"
               [allResultsPage]="true"></app-post-results-new>

            <app-angle-results-new
               [isVisible]="true"
               [allResultsPage]="true"></app-angle-results-new>
         </div>
         <div class="">
            <app-stories-results-new
               [isVisible]="true"
               [allResultsPage]="true"></app-stories-results-new>

            <app-group-results-new
               [isVisible]="true"
               [allResultsPage]="true"></app-group-results-new>

            <app-people-results-new
               [isVisible]="true"
               [allResultsPage]="true"></app-people-results-new>
         </div>
      </div>
   </ng-template>
</app-scroll-container>
