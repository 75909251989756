import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngleMode } from '@models/ncx/angle';
import { AngleSearchResult, GroupSearchResult, ISearch, ProfileSearchResult, StoryContentSearchResult, StorySearchResult } from '@models/ncx/global-search';
import { User } from '@models/users';
import { CommonService } from '@services/common-service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-global-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() globalSearch: boolean = true;

  @Input() searchText: boolean = true;

  // Loading indicator
  isLoaded = true;

  // Search reults dropdown
  showSearchResults = false;

  // Value of the search input box (Model)
  searchInputValue = '';

  // Body Element
  @ViewChild('scrollElement', { static: false }) scrollElement: ElementRef | any;

  scrollHeight: number = 0;

  // Copied searchInputValue which doesn't change until the next search is peformed
  staticSearch = '';

  // All search results
  searchResult = {
    contentSearchResult: [],
    profileResult: [],
    groupSearchResult: [],
    storySearchResult: [],
    angleSearchResult: [],
  } as ISearch;

  //to understand whether the public angles in search preview should be indented or not
  hasPrivateAngle: boolean = false;

  // Debounce listener for search box changes
  searchInputChange = new Subject();

  private searchLatestInputSubject = new Subject<string>();

  hitTime: number = 0;

  private subs = new SubSink();

  @ViewChild('searchInputField') searchInputField!: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {

    const isSearchResultsContainer = !!event.target.closest('[data-id=global-search-results]');

    const isSearchInputContainer = !!event.target.closest('[data-id=global-search-input]');

    // if search results is opened and clicking outside the results box or the input container, close everything
    if (!isSearchResultsContainer && !isSearchInputContainer && this.showSearchResults) {

      this.closeSearch();

    }

  }

  constructor(
    private router: Router,
    private cS: CommonService,
    private navigationService: NavigationService,
    private gaService: GoogleAnalyticsEventService,
    private messages: NzMessageService,
    private globalSearchService: GlobalSearchService,
    private urlRedirectService: UrlRedirectService,
  ) { }

  ngOnInit() {

    // this.subs.sink = this.searchInputChange.pipe(debounceTime(500)).subscribe(() => {

    //   this.performSearch();

    // });

    // const searchResultsObserver = {
    //   next: (res: any) => {

    //     this.handleSearchResults(res);

    //   },
    //   error: (err: any) => {

    //     if (err.message) {

    //       this.messages.error(err.message);
    //       this.isLoaded = true;

    //     }

    //   },
    // };

    // this.subs.sink = this.searchLatestInputSubject.pipe(
    //   switchMap((search) => this.cS.serviceRequestCommon('post', environment.globalSearch, `?searchString=${encodeURIComponent(search)}`))).
    //   subscribe(searchResultsObserver);

    this.subs.sink = this.globalSearchService.close.subscribe(() => {

      this.closeSearch();

    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('searchText' in changes) {
      this.onModelChange(changes.searchText.currentValue);
    }
  }


  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * ngAfterViewChecked Angular Life cycle
   */
  ngAfterViewChecked(): void {

    this.scrollHeight = this.scrollElement?.nativeElement?.clientHeight;

  }

  /**
   * When input box value changes
   *
   */
  onModelChange(search: string) {

    this.searchInputValue = search;

    this.searchInputChange.next('');

  }

  /**
   * Get search results
   *
   */
  performSearch() {

    if (!this.searchInputValue.length) {

      this.showSearchResults = false;
      this.clearResults();
      this.clearSearch();

    }

    if (this.searchInputValue.length > 2) {

      this.staticSearch = this.searchInputValue;
      this.isLoaded = false;
      this.showSearchResults = true;

      this.clearResults();

      this.gaService.trackGlobalSearch(`/ncx/search-results?searchString=${encodeURIComponent(this.searchInputValue)}`);

      this.hitTime = Math.round(new Date().getTime() / 1000);

      this.searchLatestInputSubject.next(this.searchInputValue);

    }

  }

  /**
   * When enter is pressed on input box, go directly to search page
   *
   */
  viewSearchPage() {

    this.showSearchResults = false;
    this.isLoaded = true;
    const encodedSearchInputValue = encodeURIComponent(this.searchInputValue);

    this.router.navigateByUrl(`/ncx/search-results-new?searchString=${encodedSearchInputValue}`);

    this.closeSearch();

  }

  /**
   * When profile entry is clicked
   *
   */
  viewProfile(profile: ProfileSearchResult) {

    const results = this.searchResult.profileResult;

    const index = results.findIndex((p: ProfileSearchResult) => p.userId === profile.userId);

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, this.staticSearch, 'Selected People Index', 'People Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(1, this.staticSearch, 'Selected People Page Number', 'People Content');

    this.closeSearch();

    this.router.navigateByUrl(`/ncx/profile/:${profile.userId}`);

  }

  /**
   * View group entry when clicked
   *
   */
  viewGroup(group: GroupSearchResult) {

    this.closeSearch();

    // If in iOS, open link in same window using anchor href
    if (this.navigationService.isIOSMobileApp.value) {

      return true;

    } else {

      // if in browser, open link in new window, bypassing href
      window.open(this.urlRedirectService.getRedirectURL(group.storyUrl), '_blank');

      return false;

    }

  }

  /**
   * When story entry is clicked
   *
   */
  viewStory(story: StorySearchResult) {

    const results = this.searchResult.storySearchResult;

    const index = results.findIndex((s: StorySearchResult) => s.storyUrl === story.storyUrl);

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, this.staticSearch, 'Selected Story Index', 'Story Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(1, this.staticSearch, 'Selected Story Page Number', 'Story Content');

    this.closeSearch();

    // If in iOS, open link in same window using anchor href
    if (this.navigationService.isIOSMobileApp.value) {

      if (this.isStoryLandingPage) {

        this.router.navigateByUrl(`/ncx/landing-story/:${story.storyID}`);

        return false;

      } else {

        return true;

      }

    } else {

      // if in browser, open link in new window, bypassing href
      window.open(this.urlRedirectService.getRedirectURL(story.storyUrl), '_blank');
      return false;

    }

  }

  /**
   * When post entry is clicked
   *
   */
  viewPost(post: StoryContentSearchResult) {

    const results = this.searchResult.contentSearchResult;

    const index = results.findIndex((p: StoryContentSearchResult) => p.id === post.id);

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, this.staticSearch, 'Selected Post Index', 'Post Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(1, this.staticSearch, 'Selected Post Page Number', 'Post Content');

    this.closeSearch();

    // If in iOS, open link in same window using anchor href
    if (this.navigationService.isIOSMobileApp.value) {

      if (this.isPostLandingPage) {

        this.router.navigateByUrl(`/ncx/view-post/:${post.id}`);//

        return false;

      } else {

        return true;

      }

    } else {

      // if in browser, open link in new window, bypassing href
      window.open(this.urlRedirectService.getRedirectURL(post.targetURL), '_blank');
      return false;

    }

  }

  /**
   * When angle entry is clicked
   *
   */
  viewAngle(angle: AngleSearchResult) {

    const results = this.searchResult.angleSearchResult;

    const index = results.findIndex((p: AngleSearchResult) => p.angleId === angle.angleId);

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, this.staticSearch, 'Selected Angle Index', 'Angle Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(1, this.staticSearch, 'Selected Angle Page Number', 'Angle Content');

    this.closeSearch();

    // If in iOS, open link in same window using anchor href
    if (this.navigationService.isIOSMobileApp.value) {

      if (this.isAngleLandingPage) {

        this.router.navigateByUrl(`/ncx/landing-angle/:${angle.angleId}`);//

        return false;

      } else {

        return true;

      }

    } else {

      // if in browser, open link in new window, bypassing href
      window.open(this.urlRedirectService.getRedirectURL(angle.angleURL), '_blank');
      return false;

    }

  }

  /**
   * Go to the All Results page
   *
   */
  viewAllResults() {

    this.showSearchResults = false;

    this.router.navigate(['ncx/search-results-new'], { queryParams: { searchString: this.searchInputValue } });

    this.closeSearch();

  }

  /**
   * Create link for viewing avatar
   *
   */
  avatarUrl(url: string) {

    if (url && !url.includes('?ncxjwttoken=')) {

      url = `${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

    }

    return this.urlRedirectService.getRedirectAPIURL(url);

  }

  /**
   * Display the user's name
   *
   */
  formatName(user: User) {

    return Common.formatName(user);

  }

  /**
   * Clear search, clear results, and close the search results
   *
   */
  closeSearch() {

    this.clearSearch();
    this.clearResults();
    this.showSearchResults = false;

  }

  /**
   * Clear search term
   *
   */
  clearSearch() {

    this.searchInputValue = '';
    this.staticSearch = '';

  }

  /**
   * Clear all search results
   *
   */
  clearResults() {

    this.searchResult.storySearchResult = [];
    this.searchResult.profileResult = [];
    this.searchResult.groupSearchResult = [];
    this.searchResult.contentSearchResult = [];
    this.searchResult.angleSearchResult = [];

  }

  get isStoryLandingPage() {

    return this.router.url.includes('ncx/landing-story');

  }

  get isPostLandingPage() {

    return this.router.url.includes('ncx/view-post');

  }

  get isAngleLandingPage() {

    return this.router.url.includes('ncx/landing-angle');

  }

  get isNavClosed(): boolean {

    return this.cS.isNavDrawerClosed.value;

  }

  get hasResults() {

    return this.searchResult.profileResult.length ||
      this.searchResult.storySearchResult.length ||
      this.searchResult.groupSearchResult.length ||
      this.searchResult.contentSearchResult.length ||
      this.searchResult.angleSearchResult.length;

  }

  handleSearchResults(res: any) {

    this.searchResult.storySearchResult = (res.storySearchResult || []) as StorySearchResult[];
    this.searchResult.profileResult = (res.profileResult || []) as ProfileSearchResult[];
    this.searchResult.groupSearchResult = (res.groupSearchResult || []) as GroupSearchResult[];
    this.searchResult.contentSearchResult = (res.contentSearchResult || []) as StoryContentSearchResult[];
    this.searchResult.angleSearchResult = (res.angleSearchResult || []) as AngleSearchResult[];

    this.hasPrivateAngle = false;
    this.searchResult.angleSearchResult?.forEach((angle) => {

      if (angle.angleAccess === AngleMode.Private) {

        this.hasPrivateAngle = true;

      }

    });

    // this.searchResult.storySearchResult?.forEach((story) => {

    //   story.autoGeneratedStoryId = 'HardCoded - SA47FL0001';

    // });

    const respTime = Math.round(new Date().getTime() / 1000);

    const diff = Math.round(respTime - this.hitTime);

    this.gaService.trackUserSearchAction(this.staticSearch, diff);

    this.isLoaded = true;

  }

}
