<div data-component="shared/cards/group/search">
   <!-- GROUP TITLE -->
   <div
      class="title"
      [innerHTML]="group.snippet?.title?.trim() || group.storyTitle | safeHtml"></div>

   <div class="group-info">
      <!-- NUMBER OF MEMBERS -->
      <div class="members">{{ group.membersCount }} member{{ group.membersCount !== 1 ? 's' : '' }}</div>

      <!-- both href and click events are necessary in order
      for click events to work in ios-shell app -->

      <div class="circular-icon">
         <i
            nz-icon
            nzType="arrow-right"
            nzTheme="outline"
            [href]="group.storyUrl"
            (click)="onViewGroup()"></i>
      </div>
   </div>
</div>
