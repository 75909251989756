<div
   class="ncx-profile-card"
   data-component="shared/cards/profile/search">
   <div class="grid-container">
      <div class="image-container">
         <div class="avatar">
            <!-- AVATAR (WITH PICTURE) -->
            <nz-avatar
               *ngIf="profile.thumbnailUrl"
               [nzSrc]="Common.formS3Link(profile.thumbnailUrl)"></nz-avatar>

            <!-- AVATAR (NO PICTURE - ICON ONLY) -->
            <nz-avatar
               *ngIf="!profile.thumbnailUrl"
               nzIcon="user"></nz-avatar>
         </div>
      </div>
      <div class="details-container">
         <div
            class="name"
            [innerHTML]="profile.snippet?.title?.trim() || profileName | safeHtml"></div>
         <div class="view-profile"><button (click)="onViewProfile()">View Profile</button></div>
      </div>
   </div>

   <ng-template #profileCardTemplate>
      <app-profile-overlay
         [profileData]="{ userId: profile.userId, displayName: profile.displayName }"></app-profile-overlay>
   </ng-template>
</div>
