<div #guestBookingTable>
   <nz-table
      nzBordered
      class="related-content-table"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr>
            <th
               [nzWidth]="preferenceColumns['TYPE'].width + 'px'"
               [rowspan]="preferenceColumns['TYPE'].rowSpan"
               [nzFilters]="typeFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="typeFilterEvent($event)">
               {{ preferenceColumns['TYPE'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['IDSTATUS'].width + 'px'"
               [nzFilters]="statusFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="statusFilterEvent($event)">
               {{ preferenceColumns['IDSTATUS'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="idSearchVisible"
                  [nzActive]="idSearchText.length > 0"
                  [nzDropdownMenu]="idTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['STARTDATE'].width + 'px'"
               [nzLeft]="true"
               [rowspan]="preferenceColumns['STARTDATE'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="dateSortOrder"
               (nzSortOrderChange)="startDateSortEvent($event)">
               {{ preferenceColumns['STARTDATE'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['LOCATION'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['LOCATION'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="locationSearchVisible"
                  [nzActive]="locationSearchText.length > 0"
                  [nzDropdownMenu]="locationTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['CREWCOUNT'].width + 'px'"
               [rowspan]="preferenceColumns['CREWCOUNT'].rowSpan">
               {{ preferenceColumns['CREWCOUNT'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['REQUESTER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['REQUESTER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="requesterSearchVisible"
                  [nzActive]="requesterSearchText.length > 0"
                  [nzDropdownMenu]="requesterTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOWPROJECT'].width + 'px'"
               [rowspan]="preferenceColumns['SHOWPROJECT'].rowSpan"
               nzCustomFilter>
               {{ preferenceColumns['SHOWPROJECT'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="showOrProjectSearchVisible"
                  [nzActive]="showOrProjectSearchText.length > 0"
                  [nzDropdownMenu]="showOrProjectTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>
         </tr>
      </thead>
      <tbody>
         @for (data of relatedContentData; track data) {
            <tr>
               <td>Crew Form Type</td>

               <td>
                  <span
                     [style.color]="requestStatusDetails[data.content.statusIndex]?.color"
                     [ngClass]="requestStatusDetails[data.content.statusIndex]?.class"
                     class="ecm">
                     <span
                        nz-icon
                        style="margin-right: 5px"
                        [nzType]="requestStatusDetails[data.content.statusIndex]?.icon"
                        [nzTheme]="
                           requestStatusDetails[data.content.statusIndex]?.icon === 'sync' ? 'outline' : 'fill'
                        "></span>
                     <span
                        nz-tooltip
                        nzTooltipPlacement="bottomLeft"
                        nzTooltipColor="white">
                        <a
                           [ngStyle]="{ color: requestStatusDetails[data.content.statusIndex]?.color }"
                           [routerLink]="[data.content.zrouterlink, 'view', data.content.zid]">
                           {{ data.content.zid }}
                        </a>
                     </span>
                  </span>
               </td>

               <td>
                  {{ data.content.requestCreated | date: 'MM/dd' }} ({{ data.content.zRequestersRemainingLength }})
               </td>

               <td>{{ data.content.zDestinationsDisplayString }}</td>
               <td [(nzExpand)]="data.expand">{{ data.content.zRequestersRemainingLength }}</td>
               <td>{{ data.content.zRequestersDisplayString }}</td>
               <td>{{ data.content.zShowUnitsDisplayString }}</td>
            </tr>
            <tr [nzExpand]="data.expand">
               <nz-table
                  nzBordered
                  #innerTable
                  class="related-content-table"
                  [nzData]="innerRelatedContentData"
                  [nzFrontPagination]="false"
                  [nzTotal]="tableParams.total"
                  [nzPageIndex]="tableParams.page"
                  [nzPageSize]="tableParams.limit"
                  nzShowSizeChanger
                  [nzLoading]="!isLoaded"
                  [nzLoadingDelay]="750"
                  (nzPageIndexChange)="onChangePage($event)"
                  (nzPageSizeChange)="onChangePageSize($event)"
                  [nzScroll]="{ y: 200 + 'px' }"
                  nzShowQuickJumper>
                  <thead>
                     <tr>
                        <th
                           [nzWidth]="preferenceColumns['TYPE'].width + 'px'"
                           [rowspan]="preferenceColumns['TYPE'].rowSpan"
                           [nzFilters]="typeFilters"
                           [nzFilterFn]="true"
                           (nzFilterChange)="typeFilterEvent($event)">
                           {{ preferenceColumns['TYPE'].text }}
                        </th>

                        <th
                           [nzWidth]="preferenceColumns['IDSTATUS'].width + 'px'"
                           [nzFilters]="statusFilters"
                           [nzFilterFn]="true"
                           (nzFilterChange)="statusFilterEvent($event)">
                           {{ preferenceColumns['IDSTATUS'].text }}
                           <nz-filter-trigger
                              [(nzVisible)]="idSearchVisible"
                              [nzActive]="idSearchText.length > 0"
                              [nzDropdownMenu]="idTemplate">
                              <span
                                 nz-icon
                                 nzType="search"></span>
                           </nz-filter-trigger>
                        </th>

                        <th
                           [nzWidth]="preferenceColumns['STARTDATE'].width + 'px'"
                           [nzLeft]="true"
                           [rowspan]="preferenceColumns['STARTDATE'].rowSpan"
                           [nzSortFn]="true"
                           [nzSortOrder]="dateSortOrder"
                           (nzSortOrderChange)="startDateSortEvent($event)">
                           {{ preferenceColumns['STARTDATE'].text }}
                        </th>

                        <th
                           [nzWidth]="preferenceColumns['LOCATION'].width + 'px'"
                           nzCustomFilter>
                           {{ preferenceColumns['LOCATION'].text }}
                           <nz-filter-trigger
                              [(nzVisible)]="locationSearchVisible"
                              [nzActive]="locationSearchText.length > 0"
                              [nzDropdownMenu]="locationTemplate">
                              <span
                                 nz-icon
                                 nzType="search"></span>
                           </nz-filter-trigger>
                        </th>

                        <th
                           [nzWidth]="preferenceColumns['CREWCOUNT'].width + 'px'"
                           [rowspan]="preferenceColumns['CREWCOUNT'].rowSpan">
                           {{ preferenceColumns['CREWCOUNT'].text }}
                        </th>

                        <th
                           [nzWidth]="preferenceColumns['REQUESTER'].width + 'px'"
                           nzCustomFilter>
                           {{ preferenceColumns['REQUESTER'].text }}
                           <nz-filter-trigger
                              [(nzVisible)]="requesterSearchVisible"
                              [nzActive]="requesterSearchText.length > 0"
                              [nzDropdownMenu]="requesterTemplate">
                              <span
                                 nz-icon
                                 nzType="search"></span>
                           </nz-filter-trigger>
                        </th>

                        <th
                           [nzWidth]="preferenceColumns['SHOWPROJECT'].width + 'px'"
                           [rowspan]="preferenceColumns['SHOWPROJECT'].rowSpan"
                           nzCustomFilter>
                           {{ preferenceColumns['SHOWPROJECT'].text }}
                           <nz-filter-trigger
                              [(nzVisible)]="showOrProjectSearchVisible"
                              [nzActive]="showOrProjectSearchText.length > 0"
                              [nzDropdownMenu]="showOrProjectTemplate">
                              <span
                                 nz-icon
                                 nzType="search"></span>
                           </nz-filter-trigger>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     @for (data of innerRelatedContentData; track data) {
                        <tr>
                           <td>Crew Form Type</td>

                           <td>
                              <span
                                 [style.color]="requestStatusDetails[data.content.statusIndex]?.color"
                                 [ngClass]="requestStatusDetails[data.content.statusIndex]?.class"
                                 class="ecm">
                                 <span
                                    nz-icon
                                    style="margin-right: 5px"
                                    [nzType]="requestStatusDetails[data.content.statusIndex]?.icon"
                                    [nzTheme]="
                                       requestStatusDetails[data.content.statusIndex]?.icon === 'sync'
                                          ? 'outline'
                                          : 'fill'
                                    "></span>
                                 <span
                                    nz-tooltip
                                    nzTooltipPlacement="bottomLeft"
                                    nzTooltipColor="white">
                                    <a
                                       [ngStyle]="{ color: requestStatusDetails[data.content.statusIndex]?.color }"
                                       [routerLink]="[data.content.zrouterlink, 'view', data.content.zid]">
                                       {{ data.content.zid }}
                                    </a>
                                 </span>
                              </span>
                           </td>

                           <td>
                              {{ data.content.requestCreated | date: 'MM/dd' }} ({{
                                 data.content.zRequestersRemainingLength
                              }})
                           </td>

                           <td>{{ data.content.zDestinationsDisplayString }}</td>
                           <td [(nzExpand)]="expandRecord">{{ data.content.zRequestersRemainingLength }}</td>
                           <td>{{ data.content.zRequestersDisplayString }}</td>
                           <td>{{ data.content.zShowUnitsDisplayString }}</td>
                        </tr>
                     }
                  </tbody>
               </nz-table>
            </tr>
         }
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #idTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Id(s)"
            (keyup.enter)="searchId()"
            [(ngModel)]="idSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchId()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetIdSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #locationTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Location(s)"
            (keyup.enter)="searchLocation()"
            [(ngModel)]="locationSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchLocation()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetLocationSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #requesterTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Requester(s)"
            (keyup.enter)="searchRequester()"
            [(ngModel)]="locationSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchRequester()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetRequesterSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showOrProjectTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show / Project(s)"
            (keyup.enter)="searchShowOrProject()"
            [(ngModel)]="showOrProjectSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchShowOrProject()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetShowOrProjectSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>
