import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AttachmentsNewComponent } from '@components/attachments-new/attachments-new.component';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { Post } from '@models/ncx/post';
import { UserRoles } from '@models/types/user-roles';
import { IFunctionAbility } from '@models/users';
import { AuthService } from '@services/auth/auth.service';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { DataService } from '@services/data.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
})
export class CreatePostComponent implements OnInit, AfterViewInit, AfterViewChecked {

  shareMessage: string = '';

  viewPreview = false;

  googleDocs = '';

  isLoaded = false;

  postDetails = {} as Post;

  postId;

  isVisible = false;

  radioValue: string = 'Post';

  postApiUrl: string = '';

  getStoryApiUrl: string = '';

  sendEmailUpdate = false;

  canSendEmailUpdate = false;

  userDetails: any;

  getStoryTitlesAPI: string = '';

  embedlyLink: any;

  isEmbedlyVisible: boolean;

  isAttachmentDisabled: boolean;

  getAvailableLabelsAPI: string = '';

  availableLabels: any = [];

  isSavedAsDraft: boolean;

  postTitleSubject: Subject<string> = new Subject();

  isArchieved = true;

  previewSiderIsCollapsed = true;

  previewDrawerIsCollapsed = true;

  functionAbility = {} as IFunctionAbility;

  userInfoDetails;

  isEmitted: boolean;

  privateStoryFlag: any;
  saveDiscussionId: any;
  postList: any;

  showPreviewFlag: boolean;

  postRelatedTopics: any[] = [];

  userrole: any;

  isStandards: boolean;

  isPublishDisable = false;

  postAccessMode = 'public';

  postType = {
    draft: 'Draft',
    update: 'Update',
    element: 'Element',
  };

  postDetailsTabBodyHeight = 0;

  metadataTabBodyHeight = 0;

  footerHeight = 61;

  screenSize = '';

  windowWidth;

  desktopMode = true;

  disableStory = false;

  disableAngle = false;

  storyDetailsTooltip: string = 'Search by story name. You can add this post to multiple stories';

  showStoryMandatory: boolean = false;

  highlightMandatoryStoryOrAngle: boolean = false;

  angleUpdateFromAPI: EventEmitter<any[]> = new EventEmitter();

  setPostFilters: Subject<boolean> = new Subject();

  isMobile = false;

  reportableLabel = 'REPORTABLE';

  limitedLicenseLabel = 'LIMITED LICENSE';

  standardsLabel = 'STANDARDS';

  isContentChanged = false;

  isModelOpened = false;

  pageFrom = '';

  paramId = '';
  item: any;
  eventUpdater: any;
  fileItemEvent: any;
  fileItem: any;
  userRoles = UserRoles;

  enablePreviewPublish = false;

  @ViewChild('appLayout', { static: false }) appLayoutElement: ElementRef | any;

  @ViewChild('searchPostTitleText') searchPostTitleText: ElementRef;

  @ViewChild('postDetailsTabBody', { static: false }) postDetailsTabBodyElement: ElementRef | any;

  @ViewChild('metadataTabBody', { static: false }) metadataTabBodyElement: ElementRef | any;

  @ViewChild('footerElement', { static: false }) footerElement: ElementRef | any;
  @ViewChild(AttachmentsNewComponent, { static: false }) attachmentChild: any;
  confirmed?: boolean = false;

  isDraftSaved?: boolean = false;

  draftPostId: any = '';

  discardSelected: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {

    this.windowResizeEvent();

  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: BeforeUnloadEvent) {

    if (this.isContentChanged && !this.isDraftSaved && this.paramId !== '') {

      console.log('preventing close tab');
      $event.preventDefault();
      $event.returnValue = ' ';

      if (!this.isModelOpened) {

        this.modalService.create({
          nzTitle: 'Do you want to save your edits?',
          nzContent: 'Exiting this post without saving your changes will lock this post for you and other users',
          nzOkText: 'Save Draft',
          nzOkDanger: true,
          nzCancelText: 'Discard',
          nzWidth: 405,
          nzBodyStyle: {
            padding: '16px',
          },
          nzOnCancel: () => {

            this.isModelOpened = false;

          },
          nzFooter: [
            {
              label: 'Discard',
              type: 'default',
              onClick: () => {

                this.isDraftSaved = true;
                this.isModelOpened = false;
                this.redirectTo();
                this.modalService.closeAll();

              },
            },
            {
              label: 'Save Draft',
              danger: true,
              type: 'primary',
              onClick: () => {

                this.isModelOpened = false;
                this.saveAsDraft();
                this.modalService.closeAll();

              },
            },
          ],
        });

      }
      this.isModelOpened = true;

    }

  }

  constructor(
    private router: ActivatedRoute,
    private cService: CommonService,
    private toastService: ToastService,
    private authService: AuthService,
    private reRoute: Router,
    private modalService: NzModalService,
    private location: Location,
    private tI: TransferInformationComponent,
    private breakpointService: BreakpointService,
    private changeDetector: ChangeDetectorRef,
    private dataService: DataService,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private http: HttpClient,
  ) {

    this.postTitleSubject.pipe(
      debounceTime(1000),
    ).subscribe(() => {

      this.autoSaveAsDraft();

    });

    // this.setPostFilters.subscribe(() => {
    //   this.setFilters();
    // });

  }

  subject = new Subject<boolean>();

  ngOnInit() {

    this.isLoaded = false;
    this.privateStoryFlag = false;
    this.radioValue = 'Post';
    this.postApiUrl = environment.getPostApi;
    this.getStoryApiUrl = environment.getStoriesAPI;
    this.getStoryTitlesAPI = environment.getStoryTitles;
    this.getAvailableLabelsAPI = environment.getPostFiltersURL;

    this.getAvailableLabels();
    this.getUserRole();

    this.router.params.subscribe(
      (params: Params) => {

        this.setPost(params);

      });

    this.router.queryParams.subscribe((params: Params) => {

      const { storyId, angleId } = params;

      if (storyId) {

        this.getStoryDetails(storyId);

      }
      if (angleId) {

        this.getAngleDetails(angleId);

      }

    });

    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });

    this.tI.getUserFunctionAbility().subscribe(userAccess => {

      this.functionAbility = userAccess;

    });

    this.userInfoDetails = {
      userId: this.tI.userInfoDetails.userId,
      role: this.tI.userInfoDetails.role,
    };

    //this.fetchUserList();

    this.tI.userProfile.subscribe(user => {

      this.userDetails = user || {};

    });

    this.dataService.data$.subscribe(data => {

      this.pageFrom = data;

    });

  }

  canDeactivate(): Observable<boolean> {

    console.log('canDeactivate:' + this.isContentChanged);
    return new Observable<boolean>((observer: Observer<boolean>) => {

      if (this.isContentChanged && !this.isDraftSaved && !this.isModelOpened) {

        this.isModelOpened = true;
        const modelRef = this.modalService.create({
          nzTitle: 'Do you want to save your edits?',
          nzContent: 'Exiting this post without saving your changes will lock this post for you and other users',
          nzOkText: 'Save Draft',
          nzOkDanger: true,
          nzCancelText: 'Discard',
          nzWidth: 405,
          nzBodyStyle: {
            padding: '16px',
          },
          nzOnCancel: () => {

            this.isModelOpened = false;

          },
          nzFooter: [
            {
              label: 'Discard',
              type: 'default',
              onClick: () => {

                this.isModelOpened = false;
                observer.next(true);
                observer.complete();
                this.modalService.closeAll();
                this.redirectTo();

              },
            },
            {
              label: 'Save Draft',
              danger: true,
              type: 'primary',
              onClick: () => {

                this.isModelOpened = false;
                this.saveAsDraft();
                this.modalService.closeAll();
                observer.next(true);
                observer.complete();

              },
            },
          ],

        });

      } else if (this.isContentChanged && !this.isDraftSaved) {

        this.isModelOpened = true;
        const modelRef = this.modalService.create({
          nzTitle: 'Do you want to save your edits?',
          nzContent: 'Exiting this post without saving your changes will lock this post for you and other users',
          nzOkText: 'Save Draft',
          nzOkDanger: true,
          nzCancelText: 'Discard',
          nzWidth: 405,
          nzBodyStyle: {
            padding: '16px',
          },
          nzOnCancel: () => {

            this.isModelOpened = false;

          },
          nzFooter: [
            {
              label: 'Discard',
              type: 'default',
              onClick: () => {

                this.isModelOpened = false;
                observer.next(true);
                observer.complete();
                this.redirectTo();
                this.modalService.closeAll();

              },
            },
            {
              label: 'Save Draft',
              danger: true,
              type: 'primary',
              onClick: () => {

                this.isModelOpened = false;
                this.saveAsDraft();
                this.modalService.closeAll();
                observer.next(true);
                observer.complete();

              },
            },
          ],
        });

      } else if (!this.isContentChanged && this.isDraftSaved) {

        console.log('this.isDraftSaved');
        observer.next(true);
        observer.complete();

      } else if (!this.isContentChanged && !this.isDraftSaved && !this.isModelOpened) {

        observer.next(true);
        observer.complete();

      }

    });

  }

  ngAfterViewInit() {

    this.searchPostTitleText.nativeElement.focus();
    this.changeDetector.detectChanges();

  }

  ngAfterViewChecked() {

    this.windowResizeEvent();
    this.changeDetector.detectChanges();

  }

  setPost(params) {

    if (params && params.postId) {

      const id = params.postId;

      this.postId = id.substr(1);
      this.paramId = id.substr(1);
      this.isSavedAsDraft = true;
      this.isEmitted = false;
      this.getPostDetails(this.postId);

    } else {

      this.createNewPostObject();
      this.isEmitted = true;
      this.isLoaded = true;
      this.isSavedAsDraft = false;

    }

  }

  getStoryDetails(storyId: any) {

    this.isLoaded = false;
    this.cService.serviceRequestCommon('get', this.getStoryApiUrl + '/', storyId).subscribe((response: any) => {

      console.log('GET STORY DETAILS', response);
      if (response && response.storyId) {

        const postRelatedStories = [];

        postRelatedStories.push(this.initAdditionalStoryObj(response));
        this.postDetails.relatedStories = postRelatedStories;
        this.postDetails.topicDetails = response.topicDetails;

      }
      this.isLoaded = true;

    }, () => {

      this.toastService.createMessage('error', 'Error while fetching story details');
      this.isLoaded = true;

    });

  }

  /**
   * Fetching Angle Details based on Angle Id
   */
  getAngleDetails(angleId): void {

    const queryStr = `/${angleId}` + '?isPosts=false';

    this.cService.serviceRequestCommon('get', environment.getAngleApi, queryStr).subscribe((angleDetails: any) => {

      console.log('GET ANGLE DETAILS', angleDetails);
      const angle = {
        angleId: angleDetails.angleId,
        angleTitle: angleDetails.angleTitle,
        angleIdTitle: '(' + angleDetails.angleId + ') ' + angleDetails.angleTitle,
        angleAccess: angleDetails.angleAccess,
      };

      const relatedAngles = [];

      relatedAngles.push(angle);
      this.postDetails.relatedAngles = relatedAngles;

      this.angleUpdateFromAPI.emit(this.postDetails.relatedAngles);

      this.isLoaded = true;

    }, () => {

      this.toastService.createMessage('error', 'Error while fetching angle details');
      this.isLoaded = true;

    });

  }

  toggleEmailCheckbox() {

    this.postDetails.doNotSendEmail = this.sendEmailUpdate;

  }

  toggleSider(): void {

    this.previewSiderIsCollapsed = !this.previewSiderIsCollapsed;

  }

  createNewPostObject() {

    this.postDetails.postId = 0;
    this.postDetails.postTitle = '';
    this.postDetails.postContent = '';
    this.postDetails.postType = 'Update';
    this.postDetails.relatedStories = [];
    this.postDetails.relatedAngles = [];
    this.postDetails.standardsGuidance = this.defaultStandardGuidanceContent;
    this.postDetails.reportableApprover = this.defaultReportableContent;
    this.postDetails.limitedLicenseApprover = this.defaultLimitedLicenseContent;
    this.postDetails.postAttachments = [];
    this.postDetails.attachmentDetails = this.defaultAttachmentDetails;
    this.postDetails.isGenerateTags = false;
    this.postDetails.storyId = null;
    this.postDetails.editorialStatus = [];
    this.postDetails.postLegal = [];
    this.postDetails.topicDetails = [];
    this.postDetails.postContentTags = [];

  }

  attachStoryToPost(storyEvent) {

    /**
     * Only the topics from the first story will be added to post automatically.
     * Topics from the remaining stories won't be added automatically to the post.
     */
    this.isDraftSaved = false;
    this.setTopicsFromStory(storyEvent);
    this.postDetails.relatedStories = storyEvent.relatedStories ?? [];
    this.postDetails.isPinnedOnTop = this.postDetails.relatedStories.length ? storyEvent.isPinnedOnTop : false;
    this.highlightAngleStorySection();
    this.autoSaveDraft();
    this.isContentChanged = true;
    console.log('attachStoryToPost: ' + this.isContentChanged);

  }

  setTopicsFromStory(storyEvent) {

    this.postRelatedTopics = this.postDetails.topicDetails;
    if (this.postRelatedTopics.length == 0 && storyEvent.relatedStories.length > 0 && storyEvent.relatedStories[0].topicDetails) {

      this.postRelatedTopics = storyEvent.relatedStories[0]?.topicDetails;

    } else {

      if (storyEvent.relatedStories.length > 0 && storyEvent.relatedStories[0].topicDetails) {

        storyEvent.relatedStories[0].topicDetails.forEach(element => {
          if (!this.postRelatedTopics.some(existingItem => existingItem.topicId === element.topicId)) {
            this.postRelatedTopics.push(element);
          }
        });
      }
    }

    this.postDetails.topicDetails = [];
    setTimeout(() => { this.postDetails.topicDetails = this.postRelatedTopics; }, 100);
  }

  autoSaveDraft() {

    if (this.canSaveDraft) {

      this.publishAs('Draft', false);

    }

  }

  saveDraft() {

    this.publishAs('Draft', false);

  }

  saveAsDraft() {

    this.publishAs('Draft', true);

  }

  highlightAngleStorySection() {

    this.highlightMandatoryStoryOrAngle = !this.hasStoryOrAngle;

  }

  get hasStoryOrAngle(): boolean {

    return (this.postDetails.relatedStories?.length > 0 || this.postDetails.relatedAngles?.length > 0) ? true : false;

  }

  get canSaveDraft(): boolean {

    return this.postDetails && this.postDetails.postTitle && this.hasStoryOrAngle && !this.isSavedAsDraft;

  }

  get hasAttachments(): boolean {

    return this.postDetails.postAttachments?.length > 0 ||
      this.postDetails.attachmentDetails?.linkToSource?.length > 0 ||
      this.postDetails.attachmentDetails?.captureDate?.toString()?.length > 0 ||
      this.postDetails.attachmentDetails?.isMandatoryCredit?.length > 0 ||
      this.postDetails.attachmentDetails?.creditName?.length > 0 ||
      this.postDetails.attachmentDetails?.clearanceStatus?.length > 0;

  }

  get currentPostType(): string {

    return this.hasAttachments ? this.postType.element : this.postType.update;

  }

  getPostType(publishingPostType): string {

    return publishingPostType === this.postType.draft ? this.postType.draft : this.currentPostType;

  }

  attachAnglesToPost(angleEvent) {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    console.log('attachAnglesToPost: ' + this.isContentChanged);

    this.postDetails.relatedAngles = angleEvent.postRelatedAngles ?? [];

    const postRelatedStories = this.postDetails.relatedStories ?? [];

    const postRelatedStoriesIds = postRelatedStories.map((story) => {

      return story.storyId;

    });

    const angleRelatedStories = [];

    this.postDetails.relatedAngles?.map((angle) => {

      angle.relatedStories?.map((story) => {

        if (!postRelatedStoriesIds.includes(story.storyId)) {

          if (postRelatedStoriesIds.length == 0) { // when adding an angle, and the angle has a story and it is the first (or only) story added, then by default it should be primary

            story.isPrimary = true;

          }
          angleRelatedStories.push(story);

          /**
           * when adding an angle to the post, if the post doesn't have any existing story then the topics from the related story of the angle can be
           * added to the post. If the post has existing story then the topics from the related story of the angle can't be added. Similar logic in
           * adding the story directly to the post. Only the topics from the first story will be added to post automatically.
           */
          if (postRelatedStoriesIds.length == 0) {

            this.setTopicsFromAngle(story);

          }

        }

      });
      delete angle.relatedStories;

    });

    this.postDetails.relatedStories = [...postRelatedStories, ...angleRelatedStories];

    this.highlightAngleStorySection();
    this.autoSaveDraft();

  }

  setTopicsFromAngle(story) {

    this.postRelatedTopics = this.postDetails.topicDetails;
    if (this.postRelatedTopics?.length == 0) {

      this.postRelatedTopics = story.topicDetails;

    } else {
      story?.topicDetails?.forEach((item) => {
        if (!this.postRelatedTopics.some(existingItem => existingItem.topicId === item.topicId)) {

          this.postRelatedTopics.push(item);

        }
      });
    }

    this.postDetails.topicDetails = [];
    setTimeout(() => { this.postDetails.topicDetails = this.postRelatedTopics; }, 100);
  }

  attachTopicsToPost(TopicObj) {

    this.isDraftSaved = false;

    console.log('attachTopicToPost :', TopicObj, this.postRelatedTopics);

    const newTopicIds = TopicObj.map(topic => topic.topicId.toString());

    const existingTopicIds = this.postDetails.topicDetails ? this.postDetails.topicDetails?.map(topic => topic.topicId.toString()) : [];

    if (!this.arraysEqual(newTopicIds, existingTopicIds)) {

      this.postDetails.topicDetails = TopicObj;

      this.isContentChanged = true;

    }

    this.postDetails.postTopicIds = this.postDetails.topicDetails ? this.postDetails.topicDetails?.map(topic => topic.topicId.toString()) : [];

    if ((this.postDetails && this.postDetails.postTitle && this.postDetails.topicDetails
      && this.postDetails.topicDetails.length > 0 && !this.isSavedAsDraft)) {

      this.publishAs('Draft', false);

    }

  }

  arraysEqual(arr1: string[], arr2: string[]): boolean {

    if (arr1.length !== arr2.length) {

      return false;

    }
    return arr1.every((value, index) => value === arr2[index]);

  }

  rteContentChanged(ischanged: string) {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    console.log('rteContentChnaged: ' + this.isContentChanged);
    this.saveDraft();

  }

  onInput(_value: string): void {
  }

  titleChange() {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    console.log('titleChange: ' + this.isContentChanged);

    this.postTitleSubject.next(this.postDetails.postTitle);

  }

  get defaultReportableContent() {

    return {
      isOfficial: 'No',
      seniorApprovalName: '',
      additionalNotes: '',
    };

  }

  get defaultStandardGuidanceContent() {

    return { notes: '' };

  }

  get defaultLimitedLicenseContent() {

    return { additionalNotes: '' };

  }

  get defaultAttachmentDetails() {

    return {
      linkToSource: '',
      clearanceStatus: '',
      captureDate: '',
      isMandatoryCredit: '',
      creditName: '',
      divisions: [],
      shows: [],
    };

  }

  get isReportableApproverSectionValid() {

    if (this.postDetails.editorialStatus?.includes(this.reportableLabel)) {

      const reportableApproverOptions: string[] = ['No', 'Yes'];

      if (reportableApproverOptions.includes(this.postDetails.reportableApprover.isOfficial) &&
        this.postDetails.reportableApprover.seniorApprovalName !== '') {

        return true;

      } else {

        return false;

      }

    } else {

      return true;

    }

  }

  // htmlToText(html) {
  //   const tmp = document.createElement('DIV');
  //   tmp.innerHTML = html;
  //   return tmp.textContent || tmp.innerText || tmp.innerHTML || '';
  // }

  get isTitleValid() {

    if (this.postDetails.postTitle && this.postDetails.postTitle.trim() !== '') {

      return true;

    } else {

      return false;

    }

  }

  // get isContentValid() {
  //   if (this.htmlToText(this.postDetails.postContent) !== '' && this.postDetails.postContent.trim() != '<p><br></p>') { // <p><br></p> exists in
  // content when a template added and removed completely return true; } else { return false; } }

  get isAngleStorySectionValid() {

    if (this.hasStoryOrAngle) {

      return true;

    } else {

      return false;

    }

  }

  get isLimitedLicenseSectionValid() {

    if (this.postDetails.postLegal?.includes(this.limitedLicenseLabel)) {

      return this.postDetails.limitedLicenseApprover.additionalNotes !== '' ? true : false;

    } else {

      return true;

    }

  }

  get isStandardGuidanceSectionValid(): boolean {

    if (this.postDetails.editorialStatus?.includes(this.standardsLabel)) {

      // const html = this.postDetails.standardsGuidance.notes;
      // const div = document.createElement('div');
      // div.innerHTML = html;
      // const text = div.textContent || div.innerText || '';

      // return text !== '' && text !== undefined;
      return Common.isInputHasValidTextContent(this.postDetails.standardsGuidance.notes);

    } else {

      return true;

    }

  }

  get isElementDetailsSectionValid() {

    if (this.isAttachmentDetailsSectionValid) {

      return true;

    } else {

      return false;

    }

  }

  get isAttachmentDetailsSectionValid() {

    if (this.postDetails.attachmentDetails && this.postDetails.attachmentDetails?.isMandatoryCredit === 'yes' &&
      !this.postDetails.attachmentDetails.creditName) {

      this.toastService.createMessage('warning', 'Please provide Credit Name');
      return false;

    } else {

      return true;

    }

  }

  // get isMaterialsSectionValid() {
  //   if (this.postDetails.postAttachments && this.postDetails.postAttachments?.length > 0) {
  //     if (this.postDetails.attachmentDetails?.clearanceStatus == '' ||
  //       this.postDetails.attachmentDetails?.clearanceStatus == null) {
  //       this.toastService.createMessage('warning', 'Please select Materials Cleared For');
  //       return false;
  //     } else {
  //       if (this.postDetails.attachmentDetails?.clearanceStatus == 'no' &&
  //         (this.postDetails.attachmentDetails?.divisions?.length == 0 && this.postDetails.attachmentDetails?.shows?.length == 0)) {
  //         this.toastService.createMessage('warning', 'Please select Division(s)/Show(s)');
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }

  setCanSendEmailUpdateValue() {

    if (this.postDetails.postVersionNumber === 1 && this.postDetails.postVersionState !== 'Draft') {

      this.canSendEmailUpdate = true;

    } else if (this.postDetails.postVersionNumber === 1 && this.postDetails.postVersionState === 'Draft') {

      this.canSendEmailUpdate = false;

    } else if (this.postDetails.postVersionNumber >= 1) {

      this.canSendEmailUpdate = true;

    } else {

      this.canSendEmailUpdate = false;

    }

    this.sendEmailUpdate = this.canSendEmailUpdate;

  }

  validateDraftPost(): boolean {

    if (!this.isAngleStorySectionValid) {

      return false;

    }

    if (!this.isTitleValid) {

      return false;

    }

    return true;

  }

  validatePost(_data): boolean {

    if (!this.isReportableApproverSectionValid) {

      this.toastService.createMessage('warning', 'Provide senior approver name to publish');
      return false;

    }

    if (!this.isStandardGuidanceSectionValid) {

      this.toastService.createMessage('warning', 'Provide Standards Guidance to publish');
      return false;

    }

    if (!this.isLimitedLicenseSectionValid) {

      this.toastService.createMessage('warning', 'Please list all License Limitations');
      return false;

    }

    if (!this.isTitleValid) {

      this.toastService.createMessage('warning', 'Give your Post a title to publish');
      return false;

    }

    if (!Common.isInputHasValidTextContent(this.postDetails.postContent)) {

      this.toastService.createMessage('warning', 'Give your Post a description to publish');
      return false;

    }

    console.log(' this.postDetails.postContent : ', this.postDetails.postContent);

    if (!this.isElementDetailsSectionValid) {

      return false;

    }

    if (!this.isAngleStorySectionValid) {

      this.toastService.createMessage('warning', 'Select a Story/Angle to publish');
      this.highlightAngleStorySection();
      return false;

    }

    return true;

  }

  rteEdit($event: any) {

    this.isContentChanged = true;
    this.isDraftSaved = false;

  }

  autoSaveAsDraft() {

    if (!this.isSavedAsDraft && !this.postId) {

      this.publishAs('Draft', false);

    }

  }

  saveDrat() {

    this.publishAs(this.postType.draft, false);

  }

  savePost() {

    this.publishAs(this.currentPostType, false);

  }

  publishAs(publishingPostType: string, navigateDraft: boolean) {

    if (this.isVisible || this.isEmbedlyVisible) {

      return;

    }

    if (publishingPostType === this.postType.draft && !this.validateDraftPost() ||
      publishingPostType !== this.postType.draft && !this.validatePost(publishingPostType)) {

      this.isLoaded = true;
      return;

    }

    // Return if previous transaction not completed
    if (!this.isLoaded) {

      console.log('Retuering : Previous transaction still going on');
      return;

    }
    this.isLoaded = false;

    this.getInputDetails(publishingPostType);

    if (this.postDetails.livePostId && this.postDetails.postType !== this.postType.draft) {

      this.getlivePost(this.postDetails.postVersionGroupId, this.postDetails.postType);

    } else if (this.postDetails.postId && this.postDetails.postType !== this.postType.draft) {

      this.isPublishDisable = false;
      this.savePostDetails(false, false);

    } else if (this.postDetails.postType === this.postType.draft) {

      this.savePostDetails(true, navigateDraft);

    } else {

      this.isLoaded = true;

    }

  }

  getInputDetails(publishingPostType: string) {

    this.postDetails.isPostPublished = publishingPostType !== this.postType.draft;
    this.postDetails.postType = this.getPostType(publishingPostType);
    const standardGuidanceNotes = this.postDetails.standardsGuidance?.notes;

    this.postDetails.standardsGuidance = this.defaultStandardGuidanceContent;
    this.postDetails.standardsGuidance.notes = standardGuidanceNotes;

    this.postDetails.doNotSendEmail = this.sendEmailUpdate;
    this.postDetails.isGenerateTags = (this.postDetails.isGenerateTags) ? this.postDetails.isGenerateTags : false;
    this.postDetails.postAccessMode = this.postAccessMode;

    this.postDetails.postContent = this.authService.removeJWTTokenFromLink(this.postDetails.postContent, 'img');   //JWT Token has to be removed from
    // the
    // img S3 link in the content to be saved

    // if (this.postDetails.topicDetails?.length === 0) {

    //   this.postDetails.topics = [];

    // }

    this.postDetails.postTopicIds = this.postDetails.topicDetails ? this.postDetails.topicDetails?.map(topic => topic.topicId.toString()) : [];

  }

  getlivePost(postversiongroupid, _data) {

    let livePostDetailsTime = '';

    this.cService.serviceRequestCommon('get', this.postApiUrl + '/', postversiongroupid + '/liveVersion').subscribe((response: any) => {

      for (let i = 0; i < response.posts.length; i++) {

        if (response.posts[i].postVersionState === 'Live') {

          livePostDetailsTime = response.posts[i].updateDateTime;
          break;

        }

      }
      const livePostTime = moment.utc(livePostDetailsTime).local().format('YYYY-MM-DD HH:mm:ss');

      const draftPostTime = moment.utc(this.postDetails.createDateTime).local().format('YYYY-MM-DD HH:mm:ss');

      const livePostDate = new Date(livePostTime);

      const draftPostDate = new Date(draftPostTime);

      console.log(`Live Post updated Date Time is ${livePostDate}`);
      console.log(`Draft Post created Date Time is ${draftPostDate}`);
      if (livePostDate > draftPostDate) {

        this.showConfirmationDialog();

      } else {

        this.savePostDetails(false, false);

      }
      this.isLoaded = true;

    }, () => {

      this.toastService.createMessage('error', 'Error while fetching live post details');
      this.isLoaded = true;

    });

  }

  showConfirmationDialog() {

    this.modalService.confirm({
      nzTitle: 'A newer version of post is already available and this could override the content.<br>Do you want to proceed?',
      nzOkText: 'Ok',

      // nzOkType: 'danger',
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {

        this.savePostDetails(false, false);

      },
      nzOnCancel: () => {

        console.log('cancel clicked');

      },
    });

  }

  getAttachmentsData(event) {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    this.postDetails.postAttachments = event.attachments;
    this.postDetails.postType = this.postDetails.postAttachments.length > 0 ? 'Element' : 'Update';

  }

  parseAttachments(element): any {
    return {
      attachmentId: element.uid,
      attachmentName: element.name,
      size: element.size,
      status: element.status,
    };
  }

  getListLength() {
    return this.postDetails.postAttachments.length;
  }

  uploadReq = (event) => {
    console.log(event, 'event--->upload');
    const item = event.uploadItem;
    const basePath = `${this.cService.getAPIEndpoint()}/ncx-gateway`;//this.getBasePath();
    let queryStr;
    const messageId = this.commonFunctionsHelper.generateUUID();
    const userId = localStorage.getItem('userId');
    const ncxjwttoken = localStorage.getItem('ncxjwttoken');
    const headers = new HttpHeaders({
      messageId,
      userId,
      ncxjwttoken,
    });
    if (this.saveDiscussionId) {
      this.postApiUrl = environment.getDiscussionURL;
      queryStr = `${this.postApiUrl}/${this.saveDiscussionId}/attachment`;
    } else if (this.postId) {
      queryStr = `${this.postApiUrl}/${this.postId}/attachment`;
    }
    const formData = new FormData();
    formData.append('attachmentFile', item.file as any);
    // New Upload progres Bar Updated Code
    const req = new HttpRequest('POST', basePath + queryStr, formData, {
      headers,
      reportProgress: true,
    });
    let index = this.getListLength();
    this.postDetails.postAttachments[index] = this.parseAttachments(item.file!);
    setTimeout(() => {
      // Always returns a `Subscription` object. nz-upload would automatically unsubscribe it at correct time.
      return this.http.request(req).subscribe(
        // tslint:disable-next-line no-any
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            // tslint:disable-next-line:no-non-null-assertion
            if (event.total! > 0) {
              // tslint:disable-next-line:no-any
              // tslint:disable-next-line:no-non-null-assertion
              (event as any).percent = (event.loaded / event.total!) * 100;
            }
            // tslint:disable-next-line:no-non-null-assertion

            item.onProgress!(event, item.file!);
            this.attachmentChild.updateList(item.file, { status: 'uploading', percent: (event.loaded / event.total!) * 100 }, index);
          } else if (event instanceof HttpResponse) {
            // tslint:disable-next-line:no-non-null-assertion
            item.onSuccess!(event.body, item.file!, event);
            console.log('this.attachments : ', event);

            this.postDetails.postAttachments[index] = event.body;
            this.getAttachmentsData({ attachments: [...this.postDetails.postAttachments] });
          }
        },
        err => {
          // tslint:disable-next-line:no-non-null-assertion
          item.onError!(err, item.file!);
          this.postDetails.postAttachments[index].status = 'error';
          this.postDetails.postAttachments[index].isDeleted = true;
          this.getAttachmentsData({ attachments: [...this.postDetails.postAttachments] });
        },
      );
    }, 500);
  };

  getAttachmentsDetails(elementDetails) {

    console.log('modified');
    this.postDetails.attachmentDetails = elementDetails.attachmentDetails;
    this.isContentChanged = true;
    this.isDraftSaved = false;

  }

  updateTags(tags: string[]) {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    this.postDetails.postContentTags = tags;

  }

  getRTEData(content: any) {

    this.postDetails.postContent = content;
    this.googleDocs = '';
    this.embedlyLink = '';

  }

  rteEdited(content: any) {

    console.log('Edited');
    this.isContentChanged = true;
    this.isDraftSaved = false;

  }

  getRTEStandardGuidanceData(event: any) {

    this.postDetails.standardsGuidance.notes = event;

  }

  rteStandardGuidanceContentChnaged(ischanged) {

    console.log('rteStandardGuidanceContentChnaged: ' + this.isContentChanged);
    this.saveDraft();

  }

  getReportableData(reportableApprover: any) {

    this.isContentChanged = true;
    this.postDetails.reportableApprover = reportableApprover.value;

  }

  getLimitedLicenseData(limitedLicense: any) {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    this.postDetails.limitedLicenseApprover = limitedLicense;

  }

  toggleStoryPostAngle(type: any) {

    (type === 'Post') ? this.reRoute.navigate(['ncx/post']) : (type === 'Angle')
      ? this.reRoute.navigate(['ncx/angle'])
      : this.reRoute.navigate(['ncx/create-story']);

  }

  toggleGenerateFlag(isChecked: boolean) {

    this.postDetails.isGenerateTags = isChecked;
    this.isContentChanged = true;
    this.isDraftSaved = false;

  }

  checkFlag(event) {

    console.log('check Googgle Flag ', event);
    if (event === 'true') {

      this.isVisible = true;
      console.log('check Googgle Flag inside ', event, this.isVisible);
      document.getElementById('dummy-Google-Doc-Embedy-Link').click();
      this.googleDocs = '';

    } else {

      this.isVisible = false;
      this.googleDocs = '';

    }

  }

  dummyGoogleDocEmbedyLinkClick() {

  }

  checkEmbedlyFlag(event) {

    console.log('check embedly Flag ', event);
    if (event === 'true') {

      this.embedlyLink = '';
      this.isEmbedlyVisible = true;
      console.log('check embedly Flag inside ', event, this.isVisible, this.isEmbedlyVisible);
      document.getElementById('dummy-Google-Doc-Embedy-Link').click();

    } else {

      this.isEmbedlyVisible = false;
      this.embedlyLink = '';

    }

  }

  getDocLink(value) {

    if (value !== 'close') {

      this.googleDocs = value;
      this.updateRTELinkContent('googleDoc');

    }
    this.isVisible = false;

  }

  getEmbedlyData(value) {

    if (value !== 'close') {

      this.embedlyLink = value;
      this.updateRTELinkContent('embedlyLink');

    }
    this.isEmbedlyVisible = false;

  }

  updateRTELinkContent(type) {

    if (this.postDetails.postContent === '<p><br></p>') {

      this.postDetails.postContent = (type === 'googleDoc')
        ? `<span><a href='${this.googleDocs}'>${this.googleDocs}</a><br/><iframe id='googleDoc' height='600' width='800' frameborder='0'scrolling='no' marginheight='0' marginwidth = '0' src = '${this.googleDocs}'></iframe><div id='googleDocError'></div></span>`
        : `<p>${this.embedlyLink}<p>`;

    } else {

      this.postDetails.postContent += (type === 'googleDoc')
        ? `<span><a href='${this.googleDocs}'>${this.googleDocs}</a><br/><iframe id='googleDoc' height='600' width='800' frameborder='0'scrolling='no' marginheight='0' marginwidth = '0' src = '${this.googleDocs}'></iframe><div id='googleDocError'></div></span>`
        : `<p>${this.embedlyLink}<p>`;

    }

  }

  goBackToCreate() {

    if (this.viewPreview && this.postId) {

      this.viewPreview = false;
      this.showPreviewFlag = false;

    } else if (this.viewPreview) {

      this.viewPreview = false;
      this.showPreviewFlag = false;

    } else {

      if (this.isMobile) {

        if (this.pageFrom === 'Draft') {

          this.reRoute.navigate([`ncx/view-post/:${this.postDetails.postId}`]);

        } else {

          if (this.postDetails.livePostId) {

            this.reRoute.navigate([`ncx/view-post/:${this.postDetails.livePostId}`]);

          } else {

            this.location.back();

          }

        }

      } else {

        this.cancel(true);

      }

    }

  }

  cancel(navigate) {

    if (this.isContentChanged && this.paramId !== undefined && this.paramId !== '') {

      this.isModelOpened = true;

      const modelRef = this.modalService.create({
        nzTitle: 'Do you want to save your edits?',
        nzContent: 'Exiting this post without saving your changes will lock this post for you and other users',
        nzOkText: 'Save Draft',
        nzOkDanger: true,
        nzCancelText: 'Discard',
        nzWidth: 405,
        nzBodyStyle: {
          padding: '16px',
        },
        nzOnCancel: () => {

          this.isModelOpened = false;

        },
        nzFooter: [
          {
            label: 'Discard', type: 'default',
            onClick: () => {

              this.isModelOpened = false;
              this.modalService.closeAll();
              this.redirectTo();

            },
          },
          {
            label: 'Save Draft',
            danger: true,
            type: 'primary',
            onClick: () => {

              this.isModelOpened = false;
              this.saveAsDraft();
              this.isDraftSaved = true;
              this.modalService.closeAll();
              this.reRoute.navigate([`ncx/view-post/:${this.postDetails.postId}`]);

            },
          },
        ],
      });

    } else if (navigate) {

      if (this.paramId !== '') {

        this.isDraftSaved = true;

        if (this.postDetails.postType === 'Draft') {

          this.reRoute.navigate([`ncx/view-post/:${this.postDetails.postId}`]);

        } else {

          if (this.postDetails.livePostId) {

            this.reRoute.navigate([`ncx/view-post/:${this.postDetails.livePostId}`]);

          } else {

            this.location.back();

          }

        }

      } else {

        this.location.back();

      }

    } else {

      this.redirectTo();

    }

  }

  redirectTo() {

    this.isLoaded = false;
    this.isContentChanged = false;
    this.isDraftSaved = true;
    console.log('redirectTo : ', this.postDetails);
    if (this.postDetails.postVersionNumber === 0 && this.postDetails.postVersionState === 'Draft') {

      this.deletePost(this.postDetails.postVersionGroupId);

    } else if (this.postDetails.postId) {

      const queryStr = this.postDetails.postVersionGroupId;

      this.cService.serviceRequestCommon('post', this.postApiUrl, '/' + queryStr + '/lock/delete').subscribe((res: any) => {

        console.log('Post Response', res, this.postId);
        if (res === true && this.postDetails.isPostPublished) {
          this.reRoute.navigate(['ncx/stories-dashboard']);
        } else {
          if (this.postDetails.postType === 'Draft') {

            this.reRoute.navigate([`ncx/view-post/:${this.postDetails.postId}`]);

          } else {

            this.reRoute.navigate([`ncx/view-post/:${this.postDetails.livePostId}`]);

          }
        }
      });
    } else {

      this.location.back();

    }
    this.isLoaded = true;

  }

  redirectToEdit() {

    this.viewPreview = false;
    this.showPreviewFlag = false;
    window.scroll(0, 0);

  }

  /*API Calls for GET, POST and PUT Operations */
  getAvailableLabels() {

    this.cService.serviceRequestCommon('get', this.getAvailableLabelsAPI).subscribe((res: any) => {

      this.availableLabels = res;

      // this.setMarksAsTypeLabels();
      // this.setStatusTypeLabels();
      // this.setPostFilters.next(true);

    }, () => {

      console.log(`ERR: ${this.getAvailableLabelsAPI}`);

    });

  }

  toggleCheckbox(labels: string[], selectedValue: string, list: 'markAsType' | 'statusType') {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    if (list === 'markAsType') {

      //this.postDetails.postMarkedAs = labels;
      this.postDetails.editorialStatus = labels;

      if (selectedValue === this.reportableLabel) {

        this.postDetails.reportableApprover = this.defaultReportableContent;

      }

      if (selectedValue === this.standardsLabel) {

        this.postDetails.standardsGuidance = this.defaultStandardGuidanceContent;

      }

      this.setPublishedAiredSpecialCondition();

      // postMarkedAs - Was used before but not the values are updated in editorialStatus in backend. postMarkedAs should not be used.
      this.postDetails.postMarkedAs = [];

    }

    if (list === 'statusType') {

      this.postDetails.postLegal = labels;

      if (selectedValue === this.limitedLicenseLabel) {

        this.postDetails.limitedLicenseApprover = this.defaultLimitedLicenseContent;

      }

      this.setPublishedAiredSpecialCondition();

    }

  }

  showPreview() {

    try {

      this.isLoaded = false;
      this.publishAs('Draft', false);
      this.viewPreview = true;
      this.previewSiderIsCollapsed = false;
      this.previewDrawerIsCollapsed = true;
      this.postDetails.standardsGuidance.createUser = {
        firstName: this.userDetails.name.firstName,
        jobTitle: this.userDetails.jobTitle,
        lastName: this.userDetails.name.lastName,
        displayName: this.userDetails.name.displayName,
        profilePictureURL: '',
        role: this.userDetails.role,
        ssoId: this.userDetails.ssoId,
        userId: this.userDetails.userId,
        workingEmail: null,
      };
      if (this.postDetails.standardsGuidance.createDateTime === undefined || this.postDetails.standardsGuidance.createDateTime === null) {

        this.postDetails.standardsGuidance.createDateTime = new Date().getTime();

      }

      setTimeout(() => {

        if (this.postDetails.postContent) {

          this.postDetails.postContent = this.authService.addJWTTokenToLink(this.postDetails.postContent, 'img'); //JWT Token has to be added to the
          // img S3 link to download the file from S3

        }

      });

      this.enablePreviewPublish = this.isReportableApproverSectionValid && this.isStandardGuidanceSectionValid && this.isLimitedLicenseSectionValid &&
        this.isTitleValid && Common.isInputHasValidTextContent(this.postDetails.postContent) && this.isElementDetailsSectionValid &&
        this.isAngleStorySectionValid;

    } catch (ex) {

      console.log(ex);

    } finally {

      this.isLoaded = true;

    }

  }

  public utcToLocal(apiDate, type) {

    if (!apiDate) {

      return;

    }
    if (type === 'DATE') {

      return Time.convertingUtcToLocalDate(apiDate);

    } else {

      return Time.convertingUtcToLocalTime(apiDate);

    }

  }

  handleCancel() {

    this.isVisible = false;

  }

  setModifiedDetails(data) {

    this.postDetails.postVersionGroupId = data.postVersionGroupId;
    this.postDetails.postId = data.postId;
    this.postDetails.isPostPublished = data.isPostPublished;
    this.postDetails.postVersionNumber = data.postVersionNumber;
    this.postDetails.postVersionState = data.postVersionState;

    this.setCanSendEmailUpdateValue();

  }

  isPostofPrivateStory(postObj) {

    const primaryStory = postObj.relatedStories.filter((story) => story.isPrimary === true)[0];

    if ((primaryStory && primaryStory.storyAccess === 'private') && !(((primaryStory.createUserId === Number(localStorage.getItem('userId')))
      || (this.functionAbility.fa_access_private_story)))) {

      if (!this.privateStoryFlag) {

        this.privateStoryFlag = true;
        this.toastService.createMessage('error', 'You Do Not Have Access to the Post');
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);

      }
      return false;

    }

  }

  setPublishedAiredSpecialCondition() {

    if (this.postDetails.postLegal &&
      (this.postDetails.postLegal.includes('NEEDS LICENSING') || this.postDetails.postLegal.includes('COPYRIGHT RISK') ||
        this.postDetails.postLegal.includes('DO NOT USE'))) {

      if (this.postDetails.editorialStatus) {

        const index = this.postDetails.editorialStatus.indexOf('PUBLISHED/AIRED', 0);

        if (index > -1) {

          this.postDetails.editorialStatus.splice(index, 1);

        }

      }
      this.availableLabels?.markAsType?.forEach((element: any) => {

        if (element.value === 'PUBLISHED/AIRED') {

          element.checked = false;
          element.isDisabled = true;

        }

      });

    } else {

      this.availableLabels?.markAsType?.forEach((element: any) => {

        if (element.value === 'PUBLISHED/AIRED') {

          element.isDisabled = false;

        }

      });

    }

  }

  getPostDetails(postId) {

    if (postId === null || postId === undefined) {

      return;

    }
    this.isLoaded = false;
    const queryStr = `/${postId}`;

    this.cService.serviceRequestCommon('get', this.postApiUrl, queryStr).subscribe((res: any) => {

      console.log('Get Post Response', res, this.postId);
      console.log(`SUCCESS: ${this.postApiUrl}`);
      this.postDetails = res;
      if (this.postDetails.postVersionState === 'Archived' && this.isArchieved) {

        this.isArchieved = false;
        this.toastService.createMessage('warning', 'You cannot edit Archived Post. Redirecting to Live Post Version');
        this.reRoute.navigate(['ncx/view-post/:' + this.postDetails.livePostId]);

      }

      this.postDetails.postContent = this.authService.addJWTTokenToLink(this.postDetails.postContent, 'img'); //JWT Token has to be added to the img
      // S3 link to
      // download the file from S3

      this.isPostofPrivateStory(res);

      this.postAccessMode = this.postDetails.postAccessMode;

      this.setPublishedAiredSpecialCondition();

      this.setCanSendEmailUpdateValue();

      this.isLoaded = true;
      this.isAttachmentDisabled = false;
      this.isSavedAsDraft = true;

    }, err => {

      console.log('API err edit : ', err);
      if (err === 'POST-003' && !this.privateStoryFlag) {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Post');
        this.privateStoryFlag = true;
        setTimeout(() => {

          this.reRoute.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else if (err !== 'POST-003') {

        this.toastService.createMessage('error', err);

      }
      this.isLoaded = true;

    });

  }

  savePostDetails(isNewPost: boolean, navigateDraft: boolean) {

    let queryStr = '';

    let type = 'post';

    let dataToSave;

    if (!isNewPost) {

      queryStr = `/${this.postDetails.postId}`;
      type = 'put';
      dataToSave = this.postDetails;
      dataToSave.isPostPublished = true;
      dataToSave.postVersionState = 'Live';

    } else {

      type = (!this.isSavedAsDraft || this.postDetails.postVersionState === 'Live') ? 'post' : 'put';
      if (type === 'post') {

        dataToSave = this.postDetails;
        dataToSave.postId = null;
        dataToSave.isPostPublished = false;
        dataToSave.postVersionState = 'Draft';
        dataToSave.postType = this.postDetails.postType;

      } else {

        queryStr = `/${this.postDetails.postId}`;
        dataToSave = this.postDetails;
        dataToSave.postVersionState = 'Draft';
        dataToSave.postType = this.postDetails.postType;

      }

    }

    console.log('data to save', dataToSave, queryStr);
    this.isPublishDisable = true;
    this.cService.serviceRequestCommon(type, this.postApiUrl, queryStr, dataToSave).subscribe((data: any) => {

      /**
       * if the post was already deleted then show the msg and redirect to stories dashboard
       */
      if (data.isDeleted === true) {

        this.toastService.createMessage('error', 'Requested post does not exist. Redirecting to stories dashboard');
        this.redirectToStoriesDashboard();

      } else if (data.isLinkedAngleDeleted && data.isLinkedAngleDeleted === true) {

        /**
         *
         * NRT-12759
         * NCX Edit Post Link Angle - user able to link the deleted angle to a post
         * Step 1 : Create an Angle (Angle A)
         * Step 2 : Create a Post (Post A) and link the angle created in Step 1
         * Step 3 : Don't Publish it
         * Step 4 : Delete the Angle
         * Step 5 : Publish the Post
         * Post is holding the angle which was deleted in Step 4.Record won't be saved in DB but we are not failing the API call as a temporary fix
         * for now. Response from API will have the value as true in 'isLinkedAngleDeleted' field. if the value is true then it means one of the
         * linked angle is invalid. Invalid angle will be removed in the response back from API. that should be updated in UI and the error msg in
         * the API response field 'linkedAngleDeletedErrorMsg' should be displayed to user.
         *
         */
        this.toastService.createMessage('warning', data.linkedAngleDeletedErrorMsg);
        this.postDetails.relatedAngles = data.relatedAngles;
        this.isLoaded = true;

      } else if (data.isLinkedStoryDeleted && data.isLinkedStoryDeleted === true) {

        /**
         *
         * NRT-13870
         * NCX Edit Post Link story - user able to link the deleted story to a post
         * Step 1 : Create an story (story A)
         * Step 2 : Create a Post (Post A) and link the story created in Step 1
         * Step 3 : Don't Publish it
         * Step 4 : Delete the story
         * Step 5 : Publish the Post
         * Post is holding the story which was deleted in Step 4.Record won't be saved in DB but we are not failing the API call as a temporary fix
         * for now. Response from API will have the value as true in 'isLinkedstoryDeleted' field. if the value is true then it means one of the
         * linked story is invalid. Invalid story will be removed in the response back from API. that should be updated in UI and the error msg in
         * the API response field 'linkedstoryDeletedErrorMsg' should be displayed to user.
         *
         */
        this.toastService.createMessage('warning', data.linkedStoryDeletedErrorMsg);
        this.postDetails.relatedStories = data.relatedStories;
        this.isLoaded = true;

      } else {

        this.isDraftSaved = true;
        this.isContentChanged = false;
        this.isLoaded = true;
        let successMsg = (type === 'post') ? 'Post successfully created' : 'Post successfully updated';

        this.isPublishDisable = false;
        successMsg = (data.isPostPublished === false) ? 'Post successfully saved as draft' : 'Post successfully created';
        if (!this.showPreviewFlag && !this.viewPreview) {

          this.toastService.createMessage('success', successMsg);

        } else {

          console.log('view preview else response ', type, data, dataToSave);
          this.viewPreview = true;

        }
        console.log('Post response ', type, data, dataToSave);
        this.isLoaded = true;
        if (data && data.isPostPublished === true) {

          this.isContentChanged = false;
          this.isDraftSaved = true;
          this.reRoute.navigate(['ncx/view-post/:' + data.postId]);

        } else {

          this.postId = data.postId;
          this.setModifiedDetails(data);
          this.isSavedAsDraft = true;
          this.isDraftSaved = true;
          this.isContentChanged = false;
          setTimeout(() => {

            this.isAttachmentDisabled = false;
            if (navigateDraft) {

              this.reRoute.navigate([`ncx/view-post/:${this.postDetails.postId}`]);

            }

          }, 100);

        }

      }

    },
      (error) => {

        console.log('Error', error, dataToSave);
        this.isLoaded = true;
        this.isPublishDisable = false;
        this.toastService.createMessage('error', error);

      });

  }

  /**
   * Redirect to Stories Dashboard
   */
  redirectToStoriesDashboard() {

    setTimeout(() => {

      this.reRoute.navigate(['ncx/stories-dashboard']);

    }, 500);

  }

  deletePost(postVersionGroupId: any) {

    this.isLoaded = false;
    const queryStr = `/${postVersionGroupId}/version`;

    this.cService.serviceRequestCommon('delete', this.postApiUrl, queryStr).subscribe(() => {

      this.toastService.createMessage('success', 'Post successfully deleted');
      this.isContentChanged = false;
      this.reRoute.navigate(['ncx/stories-dashboard']);
      this.isLoaded = true;

    }, () => {

      this.toastService.createMessage('error', 'Error while deleting please try again');
      this.isLoaded = true;

    });

  }

  initAdditionalStoryObj(story) {

    const obj = {
      storyId: story.storyId,
      storyTitle: story.autoGeneratedStoryId ? `${story.autoGeneratedStoryId} - ${story.storyTitle}` : story.storyTitle,
      isPrimary: true,
    };

    return obj;

  }

  saveLimitedLicense(limitedLicenseEvent) {

    this.postDetails.limitedLicenseApprover = limitedLicenseEvent;

  }

  getUserRole() {
    this.userrole = this.tI.userRole?.toUpperCase();
    if (this.userrole === this.userRoles.Admin || this.userrole === this.userRoles.Standard) {
      this.isStandards = false;

    } else {

      this.isStandards = true;

    }

  }

  selectedTypeChange(index: number) {

    setTimeout(() => {

      (index === 1) ? this.reRoute.navigate(['ncx/create-story']) : (index === 3) ? this.reRoute.navigate(['ncx/angle']) : '';

    }, 500);

  }

  getMaterialsDetails(elementDetails) {

    this.isContentChanged = true;
    this.isDraftSaved = false;
    console.log('getMaterialsDetails: ' + this.isContentChanged);

    this.postDetails.attachmentDetails = elementDetails.attachmentDetails;

  }

  public windowResizeEvent() {

    // #region For development mode
    this.windowWidth = window.innerWidth;
    if (window.innerWidth > 1600) {

      this.screenSize = 'XXL';
      this.desktopMode = true;

    } else if (window.innerWidth <= 1600 && window.innerWidth > 1200) {

      this.screenSize = 'XL';
      this.desktopMode = true;

    } else if (window.innerWidth <= 1200 && window.innerWidth > 992) {

      this.screenSize = 'LG';
      this.desktopMode = true;

    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {

      this.screenSize = 'MD';
      this.desktopMode = false;

    } else if (window.innerWidth <= 768 && window.innerWidth > 576) {

      this.screenSize = 'SM';
      this.desktopMode = false;

    } else if (window.innerWidth <= 576) {

      this.screenSize = 'XS';
      this.desktopMode = false;

    }

    // #endregion

    const metadataTabBody = (this.metadataTabBodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    const postDetailsTabBody = (this.postDetailsTabBodyElement?.nativeElement as HTMLElement)?.getBoundingClientRect();

    this.footerHeight = (this.footerElement?.nativeElement as HTMLElement)?.clientHeight + 1;
    this.postDetailsTabBodyHeight = window.innerHeight - postDetailsTabBody?.top - this.footerHeight;
    this.metadataTabBodyHeight = window.innerHeight - metadataTabBody?.top - this.footerHeight;
    document.documentElement.style.setProperty('--bodyTabBodyHeightInPx', this.postDetailsTabBodyHeight + 'px');
    document.documentElement.style.setProperty('--rightSectionTabBodyHeightInPx', this.metadataTabBodyHeight + 'px');

  }

}

