import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Option } from '@models/ncx/filters';
import { ISearch, StoryContentSearchResult } from '@models/ncx/global-search';
import { BreakpointService } from '@services/breakpoint.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

type PostParams = {
  contentType: 'Post',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string,
  startDate?: string,
  endDate?: string,
  authorSSO?: number
};

type PostPayload = {
  post: {
    filter: {
      date?: {
        fieldName: string,
        startTime: string,
        endTime: string,
        startDate: string,
        endDate: string,
      },
      includeElements: boolean,
      includeDiscussions: boolean,
      includePublishedAired: boolean,
      markAs: string[],
      contentType: string[]
      topics: any[],
      tags: any[],
      tagsIncludeExclude: string
    }
  }
};

@Component({
  selector: 'app-element-results',
  templateUrl: './element-results.component.html',
  styleUrls: ['../search-results-common.scss']
})
export class ElementResultsComponent implements OnChanges, OnDestroy {

  @Input() isVisible: boolean = false;

  @Input() allResultsPage: boolean = false;

  // Search Results from API
  searchResults: StoryContentSearchResult[] = [];

  // Current page of search results (used to calculate start/end index)
  page: number = 1;

  // Number of results per page (API does not allow us to make it more than 10)
  limit: number = 10;

  // Total number of results
  total: number = 0;

  // Loader
  isLoaded: boolean = true;

  // Share Post information
  showShareModal: boolean = false;

  shareMessage: string = '';

  sharePostId: number = 0;

  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();

  private subs = new SubSink();

  tabOrder = ['ALL', 'STORIES', 'POSTS', 'ELEMENTS', 'ANGLES', 'GROUPS', 'PEOPLE'];

  postSortOptions = [
    { key: 'Relevance', value: 'relevancescore' },
    { key: 'Newest', value: 'newestFirst' },
    { key: 'Oldest', value: 'oldestFirst' },
  ];

  isMobile: boolean = false;

  isCollapsed: boolean = true;

  constructor(
    public searchService: GlobalSearchService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService,
    private breakpointService: BreakpointService
  ) {

    // When search term changes, load results if component is visible
    this.subs.sink = this.searchService.searchTerm.subscribe(() => {


      // When search term changes, reset results regardless of whether the component is visible or not
      // When the user returns to this tab, a new list of search results will load
      this.resetResults();
      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.loadSearchResults();

      }

    });

    // When filters change, load results if component is visible
    this.subs.sink = this.searchService.filterChanged.subscribe(() => {

      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.resetResults();
        this.loadSearchResults();

      }

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    // When visibility changes, load search results if visible and if the results weren't already loaded
    if ('isVisible' in changes) {

      if (changes.isVisible.currentValue && !this.searchResults.length) {

        this.loadSearchResults();

      }
      if (!changes.isVisible.currentValue) {

        this.cancelRequest.next(true);

      }

    }

  }

  ngOnInit() {
    this.breakpointService.isMobile$.subscribe(res => {

      this.isMobile = res;

    });
  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Load search results
   *
   */
  loadSearchResults() {

    this.isLoaded = false;

    const params = this.buildParams();

    const payload = this.buildPayload();

    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&');

    this.searchService.loadSearchResults(queryString, payload).pipe(takeUntil(this.cancelRequest.pipe(filter(value => value === true)))).subscribe({
      next: (res: any) => {

        const results: ISearch = res as ISearch;

        const searchResults = results.contentSearchResult || [];

        this.searchResults = this.page === 1 ? searchResults : [...this.searchResults, ...searchResults];

        this.total = results.contentCount;

        console.log('loadSearchResults', { searchResults: this.searchResults, total: this.total });

        this.searchService.setSuggestedSearch(results.contentSuggestedPhrase);

        this.sendGATracking(params, payload);

      },
      error: (error: any) => {

        this.toastService.createMessage('error', 'Unable to load search results');

        console.error('loadSearchResults', error);

      }
    }).add(() => {

      this.isLoaded = true;

    });

  }

  /**
   * Build the query string portion of the API call
   *
   */
  buildParams(): PostParams {

    // API Params
    const params: PostParams = {
      contentType: 'Post',
      startIndex: 0,
      endIndex: this.limit,
      sortField: '',
      sortOrder: ''
    };

    try {

      // Page
      const { start: startIndex, end: endIndex } = this.startEndIndex;

      params.startIndex = startIndex;
      params.endIndex = endIndex;

      // Sort
      let sortOrder: string = 'descending';

      let sortField: string = 'modificationdate';

      const { sort, modificationDate, authorSSO } = this.searchService.filters.POSTS;

      // Sort information
      switch (sort) {

        case 'relevancescore':
          sortOrder = '';
          sortField = 'relevancescore';
          break;

        case 'newestFirst':
        case 'oldestFirst':
          sortField = 'modificationdate';
          sortOrder = sort === 'newestFirst' ? 'descending' : 'ascending';
          break;

      }

      params.sortField = sortField;
      params.sortOrder = sortOrder;

      // Dates
      if (Array.isArray(modificationDate) && modificationDate.length) {

        const [start, end] = modificationDate;

        const startDateTime = Time.convertLocalToLocal(start);

        const endDateTime = Time.convertLocalToLocal(end);

        const startDate = Time.convertUtcToUtcDate(startDateTime) + ' 00:00:00';

        const endDate = Time.convertUtcToUtcDate(endDateTime) + ' 23:59:59';

        params.startDate = Time.convertUTCToTimeStamp(startDate);

        params.endDate = Time.convertUTCToTimeStamp(endDate);

      }

      // Author
      if (authorSSO) {

        params.authorSSO = authorSSO;

      }

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as PostParams;

    }

  }

  /**
   * Build the Payload/Body portion of the API call
   *
   */
  buildPayload(): PostPayload {

    // API Payload
    const payload: PostPayload = {
      post: {
        filter: {
          date: {
            fieldName: '',
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
          },
          includeElements: false,
          includeDiscussions: false,
          includePublishedAired: false,
          markAs: [],
          contentType: [],
          topics: [],
          tags: [],
          tagsIncludeExclude: ''
        }
      }
    };

    try {

      const { modificationDate, createdOrModified, includeElements, media, includeDiscussions, includePublishedAired, editorial, legal, isAllMediaChecked, topics, tags, tagsIncludeExclude } = this.searchService.filters.POSTS;

      payload.post.filter.date.fieldName = createdOrModified;

      payload.post.filter.includeElements = includeElements;

      payload.post.filter.includeDiscussions = includeDiscussions;

      payload.post.filter.includePublishedAired = includePublishedAired;

      payload.post.filter.topics = topics;

      payload.post.filter.tags = tags;

      payload.post.filter.tagsIncludeExclude = tagsIncludeExclude;

      // Editorial and Legal labels
      const markAs = [...legal, ...editorial].filter((opt: Option) => opt.checked).map((opt: Option) => String(opt.value));

      const contentType = isAllMediaChecked ? ['All'] : media.filter((opt: Option) => opt.checked).map((opt: Option) => String(opt.value));

      payload.post.filter.markAs = markAs;

      payload.post.filter.contentType = contentType;

      // Date
      if (Array.isArray(modificationDate) && modificationDate.length) {

        const [start, end] = modificationDate;

        const startDateTime = Time.convertLocalToLocal(start);

        const endDateTime = Time.convertLocalToLocal(end);

        const startDate = Time.convertUtcToUtcDate(startDateTime) + ' 00:00:00';

        const endDate = Time.convertUtcToUtcDate(endDateTime) + ' 23:59:59';

        payload.post.filter.date.startTime = Time.convertUTCToTimeStamp(startDate);

        payload.post.filter.date.endTime = Time.convertUTCToTimeStamp(endDate);

        payload.post.filter.date.startDate = start;

        payload.post.filter.date.endDate = end;

      } else {

        // If there are no dates, the date filter needs to be removed from the payload
        delete payload.post.filter.date;

      }

      console.log('buildPayload', payload);

      return payload;

    } catch (error) {

      console.error('buildPayload', error);

      return {} as PostPayload;

    }

  }

  /**
   * Share Post
   *
   */
  sharePost(post: StoryContentSearchResult) {

    if (post && post.id && post.title) {

      this.shareMessage = post.title;
      this.sharePostId = post.id;

    } else {

      this.shareMessage = '';
      this.sharePostId = null;

    }

    this.showShareModal = true;

  }

  /**
   * Close Share modal
   *
   */
  closeShareModal() {

    this.showShareModal = false;

  }

  /**
   * Send Google Analytics tracking data
   *
   */
  sendGATracking(params: PostParams, payload: PostPayload) {

    const searchTerm = this.searchService.searchTerm.value;

    console.log('Google Analytics Tracking (Posts)', { searchTerm, params, payload });

    // Result count
    this.gaService.trackContentResultsCount(searchTerm, this.total, 'Post Results Count', 'Post Content');

    // Relevancy
    this.gaService.trackRecencyRelevancySearchAction(searchTerm, params.sortField === 'relevancescore' ? 'relevancy' : 'recency', this.total);

    // Sort
    if (params.sortOrder && params.sortField) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Sort - ${params.sortField} - ${params.sortOrder}`, this.total,
        'Post Content');

    }

    // Date Start/End
    if (params.startDate && params.endDate) {

      this.gaService.trackSelectedContentFilter(searchTerm, 'DateFilter - modificationdate', this.total, 'Post Content');

    }

    // Post Author
    if (params.authorSSO) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Author Filter - ${params.authorSSO}`, this.total, 'Post Content');

    }

    // Labels and Elements
    if (payload.post.filter.markAs.length || payload.post.filter.contentType.length) {

      const labels = payload.post.filter.markAs.length ? `Label - ${payload.post.filter.markAs.join(',')}` : '';

      const elements = payload.post.filter.contentType.length ? `Element - ${payload.post.filter.contentType.join(',')}` : '';

      const message = [labels, elements].filter((str: string) => !!str).join(' || ');

      this.gaService.trackSelectedContentFilter(searchTerm, message, this.total, 'Post Content');

    }

  }

  /**
   * Record View Post event
   *
   */
  viewPost(post: StoryContentSearchResult, index: number) {

    const searchText = this.searchService.searchTerm.value;

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, searchText, 'Selected Post Index', 'Post Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(this.page, searchText, 'Selected Post Page Number', 'Post Content');

  }

  /**
   * Record View main Story of Post event
   *
   */
  viewStory(post: StoryContentSearchResult, index: number) {

    const searchText = this.searchService.searchTerm.value;

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, searchText, 'Selected Story Index', 'Story Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(this.page, searchText, 'Selected Story Page Number', 'Story Content');

  }

  /**
   * On page scroll, load next page
   *
   */
  onScrollDown() {

    console.log('onScrollDown (Posts)');

    if (this.page * this.limit >= this.total) {

      console.log('No More Results');
      return;

    }

    this.page += 1;

    this.cancelRequest.next(false);

    this.loadSearchResults();

  }

  /**
   * Reset all results
   *
   */
  resetResults() {

    this.total = 0;
    this.page = 1;
    this.searchResults = [];

  }

  get resultCount(): string {

    return `${Common.formatNumber(this.total)} Result${this.total !== 1 ? 's' : ''}`;

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }

  onSortOptionChange(sort: string) {

    this.searchService.filters.POSTS.sort = sort;

    this.searchService.filterChanged.next();
  }

  toggleFilters(): void {

    this.isCollapsed = !this.isCollapsed;

  }

}
