<!-- RESULT COUNT -->

<div *ngIf="allResultsPage">
   <ng-container *ngTemplateOutlet="resultBodyHeaderTemplate"></ng-container>

   <ng-container *ngTemplateOutlet="resultBodyTemplate"></ng-container>
</div>

<div *ngIf="!allResultsPage">
   <ng-container *ngTemplateOutlet="resultBodyHeaderTemplate"></ng-container>

   <app-scroll-container>
      <ng-template bodyTemplate>
         <ng-container *ngTemplateOutlet="resultBodyTemplate"></ng-container>
      </ng-template>
   </app-scroll-container>
</div>

<ng-template #resultBodyHeaderTemplate>
   <div [ngClass]="{ 'result-body-header': true, 'people-header-margin': !allResultsPage }">
      <div *ngIf="allResultsPage">
         <span
            class="mr-8"
            nz-icon
            nzType="user"
            nzTheme="outline"></span>
         People
      </div>
      <div>
         <!-- {{ searchService.filters.PEOPLE.sort }} -->

         <nz-select
            *ngIf="!allResultsPage"
            class="sort-options"
            nzMode="default"
            [(ngModel)]="searchService.filters.PEOPLE.sort"
            (ngModelChange)="onSortOptionChange($event)"
            [nzAllowClear]="true"
            [nzShowArrow]="true">
            <nz-option
               *ngFor="let option of peopleSortOptions"
               [nzLabel]="option.key"
               [nzValue]="option.value"></nz-option>
         </nz-select>
      </div>
      <div
         *ngIf="isLoaded || (total > 0 && !isLoaded)"
         class="result-count">
         {{ resultCount }}
         <button
            *ngIf="searchService.suggestedSearch.show"
            (click)="searchService.performSuggestedSearch()"
            class="suggested-search">
            Did you mean {{ searchService.suggestedSearch.keyword }}?
         </button>
      </div>
   </div>
</ng-template>

<ng-template #resultBodyTemplate>
   <div
      [ngClass]="{ 'search-result-container': true, 'no-results': !searchResults.length }"
      infiniteScroll
      [infiniteScrollDistance]="2"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
      data-scrollable-container
      [attr.data-component]="'ncx/search/page/results/people'">
      <div [ngClass]="{ 'search-result-body': !allResultsPage }">
         <!-- LOADER -->
         <app-loader-component
            loaderType="SECTION_LOADER"
            [isLoaded]="isLoaded"></app-loader-component>

         <!-- CARD TO DISPLAY SEARCH RESULTS -->
         <div class="grid-container-margin">
            <div
               [ngClass]="{
                  'profile-column-grid-container': !allResultsPage,
                  'grid-container-full-frame': allResultsPage,
                  'ml-24': !allResultsPage,
               }">
               <ncx-profile-search-new
                  class="grid-item-profile"
                  *ngFor="let profile of searchResults; let index = index"
                  (viewProfile)="viewProfile(profile, index)"
                  [profile]="profile"></ncx-profile-search-new>
            </div>
         </div>

         <div *ngIf="!searchResults.length && isLoaded">
            <nz-empty [nzNotFoundContent]="contentTpl">
               <ng-template #contentTpl>
                  <span>No Profiles Found</span>
               </ng-template>
            </nz-empty>
         </div>
      </div>
   </div>
</ng-template>
