import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityPage } from '@ncx/activity/activity-page';
import { AlertsPage } from '@ncx/alerts/alerts-page';
import { CreateAngleComponent } from '@ncx/angles/create/create-angle.component';
import { AngleLandingComponent } from '@ncx/angles/landing/angle-landing.component';
import { ViewAngleComponent } from '@ncx/angles/view/view-angle.component';
import { DraftsComponent } from '@ncx/drafts/drafts.component';
import { FeedsComponent } from '@ncx/feeds/feeds.component';
import { ConnectionsComponent } from '@ncx/following/connections/connections.component';
import { FollowingComponent } from '@ncx/following/following.component';
import { FollowingStoriesComponent } from '@ncx/following/stories/following-stories.component';
import { TopicsComponent } from '@ncx/following/topics/topics.component';
import { CreateDiscussionComponent } from '@ncx/group/create-discussion/create-discussion.component';
import { CreateGroupComponent } from '@ncx/group/create-group/create-group.component';
import { DiscussionDetailsComponent } from '@ncx/group/discussion-details/discussion-details.component';
import { GroupDetailsComponent } from '@ncx/group/group-details/group-details.component';
import { GroupFollowersComponent } from '@ncx/group/group-followers/group-followers.component';
import { GroupLandingComponent } from '@ncx/group/group-landing/group-landing.component';
import { GroupVersionsComponent } from '@ncx/group/group-versions/group-versions.component';
import { ManageMembersComponent } from '@ncx/group/manage-members/manage-members.component';
import { OtherToolsComponent } from '@ncx/other-tools/other-tools.component';
import { CreatePostComponent } from '@ncx/posts/create/create-post.component';
import { PostVersionsComponent } from '@ncx/posts/versions/post-versions.component';
import { ViewPostsComponent } from '@ncx/posts/view/view-posts.component';
import { HistoryComponent } from '@ncx/profile/history/history.component';
import { ProfileComponent } from '@ncx/profile/profile/profile.component';
import { SearchResultsNewComponent } from '@ncx/search/page-new/search-results.component';
import { SearchResultsComponent } from '@ncx/search/page/search-results.component';
import { SettingsComponent } from '@ncx/settings/settings.component';
import { CreateStoryComponent } from '@ncx/stories/create/create-story.component';
import { StoriesDashboardComponent } from '@ncx/stories/dashboard/stories-dashboard.component';
import { NewStoriesLandingComponent } from '@ncx/stories/landing/story-landing.component';
import { ViewStoryComponent } from '@ncx/stories/view/view-story.component';
import { SupportPageComponent } from '@ncx/support/support-page.component';
import { ViewVideoThumbnailsComponent } from '@ncx/view-video-thumbnails/view-video-thumbnails.component';
import { AuthManager } from '@services/auth/app.auth.manager';
import { DeactivateGuardService } from '@services/auth/app.can-deactivate.guard';

const routes: Routes = [
  { path: '', component: StoriesDashboardComponent, data: { title: 'Stories' }, resolve: { AuthManager } },

  // STORIES
  { path: 'stories-dashboard', component: StoriesDashboardComponent, data: { title: 'Stories' }, resolve: { AuthManager } },
  { path: 'landing-story/:storyId', component: NewStoriesLandingComponent, data: { title: 'Story' }, resolve: { AuthManager } },
  { path: 'landing-story/:storyId/:standards', component: NewStoriesLandingComponent, data: { title: 'Story' }, resolve: { AuthManager } },
  { path: 'create-story', component: CreateStoryComponent, data: { title: 'Story' }, resolve: { AuthManager } },
  { path: 'create-story/:storyId', component: CreateStoryComponent, data: { title: 'Story' }, resolve: { AuthManager } },
  { path: 'view-story/:storyId', component: ViewStoryComponent, data: { title: 'Story' }, resolve: { AuthManager } },

  // POSTS
  { path: 'post', component: CreatePostComponent, data: { title: 'Post' }, resolve: { AuthManager } },
  { path: 'view-post/:postId', component: ViewPostsComponent, data: { title: 'Post' }, resolve: { AuthManager } },
  {
    path: 'post/:postId',
    component: CreatePostComponent,
    data: { title: 'Post' },
    resolve: { AuthManager },
    canDeactivate: [DeactivateGuardService],
  },
  { path: 'post-versions', component: PostVersionsComponent, data: { title: 'Post Versions' }, resolve: { AuthManager } },
  { path: 'post-versions/:postVersionGroupId', component: PostVersionsComponent, data: { title: 'Manage Post Version' }, resolve: { AuthManager } },

  // ANGLES
  { path: 'angle', component: CreateAngleComponent, data: { title: 'Angle' }, resolve: { AuthManager } },
  { path: 'angle/:angleId', component: CreateAngleComponent, data: { title: 'Angle' }, resolve: { AuthManager } },
  { path: 'view-angle/:angleId', component: ViewAngleComponent, data: { title: 'Angle' }, resolve: { AuthManager } },
  { path: 'landing-angle/:angleId', component: AngleLandingComponent, data: { title: 'Angle' }, resolve: { AuthManager } },
  { path: 'landing-angle/:angleId/:tab', component: AngleLandingComponent, data: { title: 'Angle' }, resolve: { AuthManager } },

  // POST DRAFTS
  { path: 'drafts', component: DraftsComponent, data: { title: 'Drafts' }, resolve: { AuthManager } },

  // GROUPS
  { path: 'group-landing', component: GroupLandingComponent, data: { title: 'Groups' }, resolve: { AuthManager } },
  { path: 'create-group', component: CreateGroupComponent, data: { title: 'Create Group' }, resolve: { AuthManager } },
  { path: 'create-group/:groupId', component: CreateGroupComponent, data: { title: 'Edit Group' }, resolve: { AuthManager } },
  { path: 'group-details/:groupId', component: GroupDetailsComponent, data: { title: 'Group Details' }, resolve: { AuthManager } },
  { path: 'group-followers/:groupId', component: GroupFollowersComponent, data: { title: 'Group Followers' }, resolve: { AuthManager } },
  {
    path: 'group-versions/:discussionVersionGroupId',
    component: GroupVersionsComponent,
    data: { title: 'Group Versions' },
    resolve: { AuthManager },
  },
  { path: 'manage-members/:groupId', component: ManageMembersComponent, data: { title: 'Manage Members' }, resolve: { AuthManager } },

  // SEARCH RESULTS
  { path: 'search-results', component: SearchResultsComponent, data: { title: 'Search Results' }, resolve: { AuthManager } },
  { path: 'search-results-new', component: SearchResultsNewComponent, data: { title: 'Search Results' }, resolve: { AuthManager } },

  // ACTIVITY
  { path: 'activity/:activityType', component: ActivityPage, data: { title: 'Activity' }, resolve: { AuthManager } },

  // DISCUSSIONS
  { path: 'create-discussion', component: CreateDiscussionComponent, data: { title: 'Create Discussion' }, resolve: { AuthManager } },
  { path: 'discussion-details', component: DiscussionDetailsComponent, data: { title: 'Discussion Details' }, resolve: { AuthManager } },

  // FOLLOWING
  { path: 'followed-stories', component: FollowingStoriesComponent, data: { title: 'Followed Stories' }, resolve: { AuthManager } },
  { path: 'topics', component: TopicsComponent, data: { title: 'Topics' }, resolve: { AuthManager } },
  { path: 'connections', component: ConnectionsComponent, data: { title: 'Connections' }, resolve: { AuthManager } },
  { path: 'following/:storyId', component: FollowingComponent, data: { title: 'Following' }, resolve: { AuthManager } },

  // PROFILE
  { path: 'profile/:userId', component: ProfileComponent, data: { title: 'View Profile' }, resolve: { AuthManager } },
  { path: 'profile', component: ProfileComponent, data: { title: 'Profile' }, resolve: { AuthManager } },
  { path: 'history', component: HistoryComponent, data: { title: 'Profile History' }, resolve: { AuthManager } },
  { path: 'history/:userId/:ssoId', component: HistoryComponent, data: { title: 'Profile History' }, resolve: { AuthManager } },

  // SETTINGS
  { path: 'settings', component: SettingsComponent, data: { title: 'Settings' }, resolve: { AuthManager } },

  // SETTINGS
  { path: 'support', component: SupportPageComponent, data: { title: 'Support' }, resolve: { AuthManager } },
  { path: 'support/:tab', component: SupportPageComponent, data: { title: 'Support' }, resolve: { AuthManager } },

  // OTHER
  { path: 'other-tools', component: OtherToolsComponent, data: { title: 'Other Tools' }, resolve: { AuthManager } },

  // ALERTS
  { path: 'alerts', component: AlertsPage, data: { title: 'Alerts' }, resolve: { AuthManager } },

  // NOT SURE ABOUT THESE PAGES
  { path: 'view-video/:storyId', component: ViewVideoThumbnailsComponent, data: { title: 'Stories' }, resolve: { AuthManager } },
  { path: 'feeds', component: FeedsComponent, data: { title: 'Feed' }, resolve: { AuthManager } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

// tslint:disable-next-line: class-name
export class ncxRoutingModule { }





