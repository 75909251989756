<div
   class="ncx-post-card"
   data-component="shared/cards/post/search">
   <div [ngClass]="{ 'grid-container': true, 'grid-full-frame': lightBoxAttachments.length == 0 }">
      <div
         class="image-container"
         *ngIf="lightBoxAttachments.length > 0">
         <div class="image-element">
            <!-- <app-view-attachments
               [postAttachments]="attachments"
               [showAllAttachments]="true"></app-view-attachments> -->
            <ng-container *ngIf="lightBoxAttachments.length > 0">
               <ng-container
                  *ngTemplateOutlet="imageTemplate; context: { $implicit: lightBoxAttachment }"></ng-container>
            </ng-container>
         </div>
         <div
            class="overlay-element"
            *ngIf="remainingAttachments > 0">
            <div>
               <i
                  class="icon"
                  nz-icon
                  nzType="file-image"
                  nzTheme="outline"></i>
            </div>
            <div>{{ remainingAttachments }}</div>
         </div>
      </div>
      <div class="details-container">
         <div class="days-title-legal">
            <div class="days">{{ calculateDays(post.createDateTime) }} days</div>
            <div
               *ngIf="!isSmall"
               class="story-title"
               [href]="post.storyURL"
               (click)="onViewStory()"
               nz-tooltip
               nzTooltipPlacement="bottomLeft"
               [nzTooltipMouseEnterDelay]="0.5"
               [nzTooltipTitle]="post?.storyTitle"
               [innerHTML]="(post?.storyTitle || '').trim() | safeHtml"></div>

            <div class="legal">
               <app-post-labels
                  [editorial]="editorials"
                  [legal]="legals"></app-post-labels>

               <div
                  *ngIf="additionalEditorialsOrLegals"
                  class="additionalEditorialOrLegals">
                  {{ additionalEditorialsOrLegals }}
               </div>
            </div>
         </div>

         <div
            *ngIf="isSmall"
            class="story-title"
            [href]="post.storyURL"
            (click)="onViewStory()"
            nz-tooltip
            nzTooltipPlacement="bottomLeft"
            [nzTooltipMouseEnterDelay]="0.5"
            [nzTooltipTitle]="post?.storyTitle"
            [innerHTML]="(post?.storyTitle || '').trim() | safeHtml"></div>

         <div class="title-container">
            <div
               class="title"
               [href]="post?.targetURL"
               (click)="onViewPost()"
               nz-tooltip
               nzTooltipPlacement="bottomLeft"
               [nzTooltipMouseEnterDelay]="0.5"
               [nzTooltipTitle]="post.title"
               [innerHTML]="(post.snippet.title || post.title || '').trim() | safeHtml"></div>
         </div>

         <div class="related-records-count">
            <div>{{ post?.attachments?.length }} Elements</div>
            <div class="dot-count">.</div>
            <div>{{ post?.attachments?.length }} Attachments</div>
            <div class="dot-count">.</div>
            <div>{{ post?.postContentTags?.length }} Tags</div>
            <div class="right-align">
               <div class="circular-icon">
                  <i
                     nz-icon
                     nzType="bulb"
                     nzTheme="outline"
                     [href]=""></i>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template
   #imageTemplate
   let-attachment>
   <ng-container>
      <!-- IMAGE -->

      <img
         class="post-attachment"
         *ngIf="attachment.isImage"
         [src]="attachment.previewUrl"
         [title]="attachment.attachmentName"
         [alt]="attachment.attachmentName" />

      <!-- VIDEO -->

      <img
         class="post-attachment"
         *ngIf="attachment.isthumpVideo && attachment.attachmentPreviewName !== ''"
         [src]="attachment.previewUrl"
         [title]="attachment.attachmentName" />

      <i
         class="post-attachment icon"
         *ngIf="(attachment.isthumpVideo || attachment.isVideo) && attachment.attachmentPreviewName === ''"
         [title]="attachment.attachmentName"
         nz-icon
         nzType="{{ attachment.attachmentName | fileIcon }}"
         nzTheme="outline"></i>

      <!-- Doc FILES -->
      <img
         class="post-attachment"
         *ngIf="attachment.isFile && attachment.attachmentPreviewName !== ''"
         [src]="attachment.thumpnail"
         [title]="attachment.attachmentName" />

      <i
         class="post-attachment icon"
         *ngIf="attachment.isFile && attachment.attachmentPreviewName === ''"
         [title]="attachment.attachmentName"
         nz-icon
         nzType="{{ attachment.attachmentName | fileIcon }}"
         nzTheme="outline"></i>

      <!-- OTHER FILES -->
      <i
         *ngIf="!attachment.isImage && !attachment.isVideo && !attachment.isthumpVideo && !attachment.isFile"
         class="post-attachment icon"
         [title]="attachment.attachmentName"
         nz-icon
         nzType="{{ attachment.attachmentName | fileIcon }}"
         nzTheme="outline"></i>
   </ng-container>
</ng-template>
