<!--
                    {{searchService.filters.STORIES | json}}
          -->
<div
   [ngClass]="{ 'search-filters': true, 'isDrawer': isDrawer }"
   [attr.data-component]="'ncx/search/page/filters'">
   <app-loader-component
      loaderType="SECTION_LOADER"
      size="default"
      [isLoaded]="!isLoading"></app-loader-component>

   <div class="sider-header">
      <span>Filters</span>
      <div
         class="right-align"
         (click)="clearFilters()">
         <i
            nz-icon
            nzType="clear"
            nzTheme="outline"></i>
         <span class="clear">Clear</span>
      </div>
   </div>

   <app-scroll-container>
      <ng-template bodyTemplate>
         <div class="filter-body">
            <!-- ****************** Stories Filter ************************* -->

            <ng-container *ngIf="filterState === 'STORIES'">
               <app-stories-filters-new></app-stories-filters-new>
            </ng-container>

            <!-- ****************** Posts Filter ************************* -->

            <ng-container *ngIf="filterState === 'POSTS'">
               <app-post-filters-new></app-post-filters-new>
            </ng-container>

            <!-- ****************** Elements Filter ************************* -->

            <ng-container *ngIf="filterState === 'ELEMENTS'">
               <app-element-filters></app-element-filters>
            </ng-container>

            <!-- ****************** Angles Filter ************************* -->

            <ng-container *ngIf="filterState === 'ANGLES'">
               <!-- {{ searchService.filters.ANGLES | json }} -->

               <app-angle-filters-new></app-angle-filters-new>
            </ng-container>

            <!-- ****************** Groups Filter ************************* -->

            <ng-container *ngIf="filterState === 'GROUPS'">
               <!--
                    {{searchService.filters.GROUPS | json}}
          -->

               <nz-collapse
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <section>
                        <nz-radio-group
                           [(ngModel)]="searchService.filters.GROUPS.sort"
                           (ngModelChange)="onFilterChanged()"
                           [nzButtonStyle]="'solid'">
                           <label
                              nz-radio-button
                              nzValue="descending">
                              Newest First
                           </label>
                           <label
                              nz-radio-button
                              nzValue="ascending">
                              Oldest First
                           </label>
                        </nz-radio-group>
                     </section>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- ****************** People Filter ************************* -->

            <ng-container *ngIf="filterState === 'PEOPLE'">
               <nz-collapse
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <section>
                        <nz-radio-group
                           [(ngModel)]="searchService.filters.PEOPLE.sort"
                           (ngModelChange)="onFilterChanged()"
                           [nzButtonStyle]="'solid'">
                           <label
                              nz-radio-button
                              nzValue="ascending">
                              A-Z
                           </label>
                           <label
                              nz-radio-button
                              nzValue="descending">
                              Z-A
                           </label>
                        </nz-radio-group>
                     </section>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- CLEAR BUTTON -->
            <button
               *ngIf="filterState === 'STORIES' || filterState === 'POSTS' || filterState === 'ANGLES'"
               class="mt-24 mb-24 clear-btn"
               nz-button
               nzBlock
               (click)="clearFilters()">
               <span
                  nz-icon
                  nzType="clear"
                  nzTheme="outline"></span>
               Clear
            </button>
         </div>
      </ng-template>
   </app-scroll-container>
</div>

<ng-template #followingHeader>
   <i
      nz-icon
      nzType="star"></i>
   Following
</ng-template>

<ng-template #dateHeader>
   <i
      nz-icon
      nzType="calendar"></i>
   Date
</ng-template>

<ng-template #editorialStandardsHeader>
   <i
      nz-icon
      nzType="audit"></i>
   Editorial/Standards
</ng-template>

<ng-template #rcLegalHeader>
   <i
      nz-icon
      nzType="trademark-circle"></i>
   R&C/Legal
</ng-template>

<ng-template #elementsHeader>
   <i
      nz-icon
      nzType="file-image"></i>
   Elements
</ng-template>

<ng-template #authorHeader>
   <i
      nz-icon
      nzType="user"></i>
   Author
</ng-template>

<ng-template #suffixIconSearch>
   <i
      nz-icon
      nzType="search"></i>
</ng-template>

<ng-template #suffixIconClear>
   <button (click)="onClearAuthor()">
      <i
         nz-icon
         nzType="close-circle"></i>
   </button>
</ng-template>

<ng-template #orderHeader>
   <i
      nz-icon
      nzType="sort-ascending"></i>
   Sort By
</ng-template>
