<!-- Filter BY PRIVACY -->
<nz-collapse
   nzExpandIconPosition="right"
   [nzBordered]="false">
   <nz-collapse-panel
      [nzHeader]="privacyTpl"
      nzActive="true"
      class="elements">
      <nz-radio-group
         class="list"
         [(ngModel)]="searchService.filters.ANGLES.privacy"
         (ngModelChange)="onFilterChanged()"
         [nzButtonStyle]="'solid'">
         <ng-container *ngFor="let obj of anglePrivacyOrder">
            <label
               nz-radio
               [nzValue]="obj.value">
               {{ obj.label }}
            </label>
         </ng-container>
      </nz-radio-group>
   </nz-collapse-panel>
</nz-collapse>

<div class="no-top-border bottom-border">
   <nz-collapse
      nzExpandIconPosition="right"
      [nzBordered]="false">
      <nz-collapse-panel
         [nzHeader]="authorHeader"
         nzActive>
         <nz-input-group [nzSuffix]="searchService.filters.ANGLES.authorName ? suffixIconClear : suffixIconSearch">
            <input
               type="text"
               class="filterAuthor"
               nz-input
               placeholder="Search Author..."
               [nzAutocomplete]="auto"
               [(ngModel)]="searchService.filters.ANGLES.authorName"
               (keydown)="onSearchAuthor($event)"
               (keyup)="onSearchAuthor($event)"
               (ngModelChange)="onSelectAuthor($event, 'ANGLES')" />
         </nz-input-group>
         <nz-autocomplete #auto>
            <ng-container *ngFor="let profile of profileOptions">
               <nz-auto-option [nzValue]="profile.name">
                  {{ profile.name }}
               </nz-auto-option>
            </ng-container>
            <nz-auto-option
               *ngIf="
                  searchService.filters.ANGLES.authorName !== '' &&
                  profileOptions.length === 0 &&
                  profileLoaded === true
               ">
               No Authors Found
            </nz-auto-option>
         </nz-autocomplete>
      </nz-collapse-panel>
   </nz-collapse>
</div>

<ng-template #dateHeader>
   <i
      nz-icon
      nzType="calendar"></i>
   Date
</ng-template>

<ng-template #postTypeHeader>
   <i
      nz-icon
      nzType="FileText"></i>
   Post Type
</ng-template>

<ng-template #editorialStandardsHeader>
   <i
      nz-icon
      nzType="audit"></i>
   Editorial / Standards
</ng-template>

<ng-template #rcLegalHeader>
   <i
      nz-icon
      nzType="trademark"></i>
   R&C / Legal
</ng-template>

<ng-template #authorHeader>
   <i
      nz-icon
      nzType="user"></i>
   Author
</ng-template>

<ng-template #suffixIconSearch>
   <i
      nz-icon
      nzType="search"></i>
</ng-template>

<ng-template #suffixIconClear>
   <button (click)="onClearAuthor()">
      <i
         nz-icon
         nzType="close-circle"></i>
   </button>
</ng-template>

<ng-template #privacyTpl>
   <span class="filter-collapse-header">
      <i
         nz-icon
         nzType="eye"
         nzTheme="outline"></i>
      Privacy
   </span>
</ng-template>

<ng-template #orderHeader>
   <i
      nz-icon
      nzType="sort-ascending"></i>
   Sort By
</ng-template>
