import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointService } from '@services/breakpoint.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { UserSettingService } from '@services/profile/usersetting.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'app-search-results-new',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsNewComponent implements OnInit {

  // State of the filter drawer
  isFilterDrawerCollapsed = true;

  showTabs: boolean = false;

  // Default tab
  tabIndex = 0;

  tabOrder = ['ALL', 'STORIES', 'POSTS', 'ELEMENTS', 'ANGLES', 'GROUPS', 'PEOPLE'];

  // Search term entered in search box
  searchTerm: string = '';

  searchText: string = '';

  suggestions: string[] = [];

  suggestionSelected: boolean = false;

  private readonly localStorageKey = 'searchHistory';

  // Search HTML input box
  @ViewChild('searchInputField') searchInputField!: ElementRef;

  constructor(
    private location: Location,
    private gaService: GoogleAnalyticsEventService,
    private searchService: GlobalSearchService,
    private activatedRoute: ActivatedRoute,
    private userSettingService: UserSettingService,
    private breakpointService: BreakpointService
  ) {

    this.isFilterDrawerCollapsed = this.isMobile;

  }

  ngOnInit() {

    this.searchService.clearFilters();
    this.searchTerm = '';

    // When page loads, if there is a search term passed in the URL, populate the search service
    this.activatedRoute.queryParams.subscribe(param => {

      if (param.tab) {

        this.setInitTab(param.tab);

      }
      if (param.searchString) {

        this.setInitSearchString(param.searchString);

      }

    });

    // If the search term changes by the Search Service, update the search text in the input box
    this.searchService.searchTerm.subscribe((searchText: string) => {

      this.gaService.trackGlobalSearch(searchText);
      this.searchTerm = searchText;

    });

    this.searchService.gloablSearchActive.next(false);
    // this.loadSuggestions();

  }

  /**
   * When changes are made in the metadata/sidebar filter drawer,
   * broadcast the change to all child components through the Search Service
   *
   */
  applyFilters() {

    this.searchService.filterChanged.next();

  }

  /**
   * Change Tab
   *
   */
  onChangeTab(event: NzTabChangeEvent) {

    this.tabIndex = event.index;

    this.updateURL();

  }

  /**
   * When search term is entered in input field
   *
   */
  setSearchTerm(event: any) {
    this.suggestionSelected = true;

    const text = (event.target.value || '').trim();

    this.saveSearchTerm(text);

    this.searchService.searchTerm.next(text);

    this.updateURL();

    this.suggestions = [];

    this.searchTerm == '';

  }

  /**
   * Clear Search Term
   *
   */
  clearSearchTerm() {

    this.searchService.clearFilters();

    this.searchService.searchTerm.next('');

    this.onFocus();

    this.updateURL();

  }

  /**
   * If a tab is passed in the URL
   *
   */
  setInitTab(tab: string) {

    const tabs = this.tabOrder.map((key: string) => key.toLowerCase());

    if (tabs.includes(tab.toLowerCase())) {

      this.tabIndex = tabs.indexOf(tab);

    }

  }

  /**
   * If a search term is passed in the URL
   *
   */
  setInitSearchString(keyword: string) {

    this.searchService.searchTerm.next(keyword);
    this.searchTerm = keyword;

    this.searchText = '';
    this.searchText = keyword;

  }

  /**
   * Toggle filter drawer
   *
   */
  toggleFilterDrawer(): void {

    this.isFilterDrawerCollapsed = !this.isFilterDrawerCollapsed;

  }

  /**
   * Close filter drawer
   *
   */
  closeFilterDrawer() {

    this.isFilterDrawerCollapsed = true;

  }

  /**
   * Update the URL with the tab and search string
   *
   */
  updateURL() {

    const hash = window.location.hash;

    const query = hash.split('?');

    let params;

    if (query && query.length) {

      params = new URLSearchParams(query[1]);

    } else {

      params = new URLSearchParams();

    }

    params.set('tab', this.tabOrder[this.tabIndex].toLowerCase());

    if (this.searchTerm) {

      params.set('searchString', this.searchTerm);

    } else {

      params.delete('searchString');

    }

    const queryString = decodeURIComponent(params.toString());

    this.location.replaceState(`/ncx/search-results-new?${queryString}`);

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  onInput(event: Event): void {
    const term = (event.target as HTMLInputElement).value;
    this.searchTerm = term;
    this.suggestions = [];
    this.suggestionSelected = false;
    this.searchText = term;
  }

  onFocus(): void {
    this.loadSuggestions();
    this.searchInputField.nativeElement.focus();
    this.suggestionSelected = false;
  }

  selectSuggestion(suggestion: string): void {
    this.suggestionSelected = true;
    this.searchTerm = suggestion;
    this.suggestions = [];
    // Perform search logic here

    this.saveSearchTerm(this.searchTerm);

    this.searchService.searchTerm.next(this.searchTerm);

    this.updateURL();

    this.suggestions = [];

  }

  private saveSearchTerm(term: string): void {
    if (term.trim() === '') return;
    let searchHistory = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
    if (!searchHistory.includes(term)) {
      searchHistory.unshift(term);
      if (searchHistory.length > 5) {
        searchHistory.pop();
      }
      localStorage.setItem(this.localStorageKey, JSON.stringify(searchHistory));
    }
  }

  removeSearchTerm(term: string): void {
    let searchHistory = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
    const index = searchHistory.indexOf(term);
    if (index !== -1) {
      searchHistory.splice(index, 1);
      localStorage.setItem(this.localStorageKey, JSON.stringify(searchHistory));
    }
    this.searchTerm = '';
    this.loadSuggestions();
  }

  private loadSuggestions(): void {
    const searchHistory = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
    if (this.searchTerm.trim() === '') {
      this.suggestions = searchHistory;
    }
    else {
      //this.suggestions = searchHistory.filter((term: string) => term.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

  ngOnDestroy() {
    this.searchService.gloablSearchActive.next(true);
  }

  seeAllResults() {
    this.showTabs = true;
  }

}
