export const Page2 = {
    "total": 25133,
    "results": [
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002098",
                "creationdate": 1726745420,
                "modificationdate": 1726745420,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002098",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "MSNBC",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG01",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "CF Express",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "Micro SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745420,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745420,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745420,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002098",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002102",
                "creationdate": 1726745420,
                "modificationdate": 1726745420,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002102",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "Entertainment",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG01",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "CF Express",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "Micro SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745420,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745420,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745420,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002102",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002084",
                "creationdate": 1726745419,
                "modificationdate": 1726745419,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002084",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "Corporate",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Cfast",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    },
                    {
                        "deviceType": "P2",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    },
                    {
                        "deviceType": "SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 4,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 6,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745419,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745419,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745419,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002084",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002060",
                "creationdate": 1726745417,
                "modificationdate": 1726745417,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002060",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "Facilities",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "VHS",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    },
                    {
                        "deviceType": "CD",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    },
                    {
                        "deviceType": "Media Shuttle",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 4,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745417,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745417,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745417,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002060",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002074",
                "creationdate": 1726745417,
                "modificationdate": 1726745417,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002074",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "CNBC",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG02",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    },
                    {
                        "deviceType": "CNBC Dropbox",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    },
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 3,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 6,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745417,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745417,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745417,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002074",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002055",
                "creationdate": 1726745416,
                "modificationdate": 1726745416,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002055",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "NBC News",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Cfast",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ]
                    },
                    {
                        "deviceType": "P2",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ]
                    },
                    {
                        "deviceType": "SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 10,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 8,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745416,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745416,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745416,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002055",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002046",
                "creationdate": 1726745414,
                "modificationdate": 1726745414,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002046",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "ERG Events",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG02",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    },
                    {
                        "deviceType": "CNBC Dropbox",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    },
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 1,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 3,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 6,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745414,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745414,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745414,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002046",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002032",
                "creationdate": 1726745408,
                "modificationdate": 1726745408,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002032",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "Acorn",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "VHS",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    },
                    {
                        "deviceType": "CD",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    },
                    {
                        "deviceType": "Media Shuttle",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 4,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745409,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745408,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745408,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002032",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266138",
                "creationdate": 1726745220,
                "modificationdate": 1726745220,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266138",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Telemundo",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Solid State Drive",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Other",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "We Transfer",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266138",
                "fileIngestRequestID": "FI2024266138",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745220,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745220,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745220,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266127",
                "creationdate": 1726745214,
                "modificationdate": 1726745215,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266127",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Peacock",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Bureau NAS",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Producer Mailbox",
                        "deviceTypeOther": null,
                        "quantity": "4",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Remote",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266127",
                "fileIngestRequestID": "FI2024266127",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745215,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745214,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745215,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266102",
                "creationdate": 1726745212,
                "modificationdate": 1726745212,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266102",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Olympics",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "XDCAM DISC",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SXS Card",
                        "deviceTypeOther": null,
                        "quantity": "3",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Hard Drive",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266102",
                "fileIngestRequestID": "FI2024266102",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745212,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745212,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745212,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266117",
                "creationdate": 1726745212,
                "modificationdate": 1726745213,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266117",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Universal Kids",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266117",
                "fileIngestRequestID": "FI2024266117",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745213,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745212,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745213,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266093",
                "creationdate": 1726745210,
                "modificationdate": 1726745211,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266093",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Operations",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "VHS",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Panasonic GH4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CD",
                        "deviceTypeOther": null,
                        "quantity": "4",
                        "cameraType": [
                            "Panasonic GH4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Media Shuttle",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Panasonic GH4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266093",
                "fileIngestRequestID": "FI2024266093",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745211,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745210,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745211,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266084",
                "creationdate": 1726745209,
                "modificationdate": 1726745209,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266084",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "News Digital",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266084",
                "fileIngestRequestID": "FI2024266084",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745209,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745209,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745209,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266070",
                "creationdate": 1726745209,
                "modificationdate": 1726745209,
                "targetURL": null,
                "storyID": 1726738976574,
                "description": ""
            },
            "fileIngestID": "FI2024266070",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "NBC Sports",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "Test-Merge_Story_0919_QAUI_2",
                "storySlugID": "1726738976574",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:56 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:56 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578560,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Solid State Drive",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Other",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "We Transfer",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266070",
                "fileIngestRequestID": "FI2024266070",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745209,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745209,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745209,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266065",
                "creationdate": 1726745121,
                "modificationdate": 1726745121,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "fileIngestID": "FI2024266065",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:54 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:54 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578440,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Solid State Drive",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Other",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "We Transfer",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10",
                            "GoPro Hero 4"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266065",
                "fileIngestRequestID": "FI2024266065",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745121,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745121,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745121,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266057",
                "creationdate": 1726745116,
                "modificationdate": 1726745117,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "fileIngestID": "FI2024266057",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "MSNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:54 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:54 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578440,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG08",
                    "Nimbus",
                    "DC Network News"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266057",
                "fileIngestRequestID": "FI2024266057",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745117,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745116,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745117,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266054",
                "creationdate": 1726745116,
                "modificationdate": 1726745117,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "fileIngestID": "FI2024266054",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Entertainment",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:54 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:54 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578440,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "2",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "7",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266054",
                "fileIngestRequestID": "FI2024266054",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745117,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745116,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745117,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266040",
                "creationdate": 1726745115,
                "modificationdate": 1726745115,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "fileIngestID": "FI2024266040",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Corporate",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:54 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:54 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578440,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Bureau NAS",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Producer Mailbox",
                        "deviceTypeOther": null,
                        "quantity": "4",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Remote",
                        "deviceTypeOther": null,
                        "quantity": "6",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266040",
                "fileIngestRequestID": "FI2024266040",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745115,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745115,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745115,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266030",
                "creationdate": 1726745114,
                "modificationdate": 1726745115,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "fileIngestID": "FI2024266030",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "NBC News",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:54 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:54 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578440,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "10",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test ",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SD",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test Test",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266030",
                "fileIngestRequestID": "FI2024266030",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745115,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745114,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745115,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        }
    ]
}