import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { CommonService } from '@services/common-service';
import { GlobalSearchService } from '@services/global-search.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';


@Component({
  selector: 'topic-search',
  templateUrl: './search-topic.component.html',
  styleUrls: ['./search-topic.component.scss']
})
export class SearchTopicComponent implements OnInit, OnDestroy, AfterViewChecked {

  topicSearch: Subject<string> = new Subject();

  topicSearchInputValue: string = '';

  @Input() selectedTopics: any[] = [];

  @Output() onTopicSelected: EventEmitter<any[]> = new EventEmitter<any[]>

  constructor(
    private cService: CommonService,
    public searchService: GlobalSearchService,
  ) { }

  ngOnInit() {

    this.topicSearch.pipe(debounceTime(500)).subscribe(searchTextValue => {

      this.getTopics(searchTextValue);

    });

  }

  ngOnDestroy() {


  }

  /**
   * ngAfterViewChecked Angular Life cycle
   */
  ngAfterViewChecked(): void {



  }




  onChangeInTopicInput(value: string, event: any): void {

    setTimeout(() => {

      this.topicSearch.next(event.target.value);

    }, 200);

  }


  topics: any[] = [];

  formattedTopics: any[] = [];

  // selectedTopics: any[] = [];

  getTopics(searchTopicText: string) {

    const queryStr = `?excludeDeleted=true&&topicName= ${searchTopicText}`;

    this.cService.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStr).subscribe((data: any) => {

      this.formattedTopics = data.topics.map(topic => ({
        ...topic,
        formattedLabel: `${topic.topicCode} - ${topic.topicName}`
      }));

      this.showUnSelectedTopics();

    });

  }

  showUnSelectedTopics() {

    this.topics = [];

    if (this.selectedTopics.length === 0) {

      this.topics = this.formattedTopics.map(topic => topic.formattedLabel);
    }
    else {

      this.formattedTopics.forEach((item) => {

        const foundTopic = this.selectedTopics?.find(topic => topic.topicId === item.topicId);

        if (!foundTopic) {

          this.topics.push(item.formattedLabel);
        }

      });
    }
  }

  titleMatch($event) {

    if (this.selectedTopics.length === 0) {

      const selectedTopic = this.formattedTopics.find(topic => topic.formattedLabel === $event);

      if (selectedTopic) {
        setTimeout(() => {
          this.topicSearchInputValue = '';
        }, 100);

        this.selectedTopics.push(selectedTopic);

        this.onTopicSelected.emit(this.selectedTopics);
      }
    }
    else {

      const foundTopic = this.selectedTopics?.find(topic => topic.formattedLabel === $event);

      if (!foundTopic) {

        const selectedTopic = this.formattedTopics.find(topic => topic.formattedLabel === $event);

        if (selectedTopic) {

          this.selectedTopics.push(selectedTopic);

          this.onTopicSelected.emit(this.selectedTopics);

          setTimeout(() => {
            this.topicSearchInputValue = '';
          }, 100);
        }

      }
    }
  }


  onEnter(_value) {

  }

  handleClose(removedTag: object): void {

    this.selectedTopics = this.selectedTopics.filter(tag => tag !== removedTag);

  }
}