export const Page8 = {
    "total": 25133,
    "results": [
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002717",
                "creationdate": 1721395594,
                "modificationdate": 1721395594,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002717",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "CNBC",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395594,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395594,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395594,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002717",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002695",
                "creationdate": 1721395593,
                "modificationdate": 1721395593,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002695",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "Acorn",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395593,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395593,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395593,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002695",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002704",
                "creationdate": 1721395593,
                "modificationdate": 1721395594,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002704",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395594,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395593,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395594,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002704",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002690",
                "creationdate": 1721395593,
                "modificationdate": 1721395593,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002690",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "Corporate",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395593,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395593,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395593,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002690",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002687",
                "creationdate": 1721395593,
                "modificationdate": 1721395593,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002687",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "Entertainment",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395593,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395593,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395593,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002687",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002707",
                "creationdate": 1721395593,
                "modificationdate": 1721395593,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002707",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "MSNBC",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395593,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395593,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395593,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002707",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002665",
                "creationdate": 1721395591,
                "modificationdate": 1721395591,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002665",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "Facilities",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395591,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395591,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395591,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002665",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002676",
                "creationdate": 1721395591,
                "modificationdate": 1721395591,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002676",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "ERG Events",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "CNBC - San Francisco",
                    "TV-18 - India"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395592,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395591,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395591,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002676",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath null",
                "id": "FO2024002678",
                "creationdate": 1721395591,
                "modificationdate": 1721395591,
                "targetURL": null,
                "storyID": 1721368389255,
                "description": ""
            },
            "feedOutRequestID": "FO2024002678",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115161,
                        "showunittitle": "CNBC-Test",
                        "budgetCode": "T-748498"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "TEST_STORY_inline_image",
                "storySlugID": "1721368389255",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "9:56 PM",
                "airTimeFlexible": "No",
                "division": "NBC News",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-07-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:56 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1721395592,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1721395591,
                "createdBy": "Peddina, Sainath",
                "modified": 1721395591,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002678",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1722228960,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Maridoss, Kishor null",
                "id": "FO2024002574",
                "creationdate": 1720441562,
                "modificationdate": 1720441563,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002574",
            "content": {
                "showunit": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "ERG Events",
                "requesters": [
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "206469507",
                        "emailId": "jeevanandham.r@nbcuni.com",
                        "workPhone": "4082828282"
                    },
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Maridoss, Kishor",
                "requestorName": "Maridoss, Kishor",
                "requestorContact": null,
                "requestorEmail": "jeevanandham.r@nbcuni.com",
                "requestorKey": "jeevanandham.r@nbcuni.com",
                "producerName": "Maridoss, Kishor",
                "producerKey": "jeevanandham.r@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "30 Rock"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1720441563,
                        "state": "NEW",
                        "ssoId": "206469507"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720441562,
                "createdBy": "Maridoss, Kishor",
                "modified": 1720441563,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002574",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720427160,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024002566",
                "creationdate": 1720441268,
                "modificationdate": 1720441273,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002566",
            "content": {
                "showunit": [
                    {
                        "id": 115135,
                        "showunittitle": "CNBC - Corporate Initiatives",
                        "budgetCode": "T-EDT22COR.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "106469501",
                        "emailId": "jive@localhost",
                        "workPhone": "4082828282"
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG06"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720441273,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720441268,
                "createdBy": "Davis, Alandre",
                "modified": 1720441273,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002566",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720426860,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024002558",
                "creationdate": 1720441129,
                "modificationdate": 1720441130,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002558",
            "content": {
                "showunit": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "106469501",
                        "emailId": "jive@localhost",
                        "workPhone": "4082828282"
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720441130,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720441129,
                "createdBy": "Davis, Alandre",
                "modified": 1720441130,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002558",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720426680,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Davis, Alandre ",
                "id": "MTD2024001438",
                "creationdate": 1720193888,
                "modificationdate": 1720193888,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "mtdRequestID": "MTD2024001438",
            "content": {
                "showunit": [
                    {
                        "id": 115128,
                        "showunittitle": "CNBC - Clip Desk",
                        "budgetCode": "T-EDT22CLD.J047"
                    }
                ],
                "slug": "test 123",
                "storyName": "TEST TEST TEST STORY_IXriUFOjQ1ud",
                "storySlugID": "1720182969076",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Telemundo",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "testing-testing, testing-testing",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "900074058",
                        "emailId": "jive@localhost",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Davis, Alandre",
                "requestorContact": "",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "producerName": "testing-testing, testing-testing",
                "producerKey": "jive@localhost",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "No",
                "correspondent": null,
                "talent": [],
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": null,
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    }
                ],
                "gigSize": "",
                "deviceStatus": null,
                "instructions": null,
                "reqDestinations": [
                    {
                        "destinations": "Send This File",
                        "quantity": 1,
                        "destDetails": null
                    }
                ],
                "ffDestinations": [
                    {
                        "destination": "CNBC Dropbox",
                        "quantity": 0,
                        "destDetails": null
                    }
                ],
                "ffFileFormat": [
                    ".mp3"
                ],
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720193889,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed General Details Section, Changed Requester Section, Changed Producer Details Section, Changed Destination Section, Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720193971,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720193888,
                "createdBy": "Davis, Alandre",
                "modified": 1720193971,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "mtdRequestID": "MTD2024001438",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FI2024265115",
                "creationdate": 1720189972,
                "modificationdate": 1720189973,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024265115",
            "content": {
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "division": "Corporate",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1720175520,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01"
                ],
                "transcodeNeeded": "",
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": "No",
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "test",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265115",
                "fileIngestRequestID": "FI2024265115",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720189973,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1720189972,
                "createdBy": "Davis, Alandre",
                "modified": 1720189973,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Maridoss, Kishor null",
                "id": "FO2024002545",
                "creationdate": 1720184975,
                "modificationdate": 1720184975,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002545",
            "content": {
                "showunit": [
                    {
                        "id": 115120,
                        "showunittitle": "CNBC - Closing Bell (CB)",
                        "budgetCode": "T-EDT22CLB.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Entertainment",
                "requesters": [
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "206469507",
                        "emailId": "jeevanandham.r@nbcuni.com",
                        "workPhone": "4082828282"
                    },
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Maridoss, Kishor",
                "requestorName": "Maridoss, Kishor",
                "requestorContact": null,
                "requestorEmail": "jeevanandham.r@nbcuni.com",
                "requestorKey": "jeevanandham.r@nbcuni.com",
                "producerName": "Maridoss, Kishor",
                "producerKey": "jeevanandham.r@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "CNBC - San Francisco"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1720184975,
                        "state": "NEW",
                        "ssoId": "206469507"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720184975,
                "createdBy": "Maridoss, Kishor",
                "modified": 1720184975,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002545",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720170540,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Maridoss, Kishor null",
                "id": "FO2024002538",
                "creationdate": 1720178246,
                "modificationdate": 1720178246,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002538",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "206469507",
                        "emailId": "jeevanandham.r@nbcuni.com",
                        "workPhone": "4082828282"
                    },
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Maridoss, Kishor",
                "requestorName": "Maridoss, Kishor",
                "requestorContact": null,
                "requestorEmail": "jeevanandham.r@nbcuni.com",
                "requestorKey": "jeevanandham.r@nbcuni.com",
                "producerName": "Maridoss, Kishor",
                "producerKey": "jeevanandham.r@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1720178246,
                        "state": "NEW",
                        "ssoId": "206469507"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720178246,
                "createdBy": "Maridoss, Kishor",
                "modified": 1720178246,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002538",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720163820,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024002521",
                "creationdate": 1720177964,
                "modificationdate": 1720177964,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002521",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "106469501",
                        "emailId": "jive@localhost",
                        "workPhone": "4082828282"
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720177965,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720177964,
                "createdBy": "Davis, Alandre",
                "modified": 1720177964,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002521",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720163520,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024002513",
                "creationdate": 1720027445,
                "modificationdate": 1720027449,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002513",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 115128,
                        "showunittitle": "CNBC - Clip Desk",
                        "budgetCode": "T-EDT22CLD.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Corporate",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test"
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": [
                    "test2"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "test",
                "deviceTimer": "11:00:00",
                "feedTime": "3:15 AM",
                "mediaID": "123456",
                "ffDestinations": [
                    "WG01",
                    "WG02",
                    "WG06"
                ],
                "deviceStatus": "",
                "deviceTimerOption": null,
                "clipCount": 1,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Vasan, Maru",
                "loggedInSSO": "206791269",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720032056,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720031066,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720029889,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720027449,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed General Details Section, Changed Additional Recipient Section, Changed Producer Details Section, Changed Talent Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1720040762,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1720027445,
                "createdBy": "Davis, Alandre",
                "modified": 1720040761,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "feedOutRequestID": "FO2024002513",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1720013040,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Maridoss, Kishor null",
                "id": "FO2024002445",
                "creationdate": 1719836113,
                "modificationdate": 1719836113,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024002445",
            "content": {
                "showunit": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Maridoss, Kishor",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "206469507",
                        "emailId": "jeevanandham.r@nbcuni.com",
                        "workPhone": "4082828282"
                    }
                ],
                "title": "Maridoss, Kishor",
                "requestorName": "Maridoss, Kishor",
                "requestorContact": null,
                "requestorEmail": "jeevanandham.r@nbcuni.com",
                "requestorKey": "jeevanandham.r@nbcuni.com",
                "producerName": "Maridoss, Kishor",
                "producerKey": "jeevanandham.r@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1719836114,
                        "state": "NEW",
                        "ssoId": "206469507"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1719836113,
                "createdBy": "Maridoss, Kishor",
                "modified": 1719836113,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024002445",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1719821700,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FI2024265091",
                "creationdate": 1719835364,
                "modificationdate": 1719835364,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024265091",
            "content": {
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "Davis, Alandre",
                "seniorProducerKey": "alandre.Davis@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1719820920,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG02"
                ],
                "transcodeNeeded": "",
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": "No",
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "test",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "Root 6"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "WG01",
                    "WG02"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mp3"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "PRIVATE"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "TEST",
                "mediaId": null,
                "ffCnbcAvid": {
                    "avidFFWorkspace": null,
                    "avidFFProjectName": null,
                    "avidFFBinName": [],
                    "avidFFTimer": null,
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": false
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": false,
                    "stratusFFTranscodeComplete": false,
                    "stratusFFTimer": null,
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": false
                },
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "00:00:00",
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024265091",
                "fileIngestRequestID": "FI2024265091",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1719835364,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1719835384,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1719918050,
                        "state": "NEW",
                        "ssoId": "206791269"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1719918080,
                        "state": "NEW",
                        "ssoId": "206791269"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1719835364,
                "createdBy": "Davis, Alandre",
                "modified": 1719918080,
                "modifiedBy": "Vasan, Maru",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        }
    ]
}