<div
   class="story-landing-tab-container angles-tab"
   [attr.data-component]="'ncx/stories/landing/tabs/angles'">
   <div class="header">
      <!--
        {{filters.getRawValue() | json}}
    -->

      <nz-input-group
         class="search-bar"
         [formGroup]="filters"
         [nzSuffix]="suffixIconSearch">
         <input
            type="text"
            nz-input
            placeholder="Search Angles"
            formControlName="search"
            [value]="filters.controls['search']?.value"
            (keyup.enter)="onSearch()" />
      </nz-input-group>

      <ng-template #suffixIconSearch>
         <button (click)="isSearching ? clearSearch() : onSearch()">
            <i
               nz-icon
               [ngClass]="{ 'search-btn': true, 'clear': isSearching }"
               [nzType]="isSearching ? 'close-circle' : 'search'"></i>
         </button>
      </ng-template>

      <button
         nz-button
         [ngClass]="['filters', filterDrawerOpen ? 'active' : '', isFiltering ? 'filtering' : '']"
         (click)="onToggleFilterDrawer()">
         <i
            nz-icon
            nzType="filter"
            [nzTheme]="filterDrawerOpen && !isFiltering ? 'outline' : isFiltering ? 'fill' : 'outline'"></i>
      </button>
   </div>

   <app-scroll-container
      [footerHeight]="footerHeight"
      [additionalPadding]="25.5">
      <ng-template bodyTemplate>
         <div
            #bodyElement
            class="scroll-container-content content-body"
            infiniteScroll
            data-scrollable-container
            (scrolled)="onLoadMore()"
            (scroll)="scrollContainer()"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [infiniteScrollDisabled]="!canScrollMore"
            [scrollWindow]="false">
            <ng-container *ngFor="let storyAngle of data; let first = first">
               <div
                  [class.mt-16]="!first"
                  class="scroll-section">
                  <app-angle-details [angleDetail]="storyAngle"></app-angle-details>
               </div>
            </ng-container>

            <div
               class="not-found"
               *ngIf="data?.length === 0 && isLoaded">
               <nz-empty [nzNotFoundContent]="contentTpl">
                  <ng-template #contentTpl>
                     <span>No Angles Found</span>
                  </ng-template>
               </nz-empty>
            </div>
         </div>
      </ng-template>
   </app-scroll-container>

   <app-loader-component
      [isLoaded]="isLoaded"
      loaderType="SECTION_LOADER"></app-loader-component>
</div>
