export const Page10 = {
    "total": 25133,
    "results": [
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Jude, George Rabin null",
                "id": "FI2024264847",
                "creationdate": 1718121152,
                "modificationdate": 1718121179,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264847",
            "content": {
                "requesters": [
                    {
                        "displayName": "Jude, George Rabin",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Front End Developer",
                        "ssoId": "206727808",
                        "emailId": "georgerabin.jude@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Jude, George Rabin",
                "requestorContact": null,
                "requestorEmail": "georgerabin.jude@nbcuni.com",
                "requestorKey": "georgerabin.jude@nbcuni.com",
                "producerName": "Jude, George Rabin",
                "producerKey": "georgerabin.jude@nbcuni.com",
                "seniorProducer": "R, Balachandran",
                "seniorProducerKey": "Balachandran.R@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Entertainment",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    }
                ],
                "slug": "asdasdsa",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718140920,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Card",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        null
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        null
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": false
                },
                "contentDescription": "asdasdsadsa",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "Root 6"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "WG01",
                    "CNBC - AVID",
                    "CNBC - Stratus"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".jpeg"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "XDROOT"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "ytry",
                "mediaId": "media",
                "ffCnbcAvid": {
                    "avidFFWorkspace": "Real Estate Specials_Projects",
                    "avidFFProjectName": "project name",
                    "avidFFBinName": [
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name",
                        "avid name"
                    ],
                    "avidFFTimer": "01:00:00",
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": true
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": true,
                    "stratusFFTranscodeComplete": true,
                    "stratusFFTimer": "02:02:02",
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": true
                },
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "01:00:00",
                "deviceTimerOption": null,
                "comments": "test",
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264847",
                "fileIngestRequestID": null,
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Jude, George Rabin",
                "loggedInSSO": "206727808",
                "revisionNotes": [
                    {
                        "comments": "123",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718181428,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "222",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718181421,
                        "state": "NEW",
                        "ssoId": "206727808"
                    }
                ],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718185975,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718185937,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718181475,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718181450,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718181421,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718180700,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718175360,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718175320,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718168895,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718168869,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718131219,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718121300,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718121223,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718186280,
                        "state": "NEW",
                        "ssoId": "206727808"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718121152,
                "createdBy": "Jude, George Rabin",
                "modified": 1718186280,
                "modifiedBy": "Jude, George Rabin",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FI2024264825",
                "creationdate": 1718119558,
                "modificationdate": 1718119564,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264825",
            "content": {
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "division": "Entertainment",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115133,
                        "showunittitle": "CNBC - Events/Conferences",
                        "budgetCode": "T-EDT22EVE.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718105160,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        ""
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        null
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": false
                },
                "contentDescription": "test",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [
                    {
                        "id": 1718119559090,
                        "name": "Original Request.txt",
                        "url": "https://qaapi.producerdashboard.nbcuni.com/fileingestrequest/attachment/FI2024264825/1718119559090-Original Request.txt",
                        "fileType": "text/plain"
                    }
                ],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264825",
                "fileIngestRequestID": null,
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Heger, Maya",
                "loggedInSSO": "206732097",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718119564,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1718119558,
                "createdBy": "Davis, Alandre",
                "modified": 1718119564,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024001947",
                "creationdate": 1718118777,
                "modificationdate": 1718118793,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024001947",
            "content": {
                "showunit": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718118793,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718118777,
                "createdBy": "Davis, Alandre",
                "modified": 1718118793,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024001947",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1718104320,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024001911",
                "creationdate": 1718118254,
                "modificationdate": 1718118254,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024001911",
            "content": {
                "showunit": [
                    {
                        "id": 115130,
                        "showunittitle": "CNBC - Dotcom/Digital",
                        "budgetCode": "T-EDT22DIG.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Entertainment",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "test1, test",
                "seniorProducerKey": "test@gmail.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01",
                    "WG02",
                    "WG06"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "11:20 AM",
                "mediaID": "",
                "ffDestinations": [
                    "WG06"
                ],
                "deviceStatus": "",
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [
                    {
                        "comments": "tse",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718192873,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Heger, Maya",
                        "revisedDate": 1718120228,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718118254,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Destinations Section, Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718192923,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718118254,
                "createdBy": "Davis, Alandre",
                "modified": 1718192923,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "feedOutRequestID": "FO2024001911",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1718103840,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Bambani, Mohit null",
                "id": "FI2024264817",
                "creationdate": 1718093545,
                "modificationdate": 1718093547,
                "targetURL": null,
                "storyID": 1717998137176,
                "description": ""
            },
            "fileIngestID": "FI2024264817",
            "content": {
                "requesters": [
                    {
                        "displayName": "Bambani, Mohit",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "QA Tester",
                        "ssoId": "206815575",
                        "emailId": "Mohit.Bambani@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Bambani, Mohit",
                "requestorContact": null,
                "requestorEmail": "Mohit.Bambani@nbcuni.com",
                "requestorKey": "Mohit.Bambani@nbcuni.com",
                "producerName": "Bambani, Mohit",
                "producerKey": "Mohit.Bambani@nbcuni.com",
                "seniorProducer": "Bambani, Mohit",
                "seniorProducerKey": "Mohit.Bambani@nbcuni.com",
                "isRequestorProducer": "No",
                "division": "CNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    }
                ],
                "slug": "test",
                "storyName": "TEST_Story_Jun_06",
                "storySlugID": "1717998137176",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718113320,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "CNBC - AVID",
                    "CNBC - Stratus"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "Longform Projects",
                    "avidProjectName": "prokect",
                    "avidBinName": null,
                    "avidBinNames": [
                        "bin name1"
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Long GOP",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "24p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "stratus slug 1",
                        "stratus slug 2"
                    ],
                    "stratusMatchingTime": true,
                    "stratusMergeClip": false
                },
                "contentDescription": "content description",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "Root 6",
                    "RMI",
                    "Export",
                    "Other"
                ],
                "methodOfIngestOthersText": "jjflsjflksjaldkf",
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "WG01",
                    "CNBC - AVID",
                    "CNBC - Stratus",
                    "Nimbus",
                    "DC Network News"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mov",
                    ".jpeg",
                    "Other"
                ],
                "fileFormatOther": ".djflasjdlf",
                "gigSize": "100",
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "BPAV",
                    "XDROOT",
                    "Other"
                ],
                "folderFormatOther": "kdsjldjflsjdflkjslfjsldjf",
                "clipCount": 8,
                "binName": "bin name 1",
                "mediaId": "media id 003",
                "ffCnbcAvid": {
                    "avidFFWorkspace": "Real Estate Specials_Projects",
                    "avidFFProjectName": "what is your project?",
                    "avidFFBinName": [
                        "second bin of the century",
                        "first bin of the century",
                        null,
                        null
                    ],
                    "avidFFTimer": "78:10:10",
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": true
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": true,
                    "stratusFFTranscodeComplete": true,
                    "stratusFFTimer": "99:59:59",
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": true
                },
                "assistantEditor": "Bambani, Mohit",
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "50:59:59",
                "deviceTimerOption": null,
                "comments": "comments of the world are here",
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264817",
                "fileIngestRequestID": "FI2024264817",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718362885,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718362799,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed CNBC Stratus Section, Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718362433,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718362238,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718096168,
                        "state": "NEW",
                        "ssoId": "206815575"
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Bambani, Mohit",
                        "revisedDate": 1718093547,
                        "state": "NEW",
                        "ssoId": "206815575"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718093545,
                "createdBy": "Bambani, Mohit",
                "modified": 1718717138,
                "modifiedBy": "Heger, Maya",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Srinivasan, Krithika null",
                "id": "FI2024264804",
                "creationdate": 1718082686,
                "modificationdate": 1718082845,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264804",
            "content": {
                "requesters": [
                    {
                        "displayName": "Srinivasan, Krithika ",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Consultant",
                        "ssoId": "206420386",
                        "emailId": "krithika.srinivasan@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Srinivasan, Krithika",
                "requestorContact": null,
                "requestorEmail": "krithika.srinivasan@nbcuni.com",
                "requestorKey": "krithika.srinivasan@nbcuni.com",
                "producerName": "Srinivasan, Krithika ",
                "producerKey": "krithika.srinivasan@nbcuni.com",
                "seniorProducer": "test46d1_test123, test46d_test123",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "division": "NBC News",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 1683926787525,
                        "showunittitle": "testing add to crew 5/12",
                        "budgetCode": "13245"
                    }
                ],
                "slug": "test121",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test"
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718102640,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon XF205(DJ Camera)",
                            "Canon C300 Mark I"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "sfsdf",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Compact Flash",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon C300 Mark I"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "asa",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "CNBC - Stratus"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        ""
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Long GOP",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "24p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "qeqweqwewq"
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": true
                },
                "contentDescription": "fdgdfg",
                "fiComments": "dfgdgdg",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [
                    {
                        "id": 1718082885263,
                        "name": "email_logic.txt",
                        "url": "https://qaapi.producerdashboard.nbcuni.com/fileingestrequest/attachment/FI2024264804/1718082885263-email_logic.txt",
                        "fileType": "text/plain"
                    }
                ],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "jive@localhost"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264804",
                "fileIngestRequestID": null,
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Jude, George Rabin",
                "loggedInSSO": "206727808",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Srinivasan, Krithika",
                        "revisedDate": 1718082845,
                        "state": "NEW",
                        "ssoId": "206420386"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1718082686,
                "createdBy": "Srinivasan, Krithika",
                "modified": 1718082845,
                "modifiedBy": null,
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FI2024264785",
                "creationdate": 1718036727,
                "modificationdate": 1718036729,
                "targetURL": null,
                "storyID": 1717998137176,
                "description": ""
            },
            "fileIngestID": "FI2024264785",
            "content": {
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "division": "Entertainment",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115121,
                        "showunittitle": "CNBC - Closing Bell Overtime (CBOT)",
                        "budgetCode": "T-EDT22CBO.J047"
                    }
                ],
                "slug": "test",
                "storyName": "TEST_Story_Jun_06",
                "storySlugID": "1717998137176",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718022300,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "CNBC - Stratus"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        ""
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Long GOP",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "Unknown",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "test"
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": false
                },
                "contentDescription": "test",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264785",
                "fileIngestRequestID": "FI2024264785",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Davis, Alandre",
                "loggedInSSO": "206721012",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718036730,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1718036727,
                "createdBy": "Davis, Alandre",
                "modified": 1718036729,
                "modifiedBy": null,
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Jude, George Rabin null",
                "id": "FI2024264770",
                "creationdate": 1718022574,
                "modificationdate": 1718022620,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264770",
            "content": {
                "requesters": [
                    {
                        "displayName": "Jude, George Rabin",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Front End Developer",
                        "ssoId": "206727808",
                        "emailId": "georgerabin.jude@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Jude, George Rabin",
                "requestorContact": null,
                "requestorEmail": "georgerabin.jude@nbcuni.com",
                "requestorKey": "georgerabin.jude@nbcuni.com",
                "producerName": "Jude, George Rabin",
                "producerKey": "georgerabin.jude@nbcuni.com",
                "seniorProducer": "R, Balachandran",
                "seniorProducerKey": "Balachandran.R@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "MSNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    }
                ],
                "slug": "asdsad",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718042340,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "CNBC - Stratus"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        null
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Other",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "23.98p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "asdsad",
                        "1asdsadsadasda",
                        "2sadasdsa",
                        "3 asdnkasjdhkasijghdsuaigdsukagdasugduasgduyasfiysahgfhsagfyiasdgsakhgfiyhsagdsayudgajhsgdhatrfhgascvhb cbmasvcgasvhjasvcbasvchjsavghjncvfdhavsfgjnhasfjhasvdjhsadghjasdghasdghasndgvhnasvfdansdadsadsadsadsadsadsadsaasdasd"
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": false
                },
                "contentDescription": "sadasdsa",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "Prelude"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "WG06"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".heic"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "BPAV"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "asdasd",
                "mediaId": null,
                "ffCnbcAvid": {
                    "avidFFWorkspace": null,
                    "avidFFProjectName": null,
                    "avidFFBinName": [
                        "1",
                        "2"
                    ],
                    "avidFFTimer": null,
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": false
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": false,
                    "stratusFFTranscodeComplete": false,
                    "stratusFFTimer": null,
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": false
                },
                "assistantEditor": null,
                "deviceStatus": "",
                "deviceDate": null,
                "deviceTimer": "",
                "deviceTimerOption": "",
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264770",
                "fileIngestRequestID": "FI2024264770",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Jude, George Rabin",
                "loggedInSSO": "206727808",
                "revisionNotes": [
                    {
                        "comments": "test1",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718175255,
                        "state": "NEW",
                        "ssoId": "206727808"
                    }
                ],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718186386,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718175299,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718175134,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718173805,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfiller Notes Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718169576,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed CNBC Stratus Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718030566,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed CNBC Stratus Section, Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718029729,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718022620,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718191942,
                        "state": "",
                        "ssoId": "206727808"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718022574,
                "createdBy": "Jude, George Rabin",
                "modified": 1718191942,
                "modifiedBy": "Jude, George Rabin",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Davis, Alandre ",
                "id": "MTD2024001281",
                "creationdate": 1718022364,
                "modificationdate": 1718022364,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "mtdRequestID": "MTD2024001281",
            "content": {
                "showunit": [
                    {
                        "id": 115128,
                        "showunittitle": "CNBC - Clip Desk",
                        "budgetCode": "T-EDT22CLD.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Entertainment",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Davis, Alandre",
                "requestorContact": "",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "Test@Test3, Test@Test1",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG02",
                        "sourceDetails": null,
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    }
                ],
                "gigSize": "",
                "deviceStatus": null,
                "instructions": null,
                "reqDestinations": [
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 0,
                        "destDetails": null
                    }
                ],
                "ffDestinations": [
                    {
                        "destination": "CNBC Dropbox",
                        "quantity": 0,
                        "destDetails": null
                    }
                ],
                "ffFileFormat": [
                    ".mp3",
                    ".jpeg"
                ],
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfiller Notes Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718022724,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718022712,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1718022364,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1718107521,
                        "state": "NEW",
                        "ssoId": "206791269"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718022364,
                "createdBy": "Davis, Alandre",
                "modified": 1718107521,
                "modifiedBy": "Vasan, Maru",
                "requestComplete": true,
                "mtdRequestID": "MTD2024001281",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Jude, George Rabin null",
                "id": "FI2024264765",
                "creationdate": 1718022042,
                "modificationdate": 1718022088,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264765",
            "content": {
                "requesters": [
                    {
                        "displayName": "Jude, George Rabin",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Front End Developer",
                        "ssoId": "206727808",
                        "emailId": "georgerabin.jude@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Jude, George Rabin",
                "requestorContact": null,
                "requestorEmail": null,
                "requestorKey": null,
                "producerName": "Jude, George Rabin",
                "producerKey": "georgerabin.jude@nbcuni.com",
                "seniorProducer": "R, Balachandran",
                "seniorProducerKey": "Balachandran.R@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115133,
                        "showunittitle": "CNBC - Events/Conferences",
                        "budgetCode": "T-EDT22EVE.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718041800,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "CNBC - Stratus",
                    "WG08",
                    "WG06"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        null
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "asd"
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": false
                },
                "contentDescription": "asdsad",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "Import"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "WG01",
                    "CNBC - Stratus",
                    "WG08",
                    "WG06"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mov"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "BPAV"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "test bin",
                "mediaId": null,
                "ffCnbcAvid": {
                    "avidFFWorkspace": null,
                    "avidFFProjectName": null,
                    "avidFFBinName": [],
                    "avidFFTimer": null,
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": false
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": false,
                    "stratusFFTranscodeComplete": false,
                    "stratusFFTimer": null,
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": false
                },
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "",
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264765",
                "fileIngestRequestID": "FI2024264765",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Jude, George Rabin",
                "loggedInSSO": "206727808",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718022088,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Destinations Section, Changed CNBC Avid Section, Changed Fulfillment Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1718102786,
                        "state": "NEW",
                        "ssoId": null
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718022042,
                "createdBy": "Jude, George Rabin",
                "modified": 1718102785,
                "modifiedBy": "Vasan, Maru",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Bambani, Mohit null",
                "id": "FI2024264756",
                "creationdate": 1718020945,
                "modificationdate": 1718020947,
                "targetURL": null,
                "storyID": 1714978303290,
                "description": ""
            },
            "fileIngestID": "FI2024264756",
            "content": {
                "requesters": [
                    {
                        "displayName": "Bambani, Mohit",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "QA Tester",
                        "ssoId": "206815575",
                        "emailId": "Mohit.Bambani@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Bambani, Mohit",
                "requestorContact": null,
                "requestorEmail": "jeevanandham.r@nbcuni.com",
                "requestorKey": "jeevanandham.r@nbcuni.com",
                "producerName": "Bambani, Mohit",
                "producerKey": "Mohit.Bambani@nbcuni.com",
                "seniorProducer": "Bambani, Mohit",
                "seniorProducerKey": "Mohit.Bambani@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "CNBC",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115135,
                        "showunittitle": "CNBC - Corporate Initiatives",
                        "budgetCode": "T-EDT22COR.J047"
                    }
                ],
                "slug": "test",
                "storyName": "Test_Case_0909",
                "storySlugID": "1714978303290",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718040720,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast Express",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Compact Flash",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "CNBC - Stratus",
                    "CNBC - AVID"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "Longform Projects",
                    "avidProjectName": "fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfsdf",
                    "avidBinName": null,
                    "avidBinNames": [
                        "fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfsdf"
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfsdf",
                        "fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfsdf"
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": false
                },
                "contentDescription": "fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfsdf",
                "fiComments": "fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj dfsdf",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "AMA"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "CNBC - Stratus"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mov"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "XDROOT",
                    "BPAV"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "bin name 1",
                "mediaId": null,
                "ffCnbcAvid": {
                    "avidFFWorkspace": null,
                    "avidFFProjectName": null,
                    "avidFFBinName": [],
                    "avidFFTimer": null,
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": false
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": false,
                    "stratusFFTranscodeComplete": false,
                    "stratusFFTimer": null,
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": false
                },
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "",
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "mohit.bambani@nbcuni.com",
                    "mohit.bambani@nbcuni.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264756",
                "fileIngestRequestID": "FI2024264756",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Additional Recipient(s) Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718185823,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed CNBC Stratus Section, Changed Content Description Section, Changed Comments Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718108544,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718025505,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Bambani, Mohit",
                        "revisedDate": 1718020947,
                        "state": "NEW",
                        "ssoId": "206815575"
                    },
                    {
                        "comments": "Changed Source Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1719241943,
                        "state": "NEW",
                        "ssoId": "206791269"
                    },
                    {
                        "comments": "Changed Source Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1719243232,
                        "state": "NEW",
                        "ssoId": "206791269"
                    },
                    {
                        "comments": "Changed Source Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1719243390,
                        "state": "NEW",
                        "ssoId": "206791269"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718020945,
                "createdBy": "Bambani, Mohit",
                "modified": 1719243390,
                "modifiedBy": "Vasan, Maru",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Jude, George Rabin null",
                "id": "FI2024264742",
                "creationdate": 1718012595,
                "modificationdate": 1718012578,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264742",
            "content": {
                "requesters": [
                    {
                        "displayName": "Jude, George Rabin",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Front End Developer",
                        "ssoId": "206727808",
                        "emailId": "georgerabin.jude@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Jude, George Rabin",
                "requestorContact": null,
                "requestorEmail": "jive@localhost",
                "requestorKey": "jive@localhost",
                "producerName": "R, Balachandran",
                "producerKey": "Balachandran.R@nbcuni.com",
                "seniorProducer": "Vasan, Maru",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "No",
                "division": "NBC News",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115135,
                        "showunittitle": "CNBC - Corporate Initiatives",
                        "budgetCode": "T-EDT22COR.J047"
                    }
                ],
                "slug": "asd",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "teset"
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1718032320,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "CF Express",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon 5D Mark II",
                            "Canon 5D Mark III"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "CNBC - AVID",
                    "CNBC - Stratus"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "Longform Projects",
                    "avidProjectName": "csadas1",
                    "avidBinName": null,
                    "avidBinNames": [
                        "asdsa1"
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Intra-frame (AVC-I)",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "23.98p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "1",
                        "2",
                        "3",
                        "4"
                    ],
                    "stratusMatchingTime": false,
                    "stratusMergeClip": true
                },
                "contentDescription": "con des exmp",
                "fiComments": "comment exmp",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [
                    {
                        "id": 1718012596153,
                        "name": "need_access.txt",
                        "url": "https://qaapi.producerdashboard.nbcuni.com/fileingestrequest/attachment/FI2024264742/1718012596153-need_access.txt",
                        "fileType": "text/plain"
                    }
                ],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "AMA"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "CNBC - AVID",
                    "CNBC - Stratus"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mp3"
                ],
                "fileFormatOther": null,
                "gigSize": "1",
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "BPAV"
                ],
                "folderFormatOther": null,
                "clipCount": 1,
                "binName": "asdsad",
                "mediaId": "media",
                "ffCnbcAvid": {
                    "avidFFWorkspace": "Real Estate Specials_Projects",
                    "avidFFProjectName": "avis pro",
                    "avidFFBinName": [
                        "d"
                    ],
                    "avidFFTimer": "01:00:00",
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": true
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": true,
                    "stratusFFTranscodeComplete": true,
                    "stratusFFTimer": "01:00:00",
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": true
                },
                "assistantEditor": "R, Balachandran",
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "01:00:00",
                "deviceTimerOption": null,
                "comments": "xcacasd",
                "additionalRecipients": [
                    "asdas"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264742",
                "fileIngestRequestID": null,
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Vasan, Maru",
                "loggedInSSO": "206791269",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718204639,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718204577,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718204241,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Show Information Section, Changed Fulfillment Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1718017858,
                        "state": "NEW",
                        "ssoId": "206791269"
                    },
                    {
                        "comments": "Changed Additional Recipient(s) Section, Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718013513,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718012620,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718206918,
                        "state": "NEW",
                        "ssoId": "206727808"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718012595,
                "createdBy": "Jude, George Rabin",
                "modified": 1718206918,
                "modifiedBy": "Jude, George Rabin",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Bambani, Mohit null",
                "id": "FI2024264732",
                "creationdate": 1718003174,
                "modificationdate": 1718003812,
                "targetURL": null,
                "storyID": 1714082395526,
                "description": ""
            },
            "fileIngestID": "FI2024264732",
            "content": {
                "requesters": [
                    {
                        "displayName": "Bambani, Mohit",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "QA Tester",
                        "ssoId": "206815575",
                        "emailId": "Mohit.Bambani@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Sawant, Swati Shivram",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "QA Engineer",
                        "ssoId": "206790656",
                        "emailId": "swatishivram.sawant@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Bambani, Mohit",
                "requestorContact": null,
                "requestorEmail": "jeevanandham.r@nbcuni.com",
                "requestorKey": "jeevanandham.r@nbcuni.com",
                "producerName": "Bambani, Mohit",
                "producerKey": "Mohit.Bambani@nbcuni.com",
                "seniorProducer": "Sawant, Swati Shivram",
                "seniorProducerKey": "swatishivram.sawant@nbcuni.com",
                "isRequestorProducer": "No",
                "division": "NBC News",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 17,
                        "showunittitle": "MSNBC 4PM",
                        "budgetCode": "J07706120"
                    }
                ],
                "slug": "biden",
                "storyName": "TEST_AUTOMATION_STORY_BiKKWNq389Ej",
                "storySlugID": "1714082395526",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 124,
                        "name": "Velshi, Ali"
                    }
                ],
                "dateNeededBy": "2024-06-25",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "4:00 PM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1719331200,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "100",
                        "cameraType": [
                            "Canon C300 Mark II"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf\nslkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd\nfsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "15",
                        "cameraType": [
                            "Canon C300",
                            "Canon XF205(DJ Camera)"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf\nslkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd\nfsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "CNBC - AVID",
                    "CNBC - Stratus",
                    "WG01"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "News Projects",
                    "avidProjectName": "fkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlkfkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlk",
                    "avidBinName": null,
                    "avidBinNames": [
                        "fkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlk"
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Other",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "29.97p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "fkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlk",
                        "fkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlk"
                    ],
                    "stratusMatchingTime": true,
                    "stratusMergeClip": false
                },
                "contentDescription": "fkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlk",
                "fiComments": "fkljadslkfjlds jflkdjf lskdjflkdsjf lksdjflksdj flksdj flksdjflksdjflksdj flkdjflksdjflkjsdflkjsdlkfjsdlkfjsldkjflksdjflkdsjfldkjf lsdkjflskdjflksdjf lkdjflksdjflksd jflksdjf lksdjflkdsjflkdsjf lkdsjflkdsjflkdsj flksdjflksdjflksdjfsldk jflksdjflksdjflkdjsflksd jflkdsjflkdsjflkdsjflkdsjfl ksdjflksjdflkjslkfjsdlkfj fjsdlk",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [
                    {
                        "id": 1718023754353,
                        "name": "random_big_size_file (2).docx",
                        "url": "https://qaapi.producerdashboard.nbcuni.com/fileingestrequest/attachment/FI2024264732/1718023754353-random_big_size_file (2).docx",
                        "fileType": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    },
                    {
                        "id": 1718023795707,
                        "name": "random_big_size_file (3).docx",
                        "url": "https://qaapi.producerdashboard.nbcuni.com/fileingestrequest/attachment/FI2024264732/1718023795707-random_big_size_file (3).docx",
                        "fileType": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                ],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "Vantage",
                    "Other"
                ],
                "methodOfIngestOthersText": "other method of injest",
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "CNBC - AVID",
                    "CNBC - Stratus"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mov",
                    ".mp3",
                    "Other"
                ],
                "fileFormatOther": "other file format",
                "gigSize": "123",
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "BPAV",
                    "XDROOT",
                    "DCIM",
                    "Other"
                ],
                "folderFormatOther": "other folder format",
                "clipCount": 15,
                "binName": "dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfjdfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj",
                "mediaId": "media id dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfjdfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj",
                "ffCnbcAvid": {
                    "avidFFWorkspace": "Promo Projects",
                    "avidFFProjectName": "project 1",
                    "avidFFBinName": [
                        "bin name 1",
                        "bin name 2"
                    ],
                    "avidFFTimer": "00:34:59",
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": true
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": true,
                    "stratusFFTranscodeComplete": true,
                    "stratusFFTimer": "04:00:00",
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": true
                },
                "assistantEditor": "Sawant, Swati Shivram",
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "00:00:59",
                "deviceTimerOption": null,
                "comments": "dfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfjdfjlksadjflksadjf lksadffffffffffffffffffffffffffffffsdklfjsdlkfjlsdkjflksdjflsd jflksdjflksdjflksdjflksdjflksjflksdjflksdjflksdjflkdjflkjsdlkfjsdlkfjsdlkfjsd fjsdlkfjsdlkfjsdlkfjlsdkfjsldkjflkdjflksdjf sdflksdfjsdlkfjsdlkf jsdlkfj sldkfj lsdfjlksd fjlksdjf sdf sd   dsf sdlkfjsdlf jsdlkfj lsdkfjlksd jflksdj flksdjflk sdjflksd jflksdf slkfjlsdjflksdjflkjsdlkfjsdlkfjsdlkfjsdlkfjslkdfjsd fsdjflksjdlkfjsdlkfjsldkfjlksdjflksdjflksdjflkjsdlkfjsdlkfjsdlkfjslfjsldkfjskldfjlksdfj",
                "additionalRecipients": [
                    "Mohit.Bambani@nbcuni.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264732",
                "fileIngestRequestID": null,
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [
                    {
                        "comments": "notes from the fulfiller side 1",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718024502,
                        "state": null,
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "nots from the fulfiller side 2.2",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718024517,
                        "state": null,
                        "ssoId": "206469507"
                    }
                ],
                "revisionLog": [
                    {
                        "comments": "Changed General Details Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718624011,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed General Details Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623988,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed General Details Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623538,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed General Details Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623483,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623372,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623335,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623279,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718623142,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718357311,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed Fulfillment Section, Changed Fulfiller Notes Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718348019,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed CNBC Stratus Section, Changed Content Description Section, Changed Comments Section, Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718275812,
                        "state": "NEW",
                        "ssoId": "206469507"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718089788,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718089768,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718088628,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Comments Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718086830,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Fulfiller Notes Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718024522,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Requester Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718024348,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023799,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023764,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023312,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023228,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Producer Details Section, Changed Additional Recipient(s) Section, Changed Talent Section, Changed Show Information Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023137,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section, Changed Date & Time Needed Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023050,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Source Section, Changed Destinations Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718023003,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed CNBC Avid Section, Changed CNBC Stratus Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022945,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Content Description Section, Changed Comments Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022897,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Comments Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022789,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022627,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022597,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Attachment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022558,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022198,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022170,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022112,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Source Section, Changed Attachment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718022067,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718021981,
                        "state": "NEW",
                        "ssoId": "206791269"
                    },
                    {
                        "comments": "Changed Producer Details Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718010446,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718005488,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718005426,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed CNBC Stratus Section, Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718004625,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Bambani, Mohit",
                        "revisedDate": 1718003814,
                        "state": "NEW",
                        "ssoId": "206815575"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Maridoss, Kishor",
                        "revisedDate": 1718624101,
                        "state": "NEW",
                        "ssoId": "206469507"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1718003174,
                "createdBy": "Bambani, Mohit",
                "modified": 1718624101,
                "modifiedBy": "Maridoss, Kishor",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Davis, Alandre ",
                "id": "MTD2024001171",
                "creationdate": 1717612778,
                "modificationdate": 1717612778,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "mtdRequestID": "MTD2024001171",
            "content": {
                "showunit": [
                    {
                        "id": 115128,
                        "showunittitle": "CNBC - Clip Desk",
                        "budgetCode": "T-EDT22CLD.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "ERG Events",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Davis, Alandre",
                "requestorContact": "",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": "Here is a paragraph of text that is absolutely about nothing particular, just words and words about a topic that nobody know anything about.",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Canon 5D Mark II"
                        ]
                    }
                ],
                "gigSize": "",
                "deviceStatus": null,
                "instructions": null,
                "reqDestinations": [
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 1,
                        "destDetails": "test"
                    }
                ],
                "ffDestinations": [],
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Heger, Maya",
                "loggedInSSO": "206732097",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717612779,
                        "state": "NEW",
                        "ssoId": "206721012"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1717612778,
                "createdBy": "Davis, Alandre",
                "modified": 1717612778,
                "modifiedBy": null,
                "requestComplete": true,
                "mtdRequestID": "MTD2024001171",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Jude, George Rabin null",
                "id": "FI2024264688",
                "creationdate": 1717594140,
                "modificationdate": 1717594547,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264688",
            "content": {
                "requesters": [
                    {
                        "displayName": "Jude, George Rabin",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Front End Developer",
                        "ssoId": "206727808",
                        "emailId": "georgerabin.jude@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Jude, George Rabin",
                "requestorContact": null,
                "requestorEmail": "georgerabin.jude@nbcuni.com",
                "requestorKey": "georgerabin.jude@nbcuni.com",
                "producerName": "Jude, George Rabin",
                "producerKey": "georgerabin.jude@nbcuni.com",
                "seniorProducer": "Davis, Alandre",
                "seniorProducerKey": "alandre.Davis@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "ERG Events",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    }
                ],
                "slug": "asdasd",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": null,
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "dateTimeNeededBy": 1717613940,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon 5D Mark II",
                            "Canon 5D Mark III",
                            "Canon C300 Mark I",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Compact Flash",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon C300 Mark I",
                            "Canon C300 Mark II",
                            "Canon R6",
                            "Canon XF205(DJ Camera)"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Cfast Express",
                        "deviceTypeOther": null,
                        "quantity": "4",
                        "cameraType": [
                            "Sony F3",
                            "Sony FS7",
                            "Sony F5",
                            "Sony F55"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "CNBC - Stratus"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "",
                    "avidProjectName": "",
                    "avidBinName": null,
                    "avidBinNames": [
                        null
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "Long GOP",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "24p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "test1",
                        "test2",
                        "test3",
                        "test4"
                    ],
                    "stratusMatchingTime": true,
                    "stratusMergeClip": false
                },
                "contentDescription": "content description",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [
                    {
                        "id": 1717594140424,
                        "name": "need_access.txt",
                        "url": "https://qaapi.producerdashboard.nbcuni.com/fileingestrequest/attachment/FI2024264688/1717594140424-need_access.txt",
                        "fileType": "text/plain"
                    }
                ],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "RMI"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "CNBC - Stratus"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".mov"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "DCIM"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "bin name",
                "mediaId": null,
                "ffCnbcAvid": {
                    "avidFFWorkspace": null,
                    "avidFFProjectName": null,
                    "avidFFBinName": [],
                    "avidFFTimer": null,
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": false
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": false,
                    "stratusFFTranscodeComplete": false,
                    "stratusFFTimer": null,
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": false
                },
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": "",
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264688",
                "fileIngestRequestID": null,
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Jude, George Rabin",
                "loggedInSSO": "206727808",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717708188,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Show Information Section, Changed Source Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717604535,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717596340,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717595479,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717594589,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Source Section, Changed CNBC Avid Section, Changed CNBC Stratus Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717997065,
                        "state": "NEW",
                        "ssoId": "206727808"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1717594140,
                "createdBy": "Jude, George Rabin",
                "modified": 1717997065,
                "modifiedBy": "Jude, George Rabin",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Jude, George Rabin null",
                "id": "FI2024264640",
                "creationdate": 1717580614,
                "modificationdate": 1717580671,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "fileIngestID": "FI2024264640",
            "content": {
                "requesters": [
                    {
                        "displayName": "Jude, George Rabin",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Front End Developer",
                        "ssoId": "206727808",
                        "emailId": "georgerabin.jude@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Jude, George Rabin",
                "requestorContact": null,
                "requestorEmail": null,
                "requestorKey": null,
                "producerName": "Jude, George Rabin",
                "producerKey": "georgerabin.jude@nbcuni.com",
                "seniorProducer": "Davis, Alandre",
                "seniorProducerKey": "alandre.Davis@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115121,
                        "showunittitle": "CNBC - Closing Bell Overtime (CBOT)",
                        "budgetCode": "T-EDT22CBO.J047"
                    }
                ],
                "slug": "slug",
                "storyName": "",
                "storySlugID": "",
                "fiAirDate": null,
                "airDate": 1719687256,
                "airDateTBD": "No",
                "airTime": "11:55 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": "2024-07-31",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "11:55 PM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1722470100,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast 2",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            null
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "source comments",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "Micro SD",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Sony F3",
                            "Sony FS7"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "CNBC - Stratus",
                    "CNBC - AVID"
                ],
                "transcodeNeeded": "",
                "nasDestination": "",
                "details": "",
                "nimbusTranscodeNeeded": "",
                "nimbusDetails": "",
                "isMaterialArchived": "",
                "materialWorkgroup": null,
                "cnbcAvid": {
                    "avidWorkspace": "Digital Projects",
                    "avidProjectName": "avid project name",
                    "avidBinName": null,
                    "avidBinNames": [
                        "avid bin name"
                    ]
                },
                "cnbcStratus": {
                    "stratusSrcCompression": "H.265",
                    "stratusSrcCompressionOther": null,
                    "stratusSrcFrameRate": "59.94p",
                    "stratusSrcFrameRateOther": null,
                    "stratusSlug": [
                        "cnbc slug 1",
                        "cnbc slug 2"
                    ],
                    "stratusMatchingTime": true,
                    "stratusMergeClip": false
                },
                "contentDescription": "content desc",
                "fiComments": "comments",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": null,
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": [
                    "AMA"
                ],
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": [
                    "CNBC - Stratus",
                    "CNBC - AVID"
                ],
                "fileFormat": null,
                "fulfillmentFileFormat": [
                    ".jpeg"
                ],
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": [
                    "CONTENTS"
                ],
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": "csda",
                "mediaId": null,
                "ffCnbcAvid": {
                    "avidFFWorkspace": null,
                    "avidFFProjectName": null,
                    "avidFFBinName": [],
                    "avidFFTimer": null,
                    "avidFFTimerStatus": null,
                    "avidFFIngestComplete": false
                },
                "ffCnbcStratus": {
                    "stratusFFTranscodeNeeded": false,
                    "stratusFFTranscodeComplete": false,
                    "stratusFFTimer": null,
                    "stratusFFTimerStatus": null,
                    "stratusFFIngestComplete": true
                },
                "assistantEditor": null,
                "deviceStatus": "",
                "deviceDate": null,
                "deviceTimer": "00:00:00",
                "deviceTimerOption": "",
                "comments": null,
                "additionalRecipients": null,
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024264640",
                "fileIngestRequestID": "FI2024264640",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Jude, George Rabin",
                "loggedInSSO": "206727808",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718193407,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718191464,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718191400,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Source Section, Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718190652,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Date & Time Needed Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717614450,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717614181,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Show Information Section, Changed Date & Time Needed Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717613727,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717613668,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Source Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717612984,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Source Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717612898,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section, Changed CNBC Avid Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717584078,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717583701,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Destinations Section, Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717583634,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1717580672,
                        "state": "NEW",
                        "ssoId": "206727808"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Jude, George Rabin",
                        "revisedDate": 1718193811,
                        "state": "NEW",
                        "ssoId": "206727808"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1717580614,
                "createdBy": "Jude, George Rabin",
                "modified": 1718193811,
                "modifiedBy": "Jude, George Rabin",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024001667",
                "creationdate": 1717524513,
                "modificationdate": 1717524513,
                "targetURL": null,
                "storyID": 1717511857207,
                "description": ""
            },
            "feedOutRequestID": "FO2024001667",
            "content": {
                "showunit": [
                    {
                        "id": 115128,
                        "showunittitle": "CNBC - Clip Desk",
                        "budgetCode": "T-EDT22CLD.J047"
                    }
                ],
                "slug": "test",
                "storyName": "Test_Merge_Story_0604_12",
                "storySlugID": "1717511857207",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Entertainment",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "Test@Test3, Test@Test1",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 1,
                        "deviceType": "CNBC - Stratus",
                        "comments": "comments",
                        "cnbcStratusSlug": [
                            "test",
                            "test 2",
                            "test 3"
                        ]
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    "CNBC - Europe",
                    "CNBC - San Francisco",
                    "TV-18 - India",
                    "News Channel Charlotte",
                    "CNBC - Washington D.C.",
                    "CNBC - Asia",
                    "CNBC - Burbank",
                    "WG08",
                    "WG02"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": [
                    "test"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "5:00 AM",
                "mediaID": "",
                "ffDestinations": [
                    "CNBC - Burbank",
                    "30 Rock"
                ],
                "deviceStatus": "",
                "deviceTimerOption": null,
                "clipCount": 3,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Davis, Alandre",
                "loggedInSSO": "206721012",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Feed Out Request Sources Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717583155,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Additional Recipient Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717582971,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Producer Details Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717582856,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Producer Details Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717582605,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Producer Details Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717582456,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Producer Details Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717582201,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Feed Out Request Sources Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717582014,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717581480,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Feed Out Request Sources Section, Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717537051,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717524514,
                        "state": "NEW",
                        "ssoId": "206721012"
                    },
                    {
                        "comments": "Changed Destinations Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717591168,
                        "state": "NEW",
                        "ssoId": null
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1717524513,
                "createdBy": "Davis, Alandre",
                "modified": 1717591168,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "feedOutRequestID": "FO2024001667",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1717510080,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Ferrell, Patrick ",
                "id": "MTD2024001162",
                "creationdate": 1717517647,
                "modificationdate": 1717517647,
                "targetURL": null,
                "storyID": 1717511611844,
                "description": ""
            },
            "mtdRequestID": "MTD2024001162",
            "content": {
                "showunit": [
                    {
                        "id": 1681717132161,
                        "showunittitle": "test",
                        "budgetCode": "test1"
                    }
                ],
                "slug": "test",
                "storyName": "test  ",
                "storySlugID": "1717511611844",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "NBC News",
                "requesters": [
                    {
                        "displayName": "Ferrell, Patrick",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Staff UX / UI Designer",
                        "ssoId": "206562436",
                        "emailId": "jive@localhost",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Ferrell, Patrick",
                "requestorContact": "",
                "requestorKey": "jive@localhost",
                "requestorEmail": "jive@localhost",
                "producerName": "Ferrell, Patrick",
                "producerKey": "jive@localhost",
                "seniorProducer": "Heger, Maya",
                "seniorProducerKey": "Maya.Heger@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG01",
                        "sourceDetails": null,
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Canon - Other"
                        ]
                    }
                ],
                "gigSize": "",
                "deviceStatus": null,
                "instructions": null,
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": null
                    }
                ],
                "ffDestinations": [
                    {
                        "destination": "Hard Drive",
                        "quantity": 0,
                        "destDetails": null
                    }
                ],
                "ffFileFormat": [
                    ".heic"
                ],
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Ferrell, Patrick",
                        "revisedDate": 1717517647,
                        "state": "NEW",
                        "ssoId": "206562436"
                    },
                    {
                        "comments": "Changed Fulfillment Section",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1718260268,
                        "state": "NEW",
                        "ssoId": "206791269"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1717517647,
                "createdBy": "Ferrell, Patrick",
                "modified": 1718260268,
                "modifiedBy": "Vasan, Maru",
                "requestComplete": true,
                "mtdRequestID": "MTD2024001162",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Davis, Alandre null",
                "id": "FO2024001630",
                "creationdate": 1717497163,
                "modificationdate": 1717497163,
                "targetURL": null,
                "storyID": 0,
                "description": ""
            },
            "feedOutRequestID": "FO2024001630",
            "content": {
                "showunit": [
                    {
                        "id": 115133,
                        "showunittitle": "CNBC - Events/Conferences",
                        "budgetCode": "T-EDT22EVE.J047"
                    },
                    {
                        "id": 115138,
                        "showunittitle": "CNBC - Telemundo",
                        "budgetCode": "T-EDT22TEL.J047"
                    }
                ],
                "slug": "test",
                "storyName": "",
                "storySlugID": "",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Davis, Alandre",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Software Engineer",
                        "ssoId": "206721012",
                        "emailId": "alandre.Davis@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Davis, Alandre",
                "requestorName": "Davis, Alandre",
                "requestorContact": null,
                "requestorEmail": "alandre.Davis@nbcuni.com",
                "requestorKey": "alandre.Davis@nbcuni.com",
                "producerName": "Davis, Alandre",
                "producerKey": "alandre.Davis@nbcuni.com",
                "seniorProducer": "testing-testing, testing-testing",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": null,
                "dateNeededByASAPBin": 1,
                "timeNeededBy": null,
                "timeNeededByFlexibleBin": 1,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "contentDescription": "test",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "5:00 AM",
                "mediaID": "",
                "ffDestinations": [
                    "CNBC - Burbank",
                    "CNBC - London"
                ],
                "deviceStatus": "",
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "Changed Feed Out Request Sources Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717502122,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Feed Out Request Sources Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717502108,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717498418,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717498403,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717498389,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717498375,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717498355,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section, Changed Fulfillment Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717498329,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717497163,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717503242,
                        "state": "NEW",
                        "ssoId": null
                    },
                    {
                        "comments": "Changed Show Information Section",
                        "revisedBy": "Davis, Alandre",
                        "revisedDate": 1717522715,
                        "state": "NEW",
                        "ssoId": null
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1717497163,
                "createdBy": "Davis, Alandre",
                "modified": 1717522715,
                "modifiedBy": "Davis, Alandre",
                "requestComplete": true,
                "feedOutRequestID": "FO2024001630",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1717482720,
                "ecmRequestId": null
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Vasan, Maru null",
                "id": "FO2024001621",
                "creationdate": 1717496559,
                "modificationdate": 1717496701,
                "targetURL": null,
                "storyID": 1715245374980,
                "description": ""
            },
            "feedOutRequestID": "FO2024001621",
            "content": {
                "showunit": [
                    {
                        "id": 115126,
                        "showunittitle": "CNBC - Biz Day - Projects",
                        "budgetCode": "T-EDT22BZP.J047"
                    }
                ],
                "slug": "sl",
                "storyName": "slack-story test 1",
                "storySlugID": "1715245374980",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "",
                "airTimeFlexible": "No",
                "division": "CNBC",
                "requesters": [
                    {
                        "displayName": "Vasan, Maru",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "",
                        "ssoId": "206791269",
                        "emailId": "jive@localhost",
                        "workPhone": ""
                    }
                ],
                "title": "Vasan, Maru",
                "requestorName": "Vasan, Maru",
                "requestorContact": null,
                "requestorEmail": "jive@localhost",
                "requestorKey": "jive@localhost",
                "producerName": "Vasan, Maru",
                "producerKey": "jive@localhost",
                "seniorProducer": "Jude, George Rabin",
                "seniorProducerKey": "jive@localhost",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-06-04",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "12:00 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01"
                ],
                "contentDescription": "ad",
                "generalComments": "",
                "additionalRecipients": null,
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Davis, Alandre",
                "loggedInSSO": "206721012",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Vasan, Maru",
                        "revisedDate": 1717496701,
                        "state": "NEW",
                        "ssoId": null
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1717496559,
                "createdBy": "Vasan, Maru",
                "modified": 1717496701,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024001621",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1717459200,
                "ecmRequestId": null
            }
        }
    ]
}