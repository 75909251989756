<div #guestBookingHeader>
   <div class="metrics-container">
      <ng-container
         *ngFor="let metric of metrics"
         class="">
         <div
            [ngClass]="{
               'metric-selected': metric.isSelected,
               'metric-unselected': !metric.isSelected,
               'metric-item': true,
            }"
            (click)="onMetricClick(metric)">
            <span
               [ngClass]="{
                  'title-selected': metric.isSelected,
                  'title-unselected': !metric.isSelected,
                  'title': true,
               }">
               {{ metric.text }}
            </span>
            <span
               [ngClass]="{
                  'count-selected': metric.isSelected,
                  'count-unselected': !metric.isSelected,
                  'count': true,
               }">
               {{ metric.count }}
            </span>
         </div>
      </ng-container>
   </div>
</div>

<!-- {{ relatedContentHeight }} - {{ tabBody?.top }} - {{ tabBodyHeight }} -->

<div #guestBookingTable>
   <!-- Related Content Height - {{ relatedContentHeight }} -- Table Height - {{ tableHeight }} --- Related Content Top -
   {{ relatedContentTop }} --- Height to be removed - {{ heightToBeRemoved }} -->

   <!-- <div
      style="background-color: green"
      [style.height]="tableHeight + 'px'"></div> -->

   <nz-table
      nzBordered
      class="related-content-table"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr>
            <th
               [nzWidth]="preferenceColumns['DATE'].width + 'px'"
               [nzLeft]="true"
               [rowspan]="preferenceColumns['DATE'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="dateSortOrder"
               (nzSortOrderChange)="guestBookingsDateSortEvent($event)">
               Date
            </th>

            <th
               [nzWidth]="preferenceColumns['TIME'].width + 'px'"
               [rowspan]="preferenceColumns['TIME'].rowSpan">
               Time
            </th>

            <th
               [nzWidth]="preferenceColumns['APP'].width + 'px'"
               [rowspan]="preferenceColumns['APP'].rowSpan"
               [nzFilters]="appFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="appFilterEvent($event)">
               App
            </th>

            <th
               [nzWidth]="preferenceColumns['GUEST'].width + 'px'"
               nzCustomFilter>
               Guest
               <nz-filter-trigger
                  [(nzVisible)]="guestSearchVisible"
                  [nzActive]="guestSearchText.length > 0"
                  [nzDropdownMenu]="guestTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOW'].width + 'px'"
               nzCustomFilter>
               Show
               <nz-filter-trigger
                  [(nzVisible)]="showSearchVisible"
                  [nzActive]="showSearchText.length > 0"
                  [nzDropdownMenu]="showTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SEGMENT'].width + 'px'"
               nzCustomFilter>
               Segment
               <nz-filter-trigger
                  [(nzVisible)]="segmentSearchVisible"
                  [nzActive]="segmentSearchText.length > 0"
                  [nzDropdownMenu]="segmentTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>
         </tr>
      </thead>
      <tbody>
         <ng-container *ngFor="let data of relatedContentData; let i = index">
            <tr>
               <td>{{ data.content.requestCreated | date: 'MM/dd/yyyy' }}</td>
               <td>
                  <a
                     [href]=""
                     class="text-selected">
                     {{ data.content.requestCreated | date: 'hh:mm a' }}
                  </a>
               </td>
               <td>
                  <div class="appearances">
                     <div>
                        <span
                           class="reload"
                           *ngIf="data.content.appearance?.includes('recurring')"
                           nz-icon
                           nzType="reload"
                           nzTheme="outline"></span>
                     </div>
                     <div>
                        <span
                           *ngIf="data.content.appearance?.includes('cancelled')"
                           class="circle"
                           nz-icon
                           nzType="close-circle"
                           nzTheme="fill"></span>
                     </div>
                  </div>
               </td>
               <td>{{ data.content.zRequestersDisplayString }}</td>
               <td>{{ data.content.zShowUnitsDisplayString }}</td>
               <td>{{ data.content.zShowUnitsDisplayString }}</td>
            </tr>
         </ng-container>
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #guestTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Guest(s)"
            (keyup.enter)="searchGuest()"
            [(ngModel)]="guestSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchGuest()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetGuestSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show(s)"
            (keyup.enter)="searchShow()"
            [(ngModel)]="showSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchShow()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetShowSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #segmentTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Segment(s)"
            (keyup.enter)="searchSegment()"
            [(ngModel)]="segmentSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchSegment()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetSegmentSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>
