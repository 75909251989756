import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoryContentSearchResult } from '@models/ncx/global-search';
import { LightboxAttachment } from '@models/ncx/lightbox';
import { PostAttachment } from '@models/ncx/post';
import { BreakpointService } from '@services/breakpoint.service';
import { NavigationService } from '@services/navigation-service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';

@Component({
  selector: 'ncx-element-search-new',
  templateUrl: './element-search.component.html',
  styleUrls: ['../../cards-common.scss', './element-search.component.scss']
})
export class ElementSearchNewComponent {

  // Story/Post information
  @Input() post: StoryContentSearchResult = {} as StoryContentSearchResult;

  @Output() sharePost: EventEmitter<StoryContentSearchResult> = new EventEmitter<StoryContentSearchResult>();

  @Output() viewPost: EventEmitter<StoryContentSearchResult> = new EventEmitter<StoryContentSearchResult>();

  @Output() viewStory: EventEmitter<StoryContentSearchResult> = new EventEmitter<StoryContentSearchResult>();

  public readonly Common = Common;

  editorials: string[] = [];

  legals: string[] = [];

  additionalEditorialsOrLegals: string = '';

  attachments: any[] = [];

  remainingAttachments: number = 0;

  lightBoxAttachments: LightboxAttachment[] = [];

  lightBoxAttachment: LightboxAttachment = {} as LightboxAttachment;

  isSmall: boolean = false;

  // All extensions that make up images or video
  imgExtn = /jpe?g|jfif|png|bmp/;

  videoExtn = /mov|mp4|3gp|m4v|mkv|webm/;

  thumpVideoExtn = /mov|mp4|3gp|m4v|mkv|webm|flv|avi|mpeg|wmv|asf/;

  fileExtn = /pdf|doc|docx|txt|rtf/;

  constructor(
    private breakpointService: BreakpointService,
    private urlRedirectService: UrlRedirectService,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {

    this.editorials = this.post.filters;
    this.legals = this.post.restrictions;

    // this.editorials = this.post.filters.slice(0, 1);
    // this.legals = this.post.filters.length > 0 ? [] : this.post.restrictions.slice(0, 1);

    // let count = this.post.filters.length + this.post.filters.length;

    // if (count > 1) {
    //   this.additionalEditorialsOrLegals = `+${count - 1}`;
    // }

    this.attachments = this.post.attachments;//.slice(0, 1);

    this.remainingAttachments = this.post.attachments.length - 1;

    this.breakpointService.isSmall.subscribe((isSmall) => {
      this.isSmall = isSmall;
    });


  }


  ngAfterViewInit() {

    this.buildAttachments();

    this.lightBoxAttachment = this.lightBoxAttachments[0];

    console.log('View Attachments (ngAfterViewInit)', { lightBoxAttachment: this.lightBoxAttachment });
  }



  onShare() {

    this.sharePost.emit(this.post);

  }

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewPost() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewPost.emit(this.post);
      return true;

    } else {

      this.viewPost.emit(this.post);
      window.open(this.urlRedirectService.getRedirectURL(this.post.targetURL), '_blank');
      return false;

    }

  }

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewStory() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewStory.emit(this.post);
      return true;

    } else {

      this.viewStory.emit(this.post);
      window.open(this.urlRedirectService.getRedirectURL(this.post.storyURL), '_blank');
      return false;

    }

  }

  get date(): string {

    const { updatedDateTime, createDateTime } = this.post;

    const created = new Date(createDateTime);

    const updated = updatedDateTime ? new Date(updatedDateTime) : null;

    if (updated && created < updated) {

      return `updated on ${Time.convertingUtcToLocalDate(updatedDateTime)} at ${Time.convertingUtcToLocalTime(updatedDateTime)} `;

    } else {

      return `posted on ${Time.convertingUtcToLocalDate(createDateTime)} at ${Time.convertingUtcToLocalTime(createDateTime)} `;

    }

  }


  calculateDays(apiDate) {

    return Time.calculateDaysBetweenGivenUtcAndToday(apiDate)

  }

  buildAttachments() {

    if (!Array.isArray(this.post.attachments)) {

      return;

    }

    this.lightBoxAttachments = this.post.attachments.map((attachment: PostAttachment) => {

      const { attachmentId, attachmentName, attachmentPreview } = attachment;

      const extension = String(attachment.attachmentPath).split('.').pop().toLowerCase();

      const isImage = !!extension.match(this.imgExtn);

      const isVideo = !!extension.match(this.videoExtn);

      const isFile = !!extension.match(this.fileExtn);

      const isthumpVideo = !!extension.match(this.thumpVideoExtn);

      const lightboxAttachment: LightboxAttachment = {

        // Id of attachment
        attachmentID: attachmentId,

        // Name of full-size version of file
        attachmentName,

        // Name of preview-size version of file
        attachmentPreviewName: attachmentPreview ? attachmentPreview : '',

        // URL of full-size version of file
        fileUrl: this.imageURL(attachment),

        // URL of preview-size version of file
        previewUrl: this.imageURL(attachment, 'preview'),

        thumpnail: this.imageThumpnailURL(attachment, 'thumpnail'),

        // File extension
        extension,

        // Is this an image?
        isImage,

        // Is this a video?
        isVideo,

        isFile,

        isthumpVideo

      };

      return lightboxAttachment;

    });

    // console.log('View Attachments (buildAttachments)', { album: this.attachments, attachments: this.viewAttachments });

  }


  /**
   * Image Url
   *
   */
  imageURL(post: PostAttachment, type: string = 'full'): string {

    let url = type === 'preview' && post.attachmentPreview && post.attachmentPreviewPath ? this.urlRedirectService.getRedirectAPIURL(post.attachmentPreviewPath) : this.urlRedirectService.getRedirectAPIURL(post.attachmentPath);

    if (url && !url.includes('?ncxjwttoken=')) {

      url = `${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

    }
    url = `${url}&attachmentName=${post.attachmentName}`
    return url;

  }

  imageThumpnailURL(post: PostAttachment, type: string = 'full'): string {

    let url = type === 'thumpnail' && post.attachmentPreviewPath ? post.attachmentPreviewPath : '';

    if (url && !url.includes('?ncxjwttoken=')) {

      url = this.urlRedirectService.getRedirectAPIURL(`${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`);

    }
    return url;

  }

}
