import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { Filters, Option } from '@models/ncx/filters';
import { SearchType } from '@models/ncx/global-search';
import { Profile, UserSettings } from '@models/users';
import { CommonService } from '@services/common-service';
import { GlobalSearchService } from '@services/global-search.service';
import { UserSettingService } from '@services/profile/usersetting.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface ProfileOption {
  name: string;
  sso: number;
}

@Component({
  selector: 'app-post-filters-new',
  templateUrl: './post-filters.component.html',
  styleUrls: ['./post-filters.component.scss']
})
export class PostFiltersNewComponent implements OnInit {

  // Which tab are we on? Posts/Stories/Angles....
  @Input() filterState: SearchType | void = undefined;

  @Input() isDrawer: boolean = false;

  @Input() isCollapsed: boolean = false;

  @Output() closeFilter: EventEmitter<void> = new EventEmitter<void>();

  @Output() triggerFilter: EventEmitter<void> = new EventEmitter<void>();

  isLoading: boolean = false;

  // Filter Options
  filterList: Filters = {} as Filters;

  // User Settings
  userSettings: UserSettings = {} as UserSettings;

  // Profile/Author search debounce and options
  profileSearch: Subject<string> = new Subject();

  profileOptions: ProfileOption[] = [];

  // Listens for changes to filter changes for debouncing
  filterChange = new Subject();

  // Special Angle sort options
  angleSortOptions = [

    // {
    //   label: 'Relevant',
    //   value: 'relevancescore'
    // },
    {
      label: 'Newest First',
      value: 'descending'
    },
    {
      label: 'Oldest First',
      value: 'ascending'
    }
  ];

  //Angle Privacy Order
  anglePrivacyOrder = [
    {
      'label': 'All',
      'value': 'all'
    },
    {
      'label': 'Public',
      'value': 'public'
    },
    {
      'label': 'Private',
      'value': 'private'
    }
  ];


  profileLoaded: boolean = false;

  constructor(
    private cService: CommonService,
    public searchService: GlobalSearchService,
    public userInfo: TransferInformationComponent,
    private userSettingService: UserSettingService,
    private toastService: ToastService
  ) {

    // Debounce when filters change, and broadcast the change to all sibling
    // components: Angles, Posts, etc... using the search service
    this.filterChange.pipe(debounceTime(750)).subscribe(() => {

      this.searchService.filterChanged.next();

    });

    // Debounce when searching for Profiles/Authors
    this.profileSearch.pipe(debounceTime(500)).subscribe(searchTextValue => {

      this.fetchProfiles(searchTextValue);

    });




  }

  async ngOnInit() {

    // 1. Load filters
    await this.initFilters();

    // 2. Load user settings
    await this.initUserSettings();

    // 3. Save original filters
    this.searchService.saveOriginalFilters();

  }

  /**
   * Load all Filter options
   *
   */
  async initFilters() {

    try {

      this.isLoading = true;

      const response: any = await this.cService.serviceRequestCommon('get', environment.getPostFiltersURL).toPromise();

      const filters: Filters = response || {} as Filters;

      this.filterList = filters;

      this.searchService.filterList = JSON.parse(JSON.stringify(filters));

      // Pre-populate necessary filters
      this.searchService.filters.POSTS.legal = JSON.parse(JSON.stringify(filters.statusType));
      this.searchService.filters.POSTS.editorial = JSON.parse(JSON.stringify(filters.markAsType));

      console.log('initFilters', filters);

    } catch (error: any) {

      console.error('initFilters', error);

      this.toastService.createMessage('error', 'Unable to load Filter options');

    } finally {

      this.isLoading = false;

    }

  }

  /**
   * Get user settings that can change the default filters
   *
   */
  async initUserSettings() {

    try {

      this.isLoading = true;

      const url = environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings';

      const response: any = await this.cService.serviceRequestCommon('get', url).toPromise();

      const settings: UserSettings = response || {} as UserSettings;

      console.log('initUserSettings', settings);

      if (settings.searchRelevancy) {

        this.searchService.filters.POSTS.sort = 'relevancescore';

        //  this.searchService.filters.ANGLES.sort = 'relevancescore';

      }

    } catch (error: any) {

      console.error('initUserSettings', error);

    } finally {

      this.isLoading = false;

    }

  }

  /**
   * Whenever any filter changes, run it through the
   * debounce logic
   *
   */
  onFilterChanged() {

    this.filterChange.next('');

  }

  /**
   * Search Authors
   *
   */
  onSearchAuthor(event: any) {

    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {

      const searchString = (event.target.value || '').trim();

      if (searchString) {

        this.profileSearch.next(searchString);

      } else {

        this.profileOptions = [];

      }

    }

  }

  /**
   * When an Author is selected
   *
   */
  onSelectAuthor(value: string, type: 'POSTS' | 'ANGLES') {

    const matchingUser = this.profileOptions.filter((profile: ProfileOption) => profile.name === value);

    if (!matchingUser.length) {

      return;

    }

    const selectedUser = matchingUser[0];

    if (type === 'ANGLES') {

      this.searchService.filters.ANGLES.authorSSO = selectedUser.sso;

    }

    if (type === 'POSTS') {

      this.searchService.filters.POSTS.authorSSO = selectedUser.sso;
      this.searchService.filters.POSTS.authorName = selectedUser.name;

    }

    this.profileOptions = [];

    this.onFilterChanged();

  }

  /**
   * When the Author name is cleared
   *
   */
  onClearAuthor() {

    switch (this.filterState) {

      case 'ANGLES':
        this.searchService.filters.ANGLES.authorSSO = 0;
        this.searchService.filters.ANGLES.authorName = '';
        break;

      case 'POSTS':
        this.searchService.filters.POSTS.authorSSO = 0;
        this.searchService.filters.POSTS.authorName = '';
        break;

    }

    this.profileOptions = [];

    this.onFilterChanged();

  }

  /**
   * More logic when either "All" or another option
   * is checked under Media Element options for Posts
   *
   */
  selectMediaOption(isAll: boolean, isChecked: boolean) {

    // If 'All' is checked
    if (isAll) {

      this.searchService.filters.POSTS.media.forEach((opt: Option) => {

        opt.checked = isChecked;

      });

    } else {

      // De-select 'All' if the user has selected other options
      const hasSelections = this.searchService.filters.POSTS.media.filter((opt: Option) => opt.checked).length > 0;

      if (hasSelections) {

        this.searchService.filters.POSTS.isAllMediaChecked = false;

      }

    }

    this.onFilterChanged();

  }

  /**
   * Fetch list of Profiles to select as Authors
   *
   */
  fetchProfiles(searchTextValue: string) {

    this.profileOptions = [];

    this.isLoading = true;
    this.profileLoaded = false;
    this.cService.serviceRequestCommon('get', environment.getUsersOnDutyURL, `?name=${searchTextValue}&isActiveUsers=false`).subscribe((res: any) => {

      const profiles: Profile[] = res as Profile[];

      this.profileOptions = res.length ? this.buildProfileList(profiles) : [];
      this.profileLoaded = true;

    }, (err) => {

      console.error('fetchProfiles', err);

    }).add(() => {

      this.isLoading = false;

    });

  }

  /**
   * Build list of Profiles to show
   *
   */
  buildProfileList(profiles: Profile[]): ProfileOption[] {

    const profileList: ProfileOption[] = [];

    profiles.forEach((user: any) => {

      let firstName = '';

      let lastName = '';

      if (!(user.name.preferredName === undefined || user.name.preferredName === null)) {

        firstName = (user.name.preferredName.split(',').length > 1)
          ? user.name.preferredName.split(',')[1].trim()
          : user.name.preferredName.trim();
        lastName = (user.name.preferredName.split(',').length > 1) ? user.name.preferredName.split(',')[0].trim() : '';

      }
      profileList.push({
        name: `${firstName} ${lastName}`,
        sso: +(user.ssoId || 0)
      });

    });

    return profileList;

  }

  /**
   * Clear SPECIFIC filters
   *
   */
  clearFilters() {

    this.searchService.clearFilters(this.filterState);

    this.searchService.filters.POSTS.legal = JSON.parse(JSON.stringify(this.filterList.statusType));
    this.searchService.filters.POSTS.editorial = JSON.parse(JSON.stringify(this.filterList.markAsType));

    this.onFilterChanged();

  }

  close() {

    this.closeFilter.emit();

  }

  onTopicSelected(topics: any[]) {

    this.searchService.filters.POSTS.topics = topics;

    this.onFilterChanged();

  }

  onTagSelected(tags: any[]) {

    this.searchService.filters.POSTS.tags = tags;

    this.onFilterChanged();

  }

  onIncludeExcludeChanged() {
    this.onFilterChanged();
  }


  includeExcludeElements(event: any) {

    this.onFilterChanged();

  }

  includeExcludeDiscussions(event: any) {

    this.onFilterChanged();

  }


  includeExcludePublishedOrAired(event: any) {

    this.onFilterChanged();

  }

  dateChanged(event) {

    this.searchService.filters.POSTS.modificationDate = event;

    this.onFilterChanged();

  }

  dateOptionChanged(event) {

    const today = new Date();

    let startDate = new Date();

    if (event === 'alltime') {
      this.searchService.filters.POSTS.modificationDate = [];
    }
    else {
      if (event === 'today') {

        startDate = this.subtractDaysFromToday(0);
      }
      else if (event === 'past2days') {

        startDate = this.subtractDaysFromToday(1);
      }
      else if (event === 'past3days') {

        startDate = this.subtractDaysFromToday(2);
      }
      else if (event === 'pastweek') {

        startDate = this.subtractDaysFromToday(7);
      }

      this.searchService.filters.POSTS.modificationDate = [startDate, today];

    }


    this.onFilterChanged();

  }


  subtractDaysFromToday(days) {
    const today = new Date();

    today.setDate(today.getDate() - days);


    return today;
  }


}
