<nz-collapse
   class="margin-space tags-search"
   nzExpandIconPosition="right"
   [nzBordered]="false">
   <nz-collapse-panel
      [nzHeader]="tagsHeader"
      nzActive>
      <nz-input-group
         [nzSuffix]="suffixIconSearch"
         class="input-group">
         <input
            placeholder="Search tags"
            nz-input
            [(ngModel)]="tagSearchInputValue"
            class="search-text"
            (keydown)="onChangeIntagInput(storyInput.value, $event)"
            (ngModelChange)="titleMatch($event)"
            (keyup.enter)="onEnter($event.target.value)"
            [nzAutocomplete]="auto"
            #storyInput />
      </nz-input-group>
      <ng-template #suffixIconSearch>
         <span
            nz-icon
            nzType="search"
            class="search-text"></span>
      </ng-template>
      <nz-autocomplete
         [nzDataSource]="tags"
         (change)="onEnter($event.target.value)"
         #auto></nz-autocomplete>

      <div
         class="show-tags"
         *ngIf="selectedTags.length > 0">
         <ng-container *ngFor="let tag of selectedTags; let i = index">
            <span class="display-tag">
               <span class="tag-text">{{ tag }}</span>
               <span
                  nz-icon
                  nzType="close"
                  nzTheme="outline"
                  (click)="handleClose(tag)"
                  class="tag-close"></span>
            </span>
         </ng-container>
      </div>

      <div class="include-exclude-group">
         <nz-radio-group
            class="full-width-radio-group"
            [(ngModel)]="searchService.filters.STORIES.tagsIncludeExclude"
            (ngModelChange)="onIncludeExcludeChange()"
            [nzButtonStyle]="'solid'">
            <label
               nz-radio-button
               nzValue="include">
               Include
            </label>
            <label
               nz-radio-button
               nzValue="exclude">
               Exclude
            </label>
         </nz-radio-group>
      </div>
   </nz-collapse-panel>
</nz-collapse>

<ng-template #tagsHeader>
   <i
      nz-icon
      nzType="message"></i>
   Tags
</ng-template>
