export const Page1 = {
    "total": 25133,
    "results": [
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002228",
                "creationdate": 1726746679,
                "modificationdate": 1726746679,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "mtdRequestID": "MTD2024002228",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Solai, Saravanan",
                "producerKey": "saravanan.solai@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:20 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727580000,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": null,
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    },
                    {
                        "deviceType": "WG01",
                        "sourceDetails": null,
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": null,
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": null
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 1,
                        "destDetails": null
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 1,
                        "destDetails": null
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726746679,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726746679,
                "createdBy": "Peddina, Sainath",
                "modified": 1726746679,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002228",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124",
                "appearance": ['recurring', 'cancelled']
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266170",
                "creationdate": 1726746644,
                "modificationdate": 1726746645,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "fileIngestID": "FI2024266170",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Solai, Saravanan",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Sr Software Quality Engineer",
                        "ssoId": "206581954",
                        "emailId": "saravanan.solai@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:20 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:20 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727580000,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "10",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Cfast",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test P2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SD",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test SD",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266170",
                "fileIngestRequestID": "FI2024266170",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726746645,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726746644,
                "createdBy": "Peddina, Sainath",
                "modified": 1726746645,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124",
                "appearance": ['recurring']

            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FO2024003263",
                "creationdate": 1726746634,
                "modificationdate": 1726746634,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "feedOutRequestID": "FO2024003263",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:20 PM",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Solai, Saravanan",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Sr Software Quality Engineer",
                        "ssoId": "206581954",
                        "emailId": "saravanan.solai@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 8,
                        "deviceType": "Tapes",
                        "comments": " Test Test Tapes",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:20 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726746634,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1726746634,
                "createdBy": "Peddina, Sainath",
                "modified": 1726746634,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024003263",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1727580000,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124",
                "appearance": ['cancelled']

            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266165",
                "creationdate": 1726746626,
                "modificationdate": 1726746626,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "fileIngestID": "FI2024266165",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Solai, Saravanan",
                "producerKey": "saravanan.solai@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "No",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:20 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727580000,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SD",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266165",
                "fileIngestRequestID": "FI2024266165",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726746626,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726746626,
                "createdBy": "Peddina, Sainath",
                "modified": 1726746626,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124",
                "appearance": []
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FO2024003251",
                "creationdate": 1726746606,
                "modificationdate": 1726746607,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "feedOutRequestID": "FO2024003251",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 1,
                        "deviceType": "CNBC - Stratus",
                        "comments": "",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:19 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "",
                "additionalRecipients": [],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726746607,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1726746606,
                "createdBy": "Peddina, Sainath",
                "modified": 1726746607,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024003251",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1727579940,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124",
                "appearance": []
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002213",
                "creationdate": 1726745919,
                "modificationdate": 1726745919,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "mtdRequestID": "MTD2024002213",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:07 PM",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Solai, Saravanan",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Sr Software Quality Engineer",
                        "ssoId": "206581954",
                        "emailId": "saravanan.solai@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:07 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727579220,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Canon 5D Mark II",
                            "Canon R6"
                        ]
                    },
                    {
                        "deviceType": "WG01",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Canon 5D Mark II",
                            "Canon R6"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 5,
                        "destDetails": "testing Hard Drive"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 10,
                        "destDetails": "testing Stratus"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 15,
                        "destDetails": " testing Bluray"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745919,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745919,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745919,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002213",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002204",
                "creationdate": 1726745892,
                "modificationdate": 1726745892,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "mtdRequestID": "MTD2024002204",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Solai, Saravanan",
                "producerKey": "saravanan.solai@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "No",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:07 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727579220,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": null,
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    },
                    {
                        "deviceType": "WG01",
                        "sourceDetails": null,
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": null,
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": null
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 1,
                        "destDetails": null
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 1,
                        "destDetails": null
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745892,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745892,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745892,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002204",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266150",
                "creationdate": 1726745858,
                "modificationdate": 1726745859,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "fileIngestID": "FI2024266150",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Solai, Saravanan",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Sr Software Quality Engineer",
                        "ssoId": "206581954",
                        "emailId": "saravanan.solai@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:07 PM",
                "airTimeFlexible": "No",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:07 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727579220,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "10",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": "Test Test Cfast",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "5",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test P2",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SD",
                        "deviceTypeOther": null,
                        "quantity": "8",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": " Test Test SD",
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "Testing info Testing info Testing info",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266150",
                "fileIngestRequestID": "FI2024266150",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745859,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745858,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745859,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FO2024003241",
                "creationdate": 1726745843,
                "modificationdate": 1726745843,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "feedOutRequestID": "FO2024003241",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:06 PM",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    },
                    {
                        "displayName": "Solai, Saravanan",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Sr Software Quality Engineer",
                        "ssoId": "206581954",
                        "emailId": "saravanan.solai@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "feedOutSources": [
                    {
                        "quantity": 10,
                        "deviceType": "Tapes",
                        "comments": "Test Test Tapes",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 5,
                        "deviceType": "CNBC - Stratus",
                        "comments": " Test Test CNBC- Stratus",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 8,
                        "deviceType": "Tapes",
                        "comments": " Test Test Tapes",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:06 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "Testing info Testing info Testing info",
                "additionalRecipients": [
                    "sainath@gyk.com",
                    "sainath@msk.com"
                ],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": "Peddina, Sainath",
                "loggedInSSO": "206736124",
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745843,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1726745843,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745843,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024003241",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1727579160,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "FileIngest",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FI2024266142",
                "creationdate": 1726745842,
                "modificationdate": 1726745842,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "fileIngestID": "FI2024266142",
            "content": {
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": null,
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Solai, Saravanan",
                "producerKey": "saravanan.solai@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "No",
                "division": "Engineering",
                "showUnit": null,
                "budgetCode": null,
                "showunits": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "fiAirDate": null,
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "correspondent": null,
                "talent": [],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAP": null,
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:07 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727579220,
                "cameraType": null,
                "cameraTypeOther": null,
                "fileIngestDevice": [
                    {
                        "deviceType": "Cfast",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "P2",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    },
                    {
                        "deviceType": "SD",
                        "deviceTypeOther": null,
                        "quantity": "1",
                        "cameraType": [
                            "Canon R6",
                            "Canon C300"
                        ],
                        "cameraTypeOtherText": null,
                        "sourceComments": null,
                        "webBasedStartTime": null,
                        "webBasedEndTime": null
                    }
                ],
                "workgroup": null,
                "destinations": [
                    "WG01",
                    "WG06"
                ],
                "transcodeNeeded": null,
                "nasDestination": null,
                "details": null,
                "nimbusTranscodeNeeded": null,
                "nimbusDetails": null,
                "isMaterialArchived": null,
                "materialWorkgroup": null,
                "cnbcAvid": null,
                "cnbcStratus": null,
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "fiComments": "",
                "crewRequestID": null,
                "attachEditRequestID": null,
                "attachments": null,
                "fileIngestAttachments": [],
                "crewContactNumber": null,
                "ingestOperator": null,
                "workgroupDC": null,
                "crewShooter": null,
                "authorId": null,
                "editorId": null,
                "selectOne": null,
                "isExternalHardDrive": null,
                "alternateEmails": null,
                "nextToBinName": null,
                "shortComment": null,
                "frameRate": null,
                "others": null,
                "methodOfIngest": null,
                "methodOfIngestOthersText": null,
                "workgroupFulFillSection": null,
                "fulfillmentDestinations": null,
                "fileFormat": null,
                "fulfillmentFileFormat": null,
                "fileFormatOther": null,
                "gigSize": null,
                "folderFormat": null,
                "fulfillmentFolderFormat": null,
                "folderFormatOther": null,
                "clipCount": 0,
                "binName": null,
                "mediaId": null,
                "ffCnbcAvid": null,
                "ffCnbcStratus": null,
                "assistantEditor": null,
                "deviceStatus": null,
                "deviceDate": null,
                "deviceTimer": null,
                "deviceTimerOption": null,
                "comments": null,
                "additionalRecipients": [],
                "requestSubmissionState": null,
                "fIStatus": "NEW",
                "fIStatusIndex": 1,
                "statusIndex": 1,
                "ingestStatus": "FormComplete",
                "fileIngestID": "FI2024266142",
                "fileIngestRequestID": "FI2024266142",
                "deleted": false,
                "sharepointID": 0,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New File Ingest Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745842,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745842,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745842,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "requestType": "File Ingest",
                "deviceReceivedTime": 0,
                "reqFulfilledTime": 0,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "Feed Out",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "FO2024003235",
                "creationdate": 1726745818,
                "modificationdate": 1726745818,
                "targetURL": null,
                "storyID": 56439,
                "description": ""
            },
            "feedOutRequestID": "FO2024003235",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "CNBC",
                "storyName": "CNBC: Hackers Appear to Target Twitter Accounts of Elon Musk, Bill Gates, Others in Digital Currency Scam",
                "storySlugID": "56439",
                "airDate": 0,
                "airDateTBD": "Yes",
                "airTime": "",
                "airTimeFlexible": "Yes",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "title": "Peddina, Sainath",
                "requestorName": "Peddina, Sainath",
                "requestorContact": null,
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [],
                "feedOutSources": [
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    },
                    {
                        "quantity": 1,
                        "deviceType": "CNBC - Stratus",
                        "comments": "",
                        "cnbcStratusSlug": [
                            "Test Slug 1",
                            "Test Slug 2",
                            "Test Slug 3"
                        ]
                    },
                    {
                        "quantity": 1,
                        "deviceType": "Tapes",
                        "comments": "",
                        "cnbcStratusSlug": []
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:06 AM",
                "timeNeededByFlexibleBin": 0,
                "destinations": [
                    "WG01",
                    "WG06",
                    "30 Rock",
                    " Other destinations"
                ],
                "contentDescription": "Testing info Content Testing info Content Testing info",
                "generalComments": "",
                "additionalRecipients": [],
                "attachments": "NA",
                "feedOutAttachments": [],
                "ffComments": "",
                "deviceTimer": "",
                "feedTime": "",
                "mediaID": "",
                "ffDestinations": [],
                "deviceStatus": null,
                "deviceTimerOption": null,
                "clipCount": 0,
                "deviceDate": null,
                "requestSubmissionState": null,
                "feedOutStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New Feedout Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745818,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": [],
                "created": 1726745818,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745818,
                "modifiedBy": null,
                "requestComplete": true,
                "feedOutRequestID": "FO2024003235",
                "requestType": "Feed Out",
                "statusIndex": 1,
                "dateTimeNeededBy": 1727579160,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002191",
                "creationdate": 1726745545,
                "modificationdate": 1726745545,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002191",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "Telemundo",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Solid State Drive",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    },
                    {
                        "deviceType": "Other",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    },
                    {
                        "deviceType": "We Transfer *",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 8,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745545,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745545,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745545,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002191",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002182",
                "creationdate": 1726745544,
                "modificationdate": 1726745544,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002182",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "Universal Kids",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG01",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "CF Express",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "Micro SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745544,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745544,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745544,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002182",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002176",
                "creationdate": 1726745543,
                "modificationdate": 1726745543,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002176",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "Peacock",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Cfast",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    },
                    {
                        "deviceType": "P2",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    },
                    {
                        "deviceType": "SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 1,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 4,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 6,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745543,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745543,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745543,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002176",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002167",
                "creationdate": 1726745541,
                "modificationdate": 1726745541,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002167",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "Olympics",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG02",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    },
                    {
                        "deviceType": "CNBC Dropbox",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    },
                    {
                        "deviceType": "Hard Drive",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony FX3",
                            "Sony FX9V"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 3,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 6,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745541,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745541,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745541,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002167",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002153",
                "creationdate": 1726745540,
                "modificationdate": 1726745540,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002153",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "Operations",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "VHS",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    },
                    {
                        "deviceType": "CD",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": []
                    },
                    {
                        "deviceType": "Media Shuttle",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": []
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 4,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745540,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745540,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745540,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002153",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002142",
                "creationdate": 1726745537,
                "modificationdate": 1726745537,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002142",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "News Digital",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "WG01",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "CF Express",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    },
                    {
                        "deviceType": "Micro SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Sony F3",
                            "Sony F5"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 7,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745537,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745537,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745537,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002142",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002138",
                "creationdate": 1726745533,
                "modificationdate": 1726745533,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002138",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "8:01 PM",
                "airTimeFlexible": "No",
                "division": "NBC Sports",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "3:01 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578860,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Solid State Drive",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    },
                    {
                        "deviceType": "Other",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    },
                    {
                        "deviceType": "We Transfer *",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 8,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745533,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745533,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745533,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002138",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002124",
                "creationdate": 1726745423,
                "modificationdate": 1726745423,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002124",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "Engineering",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Solid State Drive",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    },
                    {
                        "deviceType": "Other",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    },
                    {
                        "deviceType": "We Transfer *",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "GoPro Hero 11",
                            "GoPro Hero 10"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Media Shuttle",
                        "quantity": 2,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Send This File",
                        "quantity": 5,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "CNBC Dropbox",
                        "quantity": 8,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745423,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745423,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745423,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002124",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        },
        {
            "headers": {
                "title": "MTD",
                "source": "prodreq",
                "contact": "Peddina, Sainath",
                "id": "MTD2024002116",
                "creationdate": 1726745421,
                "modificationdate": 1726745421,
                "targetURL": null,
                "storyID": 1726736328126,
                "description": ""
            },
            "mtdRequestID": "MTD2024002116",
            "content": {
                "showunit": [
                    {
                        "id": 115127,
                        "showunittitle": "CNBC - Biz Day - General",
                        "budgetCode": "T-EDT22BZG.J047"
                    },
                    {
                        "id": 105,
                        "showunittitle": "MSNBC - Dayside Weekend",
                        "budgetCode": "J07706125"
                    },
                    {
                        "id": 115140,
                        "showunittitle": "CNBC - News Now",
                        "budgetCode": "T-EDT22NNN.J047"
                    }
                ],
                "slug": "Test",
                "storyName": "working story 2 test delete",
                "storySlugID": "1726736328126",
                "airDate": 0,
                "airDateTBD": "No",
                "airTime": "7:59 PM",
                "airTimeFlexible": "No",
                "division": "NBC News Studios",
                "requesters": [
                    {
                        "displayName": "Peddina, Sainath",
                        "thumbnailUrl": null,
                        "userId": null,
                        "jobTitle": "Quality Assurance",
                        "ssoId": "206736124",
                        "emailId": "sainath.peddina@nbcuni.com",
                        "workPhone": ""
                    }
                ],
                "requestorName": "Peddina, Sainath",
                "requestorContact": "",
                "requestorKey": "sainath.peddina@nbcuni.com",
                "requestorEmail": "sainath.peddina@nbcuni.com",
                "producerName": "Peddina, Sainath",
                "producerKey": "sainath.peddina@nbcuni.com",
                "seniorProducer": "R, Jeevanandham",
                "seniorProducerKey": "jeevanandham.r@nbcuni.com",
                "isRequestorProducer": "Yes",
                "correspondent": null,
                "talent": [
                    {
                        "id": 0,
                        "name": "test/tesr/test/test "
                    }
                ],
                "dateNeededBy": "2024-09-29",
                "dateNeededByASAPBin": 0,
                "timeNeededBy": "2:59 AM",
                "timeNeededByFlexibleBin": 0,
                "dateTimeNeededBy": 1727578740,
                "deviceType": null,
                "cameraType": null,
                "sourceDetails": null,
                "timeCodes": null,
                "mtdSource": [
                    {
                        "deviceType": "Cfast",
                        "sourceDetails": "Test Source Details 1",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    },
                    {
                        "deviceType": "P2",
                        "sourceDetails": "Test Source Details 2",
                        "timeCodes": "Merging Together Clips Without Retaining Time Code",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    },
                    {
                        "deviceType": "SD",
                        "sourceDetails": "Test Source Details 3",
                        "timeCodes": "Matching Time Code For All Clips",
                        "cameraType": [
                            "Panasonic EVA1",
                            "Panasonic Varicam LT"
                        ]
                    }
                ],
                "gigSize": null,
                "deviceStatus": null,
                "instructions": "Instructions Merging Together Clips Without Retaining Time Code",
                "reqDestinations": [
                    {
                        "destinations": "Hard Drive",
                        "quantity": 1,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Stratus",
                        "quantity": 4,
                        "destDetails": "testing test/tesr/test/test"
                    },
                    {
                        "destinations": "Bluray",
                        "quantity": 6,
                        "destDetails": " testing test/tesr/test/test"
                    }
                ],
                "ffDestinations": null,
                "ffFileFormat": null,
                "ffFileFormatOther": null,
                "clipCount": 0,
                "deviceTimer": null,
                "ffComments": null,
                "requestSubmissionState": null,
                "mtdStatus": "NEW",
                "deleted": false,
                "unifiedRequestId": null,
                "loggedInUser": null,
                "loggedInSSO": null,
                "revisionNotes": [],
                "revisionLog": [
                    {
                        "comments": "New MTD Request is submitted",
                        "revisedBy": "Peddina, Sainath",
                        "revisedDate": 1726745421,
                        "state": "NEW",
                        "ssoId": "206736124"
                    }
                ],
                "requesterNotes": [],
                "changedFields": null,
                "created": 1726745421,
                "createdBy": "Peddina, Sainath",
                "modified": 1726745421,
                "modifiedBy": "Peddina, Sainath",
                "requestComplete": true,
                "mtdRequestID": "MTD2024002116",
                "requestType": "MTD",
                "statusIndex": 1,
                "deviceTimerOption": null,
                "deviceDate": null,
                "ecmRequestId": null,
                "modifiedBySSO": "206736124"
            }
        }
    ]
}