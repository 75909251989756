import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { CommonService } from '@services/common-service';
import { GlobalSearchService } from '@services/global-search.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';


@Component({
  selector: 'tag-search',
  templateUrl: './search-tag.component.html',
  styleUrls: ['./search-tag.component.scss']
})
export class SearchTagComponent implements OnInit, OnDestroy, AfterViewChecked {

  tagSearch: Subject<string> = new Subject();

  tagSearchInputValue: string = '';

  @Input() selectedTags: any[] = [];

  @Output() onTagSelected: EventEmitter<any[]> = new EventEmitter<any[]>

  @Output() onIncludeExcludeChanged: EventEmitter<void> = new EventEmitter<void>

  constructor(
    private cService: CommonService,
    public searchService: GlobalSearchService,
  ) { }

  ngOnInit() {

    this.tagSearch.pipe(debounceTime(500)).subscribe(searchTextValue => {

      this.getTags(searchTextValue);

    });

  }

  ngOnDestroy() {


  }

  /**
   * ngAfterViewChecked Angular Life cycle
   */
  ngAfterViewChecked(): void {



  }




  onChangeIntagInput(value: string, event: any): void {

    setTimeout(() => {

      this.tagSearch.next(event.target.value);

    }, 200);

  }


  tags: any[] = [];

  formattedTags: any[] = [];

  getTags(searchTagText: string) {

    const queryStr = `?excludeDeleted=true&&topicName= ${searchTagText}`;

    this.cService.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStr).subscribe((data: any) => {

      data.topics.map(tag => {
        this.formattedTags.push(tag.topicCode);
      });

      this.showUnSelectedtags();

    });

  }

  showUnSelectedtags() {

    this.tags = [];

    if (this.selectedTags.length === 0) {

      this.tags = this.formattedTags.map(tag => tag);
    }
    else {

      this.formattedTags.forEach((item) => {

        const foundtag = this.selectedTags?.find(tag => tag === item);

        if (!foundtag) {

          this.tags.push(item);
        }

      });
    }
  }

  titleMatch($event) {

    if (this.selectedTags.length === 0) {

      const selectedtag = this.formattedTags.find(tag => tag === $event);

      if (selectedtag) {
        setTimeout(() => {
          this.tagSearchInputValue = '';
        }, 100);

        this.selectedTags.push(selectedtag);

        this.onTagSelected.emit(this.selectedTags);
      }
    }
    else {

      const foundtag = this.selectedTags?.find(tag => tag === $event);

      if (!foundtag) {

        const selectedtag = this.formattedTags.find(tag => tag === $event);

        if (selectedtag) {

          this.selectedTags.push(selectedtag);

          this.onTagSelected.emit(this.selectedTags);

          setTimeout(() => {
            this.tagSearchInputValue = '';
          }, 100);
        }

      }
    }
  }


  onEnter(_value) {

  }

  handleClose(removedTag: object): void {

    this.selectedTags = this.selectedTags.filter(tag => tag !== removedTag);
  }


  onIncludeExcludeChange() {
    this.onIncludeExcludeChanged.emit();
  }

}