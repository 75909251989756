import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BreakpointService } from '@services/breakpoint.service';
import { SubSink } from 'subsink';
import { ECM_DASHBOARD_COLUMNS } from './edit';
import { TableColumns } from './related-content-utils';


@Component({
  selector: 'app-story-related-content',
  templateUrl: './story-related-content.component.html',
  styleUrls: ['../story-landing-tabs-common.scss', './story-related-content.component.scss'],
})
export class StoryRelatedContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  isLoaded: boolean = true;

  totalRecords: number = 0;

  relatedContentData: any = [];

  tabBodyHeight: string = '';

  tabBody: HTMLElement | any;

  relatedContentHeight: number = 0;

  relatedContentTop: number = 0;

  headerHeight: number = 0;

  dashboardColumns = ECM_DASHBOARD_COLUMNS;

  createdSortOrder: any;

  preferenceColumns: { [key: string]: TableColumns } = {};

  columns: any;

  // Do we need to load additional items to engage the scrollbar?
  initLoadingMoreForScroll: boolean = false;

  // Story information
  @Input() storyId: number = 0;

  // Is this tab visible to the user?
  @Input() isVisible: boolean = false;

  // Height of the footer for viewport calculations
  @Input() footerHeight: number = 0;

  // Tell parent component when body is scrolling
  @Output() isScrolling: EventEmitter<void> = new EventEmitter<void>();

  segmentSelectedIndex: number = 0;

  @ViewChild('closeIconTpl', { static: false }) closeIconTpl: any;

  private subs = new SubSink();

  tabs = ['Guest Bookings', 'Field Crews', 'Edits', 'Feed Out', 'File Ingest'];

  tableParams: any;

  ecmDataFilters: any;

  options = [
    { label: 'Guest Bookings', value: '', disabled: false },
    { label: 'Field Crews', value: '', disabled: false },
    { label: 'Edits', value: '', disabled: false },
    { label: 'Feed Out', value: '', disabled: false },
    { label: 'File Ingest', value: '', disabled: false },
  ];

  @ViewChild('relatedContentElement', { static: false }) relatedContentElement: ElementRef | any;
  @ViewChild('tabBodyElement', { static: false }) tabBodyElement: ElementRef | any;
  @ViewChild('headerElement', { static: false }) headerElement: ElementRef | any;


  constructor(
    private breakpointService: BreakpointService
  ) { }

  ngOnInit() {

    this.segmentSelectedIndex = 0;
  }

  ngOnChanges(changes: SimpleChanges) {

    // if (changes.storyId && changes.storyId.currentValue) {

    //   this.loadRelatedContent();

    // }

  }

  ngAfterViewChecked() {
    this.windowResizeEvent();
  }


  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    const relatedContent = (this.relatedContentElement?.nativeElement as HTMLElement)?.getBoundingClientRect();
    this.relatedContentHeight = window.innerHeight - relatedContent?.top - this.footerHeight - 25.5;
    document.documentElement.style.setProperty('--relatedContentHeightInPx', this.relatedContentHeight + 'px');

    this.relatedContentTop = relatedContent.top;
  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }


  assignTotalRecords(total: number) {
    this.totalRecords = total;
  }


  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }
}

