<div #guestBookingTable>
   <nz-table
      nzBordered
      class="related-content-table"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr>
            <th
               [nzWidth]="preferenceColumns['IDSTATUS'].width + 'px'"
               [nzFilters]="statusFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="statusFilterEvent($event)">
               {{ preferenceColumns['IDSTATUS'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="idSearchVisible"
                  [nzActive]="idSearchText.length > 0"
                  [nzDropdownMenu]="idTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SUBMITTED'].width + 'px'"
               [nzLeft]="true"
               [rowspan]="preferenceColumns['SUBMITTED'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="submittedDateSortOrder"
               (nzSortOrderChange)="submittedDateSortEvent($event)"
               [nzFilters]="submittedDateFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="submittedDateFilterEvent($event)">
               {{ preferenceColumns['SUBMITTED'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['DESTINATION'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['DESTINATION'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="destinationSearchVisible"
                  [nzActive]="destinationSearchText.length > 0"
                  [nzDropdownMenu]="destinationTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['MEDIAID'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['MEDIAID'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="mediaIdSearchVisible"
                  [nzActive]="mediaIdSearchText.length > 0"
                  [nzDropdownMenu]="mediaIdTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['REQUESTER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['REQUESTER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="requesterSearchVisible"
                  [nzActive]="requesterSearchText.length > 0"
                  [nzDropdownMenu]="requesterTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOWPROJECT'].width + 'px'"
               [rowspan]="preferenceColumns['SHOWPROJECT'].rowSpan"
               nzCustomFilter>
               {{ preferenceColumns['SHOWPROJECT'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="showOrProjectSearchVisible"
                  [nzActive]="showOrProjectSearchText.length > 0"
                  [nzDropdownMenu]="showOrProjectTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>
         </tr>
      </thead>
      <tbody>
         <ng-container *ngFor="let data of relatedContentData; let i = index">
            <tr>
               <td>
                  <span
                     [style.color]="requestStatusDetails[data.content.statusIndex]?.color"
                     [ngClass]="requestStatusDetails[data.content.statusIndex]?.class"
                     class="ecm">
                     <span
                        nz-icon
                        style="margin-right: 5px"
                        [nzType]="requestStatusDetails[data.content.statusIndex]?.icon"
                        [nzTheme]="
                           requestStatusDetails[data.content.statusIndex]?.icon === 'sync' ? 'outline' : 'fill'
                        "></span>
                     <span
                        nz-tooltip
                        nzTooltipPlacement="bottomLeft"
                        nzTooltipColor="white">
                        <a
                           [ngStyle]="{ color: requestStatusDetails[data.content.statusIndex]?.color }"
                           [routerLink]="[data.content.zrouterlink, 'view', data.content.zid]">
                           {{ data.content.zid }}
                        </a>
                     </span>
                  </span>
               </td>

               <td>{{ data.content.requestCreated | date: 'MM/dd/yyyy' }}</td>

               <td>{{ data.content.zRequestersDisplayString }}</td>
               <td>{{ data.content.zShowUnitsDisplayString }}</td>
               <td>{{ data.content.zProducersDisplayString }}</td>
               <td>{{ data.content.zProducersDisplayString }}</td>
            </tr>
         </ng-container>
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #idTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Id(s)"
            (keyup.enter)="searchId()"
            [(ngModel)]="idSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchId()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetIdSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #requesterTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Requester(s)"
            (keyup.enter)="searchRequester()"
            [(ngModel)]="requesterSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchRequester()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetRequesterSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showOrProjectTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show / Project(s)"
            (keyup.enter)="searchShowOrProject()"
            [(ngModel)]="showOrProjectSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchShowOrProject()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetShowOrProjectSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #destinationTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Destination(s)"
            (keyup.enter)="searchDestination()"
            [(ngModel)]="destinationSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchDestination()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetDestinationSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #mediaIdTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Senior Producer(s)"
            (keyup.enter)="searchMediaId()"
            [(ngModel)]="mediaIdSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchMediaId()"
            class="search-button">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetMediaIdSearch()">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>
