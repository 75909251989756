import { AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LightboxAttachment } from '@models/ncx/lightbox';
import { PostAttachment } from '@models/ncx/post';
import { BreakpointService } from '@services/breakpoint.service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Subject } from 'rxjs';
import { GoogleAnalyticsEventService } from 'src/app/services/google-analytics-events.service.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-view-attachments',
  templateUrl: './view-attachments.component.html',
  styleUrls: ['./view-attachments.component.scss'],
})
export class ViewAttachmentsComponent implements OnDestroy, AfterViewInit, OnChanges {

  // Array of all attachments in Post
  @Input() postAttachments: PostAttachment[] = [];

  /**
   * Create Post (Preview) and View Post will show all attachments. Other views will show only three rows with the remaining number on the last
   * visible attachment.
   */
  @Input() showAllAttachments: boolean = false;

  @Input() isStoryLanding;

  @Input() storyId;

  // Same array of Post attachments, converted for Lightbox
  lightBoxAttachments: LightboxAttachment[] = [];

  // Initial index of the attachments array to show
  indexToOpen: number = 0;

  // Show the Lightbox image viewer
  showLightBox: any = false;

  // All extensions that make up images or video
  imgExtn = /jpe?g|jfif|png|bmp/;

  videoExtn = /mov|mp4|3gp|m4v|mkv|webm/;

  thumpVideoExtn = /mov|mp4|3gp|m4v|mkv|webm|flv|avi|mpeg|wmv|asf/;

  fileExtn = /pdf|doc|docx|txt|rtf/;

  // Resize listener
  resize$ = new Subject<void>();

  private subs = new SubSink();

  // Container that holds all attachments
  @ViewChild('container', { static: true }) container: ElementRef;

  private resizeObserver!: ResizeObserver;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {

    this.resize$.next();

  }

  constructor(
    private gaService: GoogleAnalyticsEventService,
    private breakpointService: BreakpointService,
    private urlRedirectService: UrlRedirectService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
  ) { }

  ngAfterViewInit() {

    this.buildAttachments();

    this.resizeObserver = new ResizeObserver(entries => {

      for (const entry of entries) {

        // Use NgZone to run the change detection inside Angular's zone
        this.ngZone.run(() => {

          // console.log('Element resized:', entry.contentRect);
          this.updateHiddenAttachments();

        });

      }

    });
    this.resizeObserver.observe(this.container.nativeElement);

    // this.subs.sink = this.resize$.pipe(debounceTime(250)).subscribe(() => {
    //   this.updateHiddenAttachments();
    // });

    // this.subs.sink = this.breakpointService.breakpoints$.subscribe(() => {
    //   this.updateHiddenAttachments();
    // });

  }

  ngOnChanges() {

    this.buildAttachments();

  }

  getUrl(url: string) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);

  }

  ngOnDestroy() {

    this.subs.unsubscribe();
    this.resizeObserver.disconnect();

    this.resizeObserver.disconnect();

  }

  /**
   * Build the album of attachments which is all of the attachments converted into
   * Lightbox images
   *
   */
  buildAttachments() {

    if (!Array.isArray(this.postAttachments)) {

      return;

    }

    this.lightBoxAttachments = this.postAttachments.map((attachment: PostAttachment) => {

      const { attachmentId, attachmentName, attachmentPreview } = attachment;

      const extension = String(attachment.attachmentPath).split('.').pop().toLowerCase();

      const isImage = !!extension.match(this.imgExtn);

      const isVideo = !!extension.match(this.videoExtn);

      const isFile = !!extension.match(this.fileExtn);

      const isthumpVideo = !!extension.match(this.thumpVideoExtn);

      const lightboxAttachment: LightboxAttachment = {

        // Id of attachment
        attachmentID: attachmentId,

        // Name of full-size version of file
        attachmentName,

        // Name of preview-size version of file
        attachmentPreviewName: attachmentPreview ? attachmentPreview : '',

        // URL of full-size version of file
        fileUrl: this.imageURL(attachment),

        // URL of preview-size version of file
        previewUrl: this.imageURL(attachment, 'preview'),

        thumpnail: this.imageThumpnailURL(attachment, 'thumpnail'),

        // File extension
        extension,

        // Is this an image?
        isImage,

        // Is this a video?
        isVideo,

        isFile,

        isthumpVideo,

      };

      return lightboxAttachment;

    });

    // console.log('View Attachments (buildAttachments)', { album: this.attachments, attachments: this.viewAttachments });

  }

  /**
   * Function determines the last visible image and adds the +N indicator
   * on the image if there are overflow attachments
   *
   */
  updateHiddenAttachments() {

    if (!this.container || !this.container.nativeElement) {

      return;

    }

    const bounds = this.container.nativeElement.getBoundingClientRect();

    const attachments = this.container.nativeElement.querySelectorAll('.attachment');

    const containerBottom = bounds.bottom;

    const numAttachments = this.lightBoxAttachments.length;

    let visibleAttachments = 0;

    [...attachments].forEach((attachment: any) => {

      attachment.removeAttribute('data-hidden');

      const top = attachment.getBoundingClientRect().top;

      const topofImage = Math.ceil(top); //Rounds up to the nearest integer

      const bottomOfContainer = Math.floor(containerBottom); //Rounds down to the nearest integer.

      if (topofImage < bottomOfContainer) {

        visibleAttachments++;

      }

    });

    if (visibleAttachments && visibleAttachments < numAttachments) {

      attachments[visibleAttachments - 1].setAttribute('data-hidden', numAttachments - visibleAttachments);

    }

    // Need this due to slight delay between repaint and caculation of height/width
    if (numAttachments > 0 && !visibleAttachments) {

      window.dispatchEvent(new Event('resize'));

    }

  }

  /**
   * Open lightbox viewer
   *
   */
  openvideolightbox(index: number, url: any, extension: any, attachmentId: any) {

    let supported = !!extension.match(this.videoExtn);
    if (window.location.href.includes('view-post')) {
      this.gaService.sendEvent('View Lightbox Click', 'View Post Page', attachmentId, 1);
    } else if (window.location.href.includes('discussion-details')) {
      this.gaService.sendEvent('View Lightbox Click', 'Discussion Details Page', attachmentId, 1);
    } else if (window.location.href.includes('search-results')) {
      this.gaService.sendEvent('View Lightbox Click', 'Search Result Page', attachmentId, 1);
    } else if (window.location.href.includes('history')) {
      this.gaService.sendEvent('View Lightbox Click', 'History Page', attachmentId, 1);
    } else {
      this.gaService.sendEvent('View Lightbox Click', 'Attachment Preview', attachmentId, 1);
    }
    if (supported) {

      if (this.isStoryLanding) {

        this.gaService.trackElementClickAction('Story Landing Clicks', this.storyId);
        this.gaService.sendEvent('View Lightbox Click', 'Story Landing Page', attachmentId, 1);
      }
      this.indexToOpen = index;
      this.showLightBox = true;

    } else {

      window.open(this.urlRedirectService.getRedirectURL(url), '_blank');

    }

  }

  openlightbox(index: number, attachmentId: any) {

    if (window.location.href.includes('view-post')) {
      this.gaService.sendEvent('View Lightbox Click', 'View Post Page', attachmentId, 1);
    } else if (window.location.href.includes('discussion-details')) {
      this.gaService.sendEvent('View Lightbox Click', 'Discussion Details Page', attachmentId, 1);
    } else if (window.location.href.includes('search-results')) {
      this.gaService.sendEvent('View Lightbox Click', 'Search Result Page', attachmentId, 1);
    } else if (window.location.href.includes('history')) {
      this.gaService.sendEvent('View Lightbox Click', 'History Page', attachmentId, 1);
    } else {
      this.gaService.sendEvent('View Lightbox Click', 'Attachment Preview', attachmentId, 1);
    }

    if (this.isStoryLanding) {

      this.gaService.trackElementClickAction('Story Landing Clicks', this.storyId);
      this.gaService.sendEvent('View Lightbox Click', 'Story Landing Page', attachmentId, 1);
    }
    this.indexToOpen = index;
    this.showLightBox = true;

  }

  /**
   * Close modal
   *
   */
  destroyModalPopup(isClosed: boolean) {

    if (isClosed) {

      this.showLightBox = false;

    }

  }

  /**
   * Image Url
   *
   */
  imageURL(post: PostAttachment, type: string = 'full'): string {

    let url = type === 'preview' && post.attachmentPreview && post.attachmentPreviewPath ? this.urlRedirectService.getRedirectAPIURL(
      post.attachmentPreviewPath) : this.urlRedirectService.getRedirectAPIURL(post.attachmentPath);

    if (url && !url.includes('?ncxjwttoken=')) {

      url = `${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`;

    }
    url = `${url}&attachmentName=${post.attachmentName}`;
    return url;

  }

  imageThumpnailURL(post: PostAttachment, type: string = 'full'): string {

    let url = type === 'thumpnail' && post.attachmentPreviewPath ? post.attachmentPreviewPath : '';

    if (url && !url.includes('?ncxjwttoken=')) {

      url = this.urlRedirectService.getRedirectAPIURL(`${url}?ncxjwttoken=${localStorage.getItem('ncxjwttoken')}`);

    }
    return url;

  }

}

