import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IconDefinition, IconModule } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { environment } from '@environments/environment';
import { AppJsonDataService } from '@services/app-json.service';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

// tslint:disable-next-line: jsdoc-format
/** config angular i18n **/
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { BannersComponent } from '@components/banners/announcements/banners.component';
import { NavigationComponent } from '@layout/navigation/navigation.component';
import { AlertsPopupComponent } from '@ncx/alerts/popup/alerts-popup.component';
import { SearchComponent } from '@ncx/search/global/search.component';
import { SearchPreviewComponent } from '@ncx/search/preview/preview.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTPCommonInterceptor } from '@services/Interceptor.service';
import { AuthGuardService } from '@services/auth/app.auth.guard';
import { AuthManager } from '@services/auth/app.auth.manager';
import { DeactivateGuardService } from '@services/auth/app.can-deactivate.guard';
import { AuthService } from '@services/auth/auth.service';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import { NavigationService } from '@services/navigation-service';
import { NcxJwtInterceptorService } from '@services/ncx-jwt-interceptor.service';
import { PermissionsService } from '@services/profile/permissions.service';
import { UserSettingService } from '@services/profile/usersetting.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { TranslationService } from '@services/translation-service.service';
import { WebSocketService } from '@services/websocket.service';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ErrorComponent } from './error.component';
import { DisabledUserComponent } from './ncx/disabled-user/disabled-user.component';
import { HomeComponent } from './ncx/home/home.component';
import { InvalidUserComponent } from './ncx/invalid-user/invalid-user.component';
import { NcxModule } from './ncx/ncx.module';
import { PageNotFoundComponent } from './ncx/page-not-found/page-not-found.component';
import { TransferInformationComponent } from './shared/components/TransferInformation/TransferInformation.component';
import { DemoNgZorroAntdModule } from './shared/modules/ng-zorro-antd.module';

export const createTranslateLoader = (http: HttpClient) => {

  return new TranslateHttpLoader(http, './assets/i18n', '.json');

};

registerLocaleData(en);
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => {

  const i = antDesignIcons[key];

  return i;

});

export function init_app(appLoadService: AuthManager) {

  return () => appLoadService.resolve();

}

// AntDesign Global configuration settings
const ngZorroConfig: NzConfig = {
  message: { nzTop: 20, nzMaxStack: 2 },
  notification: { nzTop: 20 }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlertsPopupComponent,
    NavigationComponent,
    ErrorComponent,
    SearchComponent,
    SearchPreviewComponent,
    InvalidUserComponent,
    BannersComponent,
    PageNotFoundComponent,
    DisabledUserComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  imports: [
    NcxModule,
    NzSegmentedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    HttpClientJsonpModule,
    TranslateModule,
    IconModule,
    DemoNgZorroAntdModule,
    SharedModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    NgxDocViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), FormsModule
  ],
  exports: [
    NzIconModule
  ],
  providers: [
    AppJsonDataService,
    AuthManager,
    WebSocketService,
    AuthService,
    NavigationService,
    PermissionsService,
    CommonFunctionsHelper,
    TransferInformationComponent,
    ToastService,
    TranslationService,
    AuthGuardService,
    DeactivateGuardService,
    UserSettingService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPCommonInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NcxJwtInterceptorService,
      multi: true
    },
    {
      provide: NZ_ICONS,
      useValue: icons
    }, { provide: NZ_I18N, useValue: en_US },
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
