<nz-collapse
   nzExpandIconPosition="right"
   [nzBordered]="false">
   <nz-collapse-panel
      [nzHeader]="dateHeader"
      nzActive>
      <div class="date-panel">
         <nz-range-picker
            class="date-picker"
            [(ngModel)]="searchService.filters.STORIES.modificationDate"
            (ngModelChange)="dateChanged($event)"></nz-range-picker>

         <nz-radio-group
            class="list"
            [(ngModel)]="searchService.filters.STORIES.dateOption"
            (ngModelChange)="dateOptionChanged($event)">
            <label
               nz-radio
               nzValue="alltime">
               All Time
            </label>
            <label
               nz-radio
               nzValue="today">
               Today
            </label>
            <label
               nz-radio
               nzValue="past2days">
               Past 2 Days
            </label>
            <label
               nz-radio
               nzValue="past3days">
               Past 3 days
            </label>
            <label
               nz-radio
               nzValue="pastweek">
               Past Week
            </label>
         </nz-radio-group>

         <nz-divider
            class="horizontal-line"
            nzType="horizontal"></nz-divider>

         <nz-radio-group
            class="full-width-radio-group"
            [(ngModel)]="searchService.filters.STORIES.createdOrModified"
            (ngModelChange)="onFilterChanged()"
            [nzButtonStyle]="'solid'">
            <label
               nz-radio-button
               nzValue="modified">
               Modified
            </label>
            <label
               nz-radio-button
               nzValue="created">
               Created
            </label>
         </nz-radio-group>
      </div>
   </nz-collapse-panel>
</nz-collapse>

<div class="no-top-border">
   <nz-collapse
      nzExpandIconPosition="right"
      [nzBordered]="false">
      <nz-collapse-panel
         [nzHeader]="followingHeader"
         nzActive>
         <nz-radio-group
            class="full-width-radio-group"
            [(ngModel)]="searchService.filters.STORIES.following"
            (ngModelChange)="onFilterChanged()"
            [nzButtonStyle]="'solid'">
            <label
               nz-radio-button
               nzValue="Following">
               Following
            </label>
            <label
               nz-radio-button
               nzValue="All">
               All
            </label>
         </nz-radio-group>
      </nz-collapse-panel>
   </nz-collapse>
</div>

<div class="no-top-border">
   <topic-search
      [selectedTopics]="searchService.filters.STORIES.topics"
      (onTopicSelected)="onTopicSelected($event)"></topic-search>
</div>

<div class="no-top-border bottom-border">
   <tag-search
      [selectedTags]="searchService.filters.STORIES.tags"
      (onTagSelected)="onTagSelected($event)"
      (onIncludeExcludeChanged)="onIncludeExcludeChanged()"></tag-search>
</div>

<ng-template #followingHeader>
   <i
      nz-icon
      nzType="star"></i>
   Following
</ng-template>

<ng-template #dateHeader>
   <i
      nz-icon
      nzType="calendar"></i>
   Date
</ng-template>
