import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GroupSearchResult } from '@models/ncx/global-search';
import { NavigationService } from '@services/navigation-service';
import { UrlRedirectService } from '@services/url-redirect.service';

@Component({
  selector: 'ncx-group-search-new',
  templateUrl: './group-search.component.html',
  styleUrls: ['../../cards-common.scss', './group-search.component.scss']
})
export class GroupSearchNewComponent {

  @Input() group: GroupSearchResult = {} as GroupSearchResult;

  @Output() viewGroup: EventEmitter<GroupSearchResult> = new EventEmitter<GroupSearchResult>();

  constructor(
    private router: Router,
    private location: Location,
    private urlRedirectService: UrlRedirectService,
    private navigationService: NavigationService
  ) { }

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewGroup() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewGroup.emit(this.group);
      return true;

    } else {

      this.viewGroup.emit(this.group);
      window.open(this.urlRedirectService.getRedirectURL(this.group.storyUrl), '_blank');
      return false;

    }

  }

}
