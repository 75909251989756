<!-- RESULT COUNT -->
<div>
   <ng-container *ngTemplateOutlet="resultBodyHeaderTemplate"></ng-container>

   <ng-container *ngTemplateOutlet="resultBodyTemplate"></ng-container>
</div>

<ng-template #resultBodyHeaderTemplate>
   <div [ngClass]="{ 'result-body-header': true, 'group-header-margin': !allResultsPage }">
      <div *ngIf="allResultsPage">
         <span
            class="mr-8"
            nz-icon
            nzType="global"
            nzTheme="outline"></span>
         Groups
      </div>
      <div *ngIf="!allResultsPage">
         <!-- {{ searchService.filters.GROUPS.sort }} -->

         <nz-select
            class="sort-options"
            nzMode="default"
            [(ngModel)]="searchService.filters.GROUPS.sort"
            (ngModelChange)="onSortOptionChange($event)"
            [nzAllowClear]="true"
            [nzShowArrow]="true">
            <nz-option
               *ngFor="let option of groupSortOptions"
               [nzLabel]="option.key"
               [nzValue]="option.value"></nz-option>
         </nz-select>
      </div>
      <div
         *ngIf="isLoaded || (total > 0 && !isLoaded)"
         class="result-count">
         {{ resultCount }}
         <button
            *ngIf="searchService.suggestedSearch.show"
            (click)="searchService.performSuggestedSearch()"
            class="suggested-search">
            Did you mean {{ searchService.suggestedSearch.keyword }}?
         </button>
      </div>
   </div>
</ng-template>

<ng-template #resultBodyTemplate>
   <div
      [ngClass]="{ 'search-result-container': true, 'no-results': !searchResults.length }"
      infiniteScroll
      [infiniteScrollDistance]="2"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
      data-scrollable-container
      [attr.data-component]="'ncx/search/page/results/groups'">
      <div [ngClass]="{ 'search-result-body': !allResultsPage }">
         <!-- LOADER -->
         <app-loader-component
            loaderType="SECTION_LOADER"
            [isLoaded]="isLoaded"></app-loader-component>

         <!-- CARD TO DISPLAY GROUP SEARCH RESULTS -->
         <div class="grid-container-margin">
            <div
               [ngClass]="{
                  'group-column-grid-container': !allResultsPage,
                  'grid-container-full-frame': allResultsPage,
                  'ml-24': !allResultsPage,
               }">
               <ng-container
                  *ngFor="let group of searchResults; let index = index"
                  class="grid-item">
                  <ncx-group-search-new
                     [group]="group"
                     (viewGroup)="viewGroup(group, index)"></ncx-group-search-new>
               </ng-container>
            </div>
         </div>

         <div *ngIf="!searchResults.length && isLoaded">
            <nz-empty [nzNotFoundContent]="contentTpl">
               <ng-template #contentTpl>
                  <span>No Groups Found</span>
               </ng-template>
            </nz-empty>
         </div>
      </div>
   </div>
</ng-template>
