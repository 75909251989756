<div
   class="global-search-bar"
   data-id="global-search">
   <input
      *ngIf="globalSearch"
      type="text"
      nz-input
      data-id="global-search-input"
      #searchInputField
      [(ngModel)]="searchInputValue"
      (ngModelChange)="onModelChange($event)"
      (keyup.enter)="viewSearchPage()"
      placeholder="Search all of NewsConnect" />

   <button
      *ngIf="globalSearch"
      nz-button
      nzType="primary"
      nzSize="small"
      nzGhost="true"
      nzShape="circle"
      class="icon"
      (click)="staticSearch ? closeSearch() : performSearch()">
      <span
         nz-icon
         nzTheme="outline"
         [nzType]="staticSearch ? 'close' : 'search'"></span>
   </button>

   <!-- SEARCH RESULTS -->

   <app-search-preview [searchText]="searchInputValue"></app-search-preview>
</div>
