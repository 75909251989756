<nz-collapse
   class="margin-space topics-search"
   nzExpandIconPosition="right"
   [nzBordered]="false">
   <nz-collapse-panel
      [nzHeader]="topicsHeader"
      nzActive>
      <nz-input-group
         [nzSuffix]="suffixIconSearch"
         class="input-group">
         <input
            placeholder="Search Topics"
            nz-input
            [(ngModel)]="topicSearchInputValue"
            class="search-text"
            (keydown)="onChangeInTopicInput(storyInput.value, $event)"
            (ngModelChange)="titleMatch($event)"
            (keyup.enter)="onEnter($event.target.value)"
            [nzAutocomplete]="auto"
            #storyInput />
      </nz-input-group>
      <ng-template #suffixIconSearch>
         <span
            nz-icon
            nzType="search"
            class="search-text"></span>
      </ng-template>
      <nz-autocomplete
         [nzDataSource]="topics"
         (change)="onEnter($event.target.value)"
         #auto></nz-autocomplete>

      <div
         class="show-topics"
         *ngIf="selectedTopics.length > 0">
         <ng-container *ngFor="let topic of selectedTopics; let i = index">
            <span class="display-topic">
               <span class="topic-text">{{ topic.topicName }}</span>
               <span
                  nz-icon
                  nzType="close"
                  nzTheme="outline"
                  (click)="handleClose(topic)"
                  class="topic-close"></span>
            </span>
         </ng-container>
      </div>
   </nz-collapse-panel>
</nz-collapse>

<ng-template #topicsHeader>
   <i
      nz-icon
      nzType="message"></i>
   Topics
</ng-template>
