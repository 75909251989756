
export const editColumns: any = [
    {
        text: 'TYPE',
        value: 'EDITTYPE',
        width: 240,
        checked: true,
        rowSpan: 2,
        colSpan: 0,
        superHeader: '250px',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }, ,
    {
        text: 'ID/Status',
        value: 'REQUESTID',
        width: 240,
        checked: true,
        rowSpan: 2,
        colSpan: 0,
        superHeader: '250px',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Air Date',
        value: 'AIRDATE',
        width: 190,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Requester(s)',
        value: 'REQUESTER',
        width: 240,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: 'Production Team',
        extraSpan: true,
        reporting: true,
        firstLevelHeader: false
    },

    {
        text: 'Show / Project(s)',
        value: 'SHOWUNIT',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    },
    {
        text: 'Producer(s)',
        value: 'PRODUCER',
        width: 300,
        checked: false,
        rowSpan: 0,
        colSpan: 0,
        superHeader: '',
        extraSpan: false,
        reporting: true,
        firstLevelHeader: false
    }
];

export const ECM_DASHBOARD_COLUMNS = {
    requestid: { text: 'Request ID', value: 'REQUESTID' },
    enteredintostorm: { text: 'Entered Into STORM', value: 'ENTEREDINTOSTORM' },
    timer: { text: 'Timer', value: 'TIMER' },
    devicereceived: { text: 'Device Received', value: 'DEVICERECEIVED' },
    slug: { text: 'Slug', value: 'SLUG' },
    showunits: { text: 'Show Unit(s)', value: 'SHOWUNITS' },
    budgetcodes: { text: 'Budget Code(s)', value: 'BUDGETCODES' },
    division: { text: 'Division', value: 'DIVISION' },
    location: { text: 'Location', value: 'LOCATION' },
    editstart: { text: 'Edit Start', value: 'EDITSTART' },
    submitter: { text: 'Submitter', value: 'SUBMITTER' },
    airdate: { text: 'Air Date', value: 'AIRDATE' },
    airtime: { text: 'Air Time', value: 'AIRTIME' },
    dateneeded: { text: 'Date Needed', value: 'DATENEEDED' },
    timeneeded: { text: 'Time Needed', value: 'TIMENEEDED' },
    destinations: { text: 'Destination(s)', value: 'DESTINATIONS' },
    requesters: { text: 'Requester(s)', value: 'REQUESTERS' },
    producer: { text: 'Producer', value: 'PRODUCER' },
    srproducer: { text: 'Sr. Producer', value: 'SRPRODUCER' },
    assteditor: { text: 'Asst. Editor', value: 'ASSTEDITOR' },
    producereditor: { text: 'Producer/Editor', value: 'PRODUCEREDITOR' },
    sources: { text: 'Source(s)', value: 'SOURCES' },
    cameratypes: { text: 'Camera Type(s)', value: 'CAMERATYPES' },
    transcode: { text: 'Transcode', value: 'TRANSCODE' },
    avid: { text: 'AVID', value: 'AVID' },
    stratus: { text: 'Stratus', value: 'STRATUS' },
    contentdescription: { text: 'Content Description', value: 'CONTENTDESCRIPTION' },
    crash: { text: 'Crash', value: 'CRASH' },
    ackage: { text: 'Package', value: 'PACKAGE' },
    promos: { text: 'Promos', value: 'PROMOS' },
    openteases: { text: 'Open/Teases', value: 'OPENTEASES' },
    vossots: { text: 'VOs/SOTs', value: 'VOSSOTS' },
    podcasts: { text: 'Podcasts', value: 'PODCASTS' },
    digitalweb: { text: 'Digital/Web', value: 'DIGITALWEB' },
    fixes: { text: 'Fixes', value: 'FIXES' },
    showbuild: { text: 'Show Build', value: 'SHOWBUILD' },
    showupdate: { text: 'Show Update', value: 'SHOWUPDATE' },
    other: { text: 'Other', value: 'OTHER' },
    total: { text: 'Total', value: 'TOTAL' },
    modified: { text: 'Modified', value: 'MODIFIED' },
    submitted: { text: 'Submitted', value: 'SUBMITTED' },
    actions: { text: 'Actions', value: 'ACTIONS' },
    productionteam: { text: 'Production Team', value: 'PRODUCTIONTEAM' },
    technical: { text: 'Technical', value: 'TECHNICAL' },
    cnbcingest: { text: 'CNBC Ingest', value: 'CNBCINGEST' },
    edittype: { text: 'Edit Type', value: 'EDITTYPE' }
}

export enum requestType {
    longFormeditrequest = "Long Form Edit Request",
    editrequest = 'Edit Request',
    fileingest = 'File Ingest',
    feedout = 'Feed Out',
    mtd = 'MTD',
    selfreporting = 'Edit Self Reporting',
    standard = 'Standard',
    longForm = 'Long Form'
};
export interface CrewShowUnit {
    budgetCode: string;

    // required
    id: number;

    // required
    showunittitle: string;
}


export enum requestStatus {
    queue = 'QUEUE',
    new = 'NEW',
    inprogress = 'IN PROGRESS',
    revised = 'REVISED',
    completed = 'COMPLETED',
    confirmcancellation = 'CONFIRM CANCELLATION',
    cancelled = 'CANCELLED',
    fulfilled = 'FULFILLED'
};
