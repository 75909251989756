import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TransferInformationComponent } from '@components/TransferInformation/TransferInformation.component';
import { environment } from '@environments/environment';
import { UserRoles } from '@models/types/user-roles';
import { IFunctionAbility, User } from '@models/users';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { WebSocketService } from '@services/websocket.service';
import { Common } from '@utilities/common';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-stories-dashboard',
  templateUrl: './stories-dashboard.component.html',
  styleUrls: ['./stories-dashboard.component.scss']
})
export class StoriesDashboardComponent implements OnInit, OnDestroy {

  isPointerOverContainer: boolean;

  public functionAbility = {} as IFunctionAbility;

  pageIndex = 1;

  pageSize = 1;

  listOfData = [];

  submittedArray = [];

  workingArray = [];

  readyArray = [];

  filteredItems: string[];

  test = 'scrollx';

  submitted = 'Submitted';

  working = 'Working';

  ready = 'Ready';

  editorScreen = false;

  isVisible = false;

  editorsOnDuty: User[] = [];

  editorsOnDutyInit: User[] = [];

  editorsList = [];

  editorsListInit = [];

  getApiURL = '';

  getprofileURL: string;

  getEditorURL: string;

  getUsersOnDutyURL: string;

  editorText: string;

  isLoaded: boolean;

  isInitLoaded: boolean = false;

  userInfoDetails;

  pagecode = 'STORY';

  type: string;

  radioValue = 'ALL';

  isReadyTrending: boolean;

  isWorkingTrending: boolean;

  customColumnDrawerOpen = false;

  customColumnEditDrawerOpen = false;

  columnEditing = -1;

  PersonalizedArray = [
    { columnName: 'Personalized Content', categoryName: 'Story', columnNumber: 3, totalCount: 0, stories: [] },
    { columnName: 'Personalized Content', categoryName: 'Story', columnNumber: 4, totalCount: 0, stories: [] }
  ];

  PersonalizedArray1 = [{ columnName: 'Personalized Content', categoryName: 'Story', columnNumber: 3, totalCount: 0, stories: [] }];

  postEditorAPIURL: string;

  userRole: any;

  private subscriptionsWs: Subscription = new Subscription();

  modifiedUserIds = [];

  workingCount: any;

  submittedCount: any;

  readyCount: any;

  CustomArray = [];

  customHomePage = false;
  userRoles = UserRoles;
  @ViewChild(NzCarouselComponent) myCarousel: NzCarouselComponent;

  @Output() goTo = new EventEmitter();

  constructor(
    private http: HttpClient,
    private cService: CommonService,
    private wS: WebSocketService,
    private toastService: ToastService,
    private userInfo: TransferInformationComponent,
    private router: Router,
    private breakpointService: BreakpointService
  ) {

    this.userInfo.linkRoleDetail.subscribe(role => this.userRole = role);

  }

  ngOnInit() {

    this.editorText = '';
    this.getprofileURL = environment.getProfileAPIURL;
    this.getUsersOnDutyURL = environment.getUsersOnDutyURL;
    this.getEditorURL = environment.getEditorsAPIURL;
    this.postEditorAPIURL = environment.postProfileAPIURL;
    this.functionAbility = { ...this.userInfo.userFunctionAbility } as IFunctionAbility;
    this.userInfoDetails = {
      userId: this.userInfo.userInfoDetails.userId,
      role: this.userInfo.userInfoDetails.role
    };
    this.cService.serviceRequestCommon('get', environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings').
      subscribe((res: any) => {

        const settingsResponse = res;

        if (settingsResponse.showPersonalizedColumn &&
          settingsResponse.showPersonalizedColumn === true) {

          this.getApiURL = environment.getpersonalizedStoryDashboardAPI;
          this.getJSONData();
          this.customHomePage = true;
          this.cService.customHomePage = true;

        } else {

          this.getApiURL = environment.getStoryDashboardAPI;
          this.getJSONData();
          this.customHomePage = false;
          this.cService.customHomePage = false;

        }

      }, err => {

        this.toastService.createMessage('error', err);

      });

    this.subscriptionsWs = this.wS.doSocketData$.subscribe(data => {

      console.log('Web socket notification received in stories dashboard. Calling updateDashBoard event in stories dashboard');
      this.updateDashBoard(data);

    });
    this.wS.connect(this.pagecode);
    this.pageIndexfun();

    this.cService.UpdateDashboard.subscribe((res: any) => {

      if (res !== '') {

        if (res.action && res.action === 'Delete') {

          this.CustomArray.forEach((item, index) => {

            if (item.columnNumber === res.column) {

              this.CustomArray.splice(index, 1);

            }

          });

        } else {

          const ArrayDetails = this.CustomArray;

          if (res.categoryName === 'Activity') {

            this.CustomArray.forEach((element, index) => {

              this.CustomArray = [];
              if (element.column.columnNumber === res.columnNumber && res.categoryName === 'Activity') {

                const queryStr2 = `?ids=${res.storyIds}&tags=${res.tags}&` +
                  `labels=${res.labels}&postType=${res.postType}&` +
                  `contentPage=1&topics=${res.topics}&searchString=`;

                this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((subres: any) => {

                  ArrayDetails[index].stories = subres.stories;
                  ArrayDetails[index].categoryName = res.categoryName;
                  ArrayDetails[index].columnName = res.columnName;
                  ArrayDetails[index].columnNumber = res.columnNumber;
                  ArrayDetails[index].column.categoryName = res.categoryName;
                  ArrayDetails[index].column.columnName = res.columnName;
                  ArrayDetails[index].column.columnNumber = res.columnNumber;
                  ArrayDetails[index].column.enabledNotifications = res.enabledNotifications;
                  ArrayDetails[index].column.groupIds = res.groupIds;
                  ArrayDetails[index].column.labels = res.labels;
                  ArrayDetails[index].column.pageType = res.pageType;
                  ArrayDetails[index].column.personIds = res.personIds;
                  ArrayDetails[index].column.postIds = res.postIds;
                  ArrayDetails[index].column.postType = res.postType;
                  ArrayDetails[index].column.readyStory = res.readyStory;
                  ArrayDetails[index].column.sortField = res.sortField;
                  ArrayDetails[index].column.storyIds = res.storyIds;
                  ArrayDetails[index].column.workingStory = res.workingStory;
                  ArrayDetails[index].column.topics = res.topics;
                  ArrayDetails[index].column.tags = res.tags;
                  this.CustomArray = ArrayDetails;

                }, err => {

                  console.log(err);

                }).add(() => {
                });

              }
              if (element.column.columnNumber === res.columnNumber && res.categoryName === 'Group') {

                const queryStr2 = `?ids=${res.groupIds}&searchString=&isStory=false&` +
                  'idType=group&contentPage=1&isDiscussion=true';

                this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((subres: any) => {

                  ArrayDetails[index].discussions = subres.discussions;
                  ArrayDetails[index].categoryName = res.categoryName;
                  ArrayDetails[index].columnName = res.columnName;
                  ArrayDetails[index].columnNumber = res.columnNumber;
                  ArrayDetails[index].column.categoryName = res.categoryName;
                  ArrayDetails[index].column.columnName = res.columnName;
                  ArrayDetails[index].column.columnNumber = res.columnNumber;
                  ArrayDetails[index].column.enabledNotifications = res.enabledNotifications;
                  ArrayDetails[index].column.groupIds = res.groupIds;
                  ArrayDetails[index].column.labels = res.labels;
                  ArrayDetails[index].column.pageType = res.pageType;
                  ArrayDetails[index].column.personIds = res.personIds;
                  ArrayDetails[index].column.postIds = res.postIds;
                  ArrayDetails[index].column.postType = res.postType;
                  ArrayDetails[index].column.readyStory = res.readyStory;
                  ArrayDetails[index].column.sortField = res.sortField;
                  ArrayDetails[index].column.storyIds = res.storyIds;
                  ArrayDetails[index].column.workingStory = res.workingStory;
                  ArrayDetails[index].column.topics = res.topics;
                  ArrayDetails[index].column.tags = res.tags;
                  this.CustomArray = ArrayDetails;

                }, err => {

                  console.log(err);

                }).add(() => {
                });

              }
              if (element.column.columnNumber === res.columnNumber && res.categoryName === 'Person') {

                const queryStr2 = `?searchString=&createdBySSOIds=${res.personIds}&isCreatedBy=true&` +
                  'contentPage=1';

                this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((subres: any) => {

                  ArrayDetails[index].stories = subres.stories;
                  ArrayDetails[index].categoryName = res.categoryName;
                  ArrayDetails[index].columnName = res.columnName;
                  ArrayDetails[index].columnNumber = res.columnNumber;
                  ArrayDetails[index].column.categoryName = res.categoryName;
                  ArrayDetails[index].column.columnName = res.columnName;
                  ArrayDetails[index].column.columnNumber = res.columnNumber;
                  ArrayDetails[index].column.enabledNotifications = res.enabledNotifications;
                  ArrayDetails[index].column.groupIds = res.groupIds;
                  ArrayDetails[index].column.labels = res.labels;
                  ArrayDetails[index].column.pageType = res.pageType;
                  ArrayDetails[index].column.personIds = res.personIds;
                  ArrayDetails[index].column.postIds = res.postIds;
                  ArrayDetails[index].column.postType = res.postType;
                  ArrayDetails[index].column.readyStory = res.readyStory;
                  ArrayDetails[index].column.sortField = res.sortField;
                  ArrayDetails[index].column.storyIds = res.storyIds;
                  ArrayDetails[index].column.workingStory = res.workingStory;
                  ArrayDetails[index].column.topics = res.topics;
                  ArrayDetails[index].column.tags = res.tags;
                  this.CustomArray = ArrayDetails;

                }, err => {

                  console.log(err);

                }).add(() => {
                });

              }

            });

          } else {

            this.isLoaded = false;
            this.cService.serviceRequestCommon('get', environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings').
              subscribe((subres: any) => {

                const settingsResponse = subres;

                if (settingsResponse.showPersonalizedColumn &&
                  settingsResponse.showPersonalizedColumn === true) {

                  this.getApiURL = environment.getpersonalizedStoryDashboardAPI;
                  this.getJSONData();
                  this.customHomePage = true;
                  this.cService.customHomePage = true;

                } else {

                  this.getApiURL = environment.getStoryDashboardAPI;
                  this.getJSONData();
                  this.customHomePage = false;
                  this.cService.customHomePage = false;

                }

              }, err => {

                console.log(err);
                this.toastService.createMessage('error', err);

              }).add(() => {

                this.isLoaded = true;

              });

          }

        }

      }

    });

  }

  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();

  }

  /* Shell App Register Device Code Start */
  registerIOSDevice(deviceId) {

    console.log('IOS Device Registered We are in Story Landing Screen', deviceId);
    this.toastService.createMessage('success', 'The Device Has Been Succesfully registered -- STORY' + deviceId);

  }

  /* Shell App Register Device Code End */

  assignCopy() {

    this.filteredItems = Object.assign([], this.editorsListInit);

  }

  showEditorsModal(): void {

    console.log(this.userInfo.linkRoleDetailSource.value, this.editorsList);

    if (this.userInfo.linkRoleDetailSource.value?.toUpperCase() === this.userRoles.Editor && this.editorsList && !this.editorsList.length) {
      const loggedInUser = this.userInfo.linkUserDetailsSource.value;

      this.editorsList.push(loggedInUser);
      this.editorsListInit.push(loggedInUser);
      this.editorsListInit[0].allowAdd = this.functionAbility.fa_add_editor_on_duty ? true : this.checkAccess(loggedInUser);
      this.assignCopy();
    } else if (this.userInfo.linkRoleDetailSource.value?.toUpperCase() !== this.userRoles.Editor) {
      this.getGetUsers();

    }

    // NCX-1804 End
    this.isVisible = true;
    this.editorScreen = !this.editorScreen;
    this.editorText = '';

  }

  handleOk(): void {

    this.saveEditorList();
    this.editorScreen = !this.editorScreen;
    this.filteredItems = this.editorsList;

  }

  handleCancel(): void {

    this.isVisible = false;
    this.editorsOnDuty = JSON.parse(JSON.stringify(this.editorsOnDutyInit));
    this.filteredItems = this.editorsList;
    this.editorScreen = !this.editorScreen;
    this.editorText = '';
    this.modifiedUserIds = [];

  }

  onClose(id): void {

    let elementIndex;

    // tslint:disable-next-line:no-shadowed-variable
    this.editorsOnDuty.forEach((element, index) => {

      // tslint:disable-next-line:radix
      if (+element.userId === parseInt(id)) {

        elementIndex = index;

      }

    });
    if (elementIndex >= 0) {

      this.editorsOnDuty.splice(elementIndex, 1);

      // tslint:disable-next-line:radix
      this.modifiedUserIds.push({ userId: (parseInt(id)), operation: 'REMOVED' });

    }

  }

  goToSlide(event) {

    if (event === 'next') {

      this.myCarousel.next();

    } else {

      this.myCarousel.pre();

    }

  }

  getValueChange(val) {

    console.log('value emitted is : ' + val);
    const colFilter = val.filters.split('|');

    this.pagecode = colFilter[0];
    this.type = colFilter[1];
    this.radioValue = colFilter[2];
    if (this.type === 'READY' && this.pagecode === 'STORY_TRENDING') {

      this.isReadyTrending = true;

    } else if (this.type === 'READY' && this.pagecode !== 'STORY_TRENDING') {

      this.readyArray = val.resp;
      this.isReadyTrending = false;

    }
    if (this.type === 'WORKING' && this.pagecode === 'STORY_TRENDING') {

      this.isWorkingTrending = true;

    } else if (this.type === 'WORKING' && this.pagecode !== 'STORY_TRENDING') {

      this.isWorkingTrending = false;
      this.workingArray = val.resp;

    }
    if (this.isReadyTrending || this.isWorkingTrending) {

      this.pagecode = 'STORY_TRENDING';

    } else {

      this.pagecode = 'STORY';

    }
    this.wS.connect(this.pagecode);

  }

  formatHeaderIcons() {

    return window.innerWidth <= 1200;

  }

  addEditors(id) {

    // tslint:disable-next-line:radix
    const ifExist = this.editorsOnDuty.filter((editor) => +editor.userId === parseInt(id.userId));

    if (this.editorsOnDuty.length < 3 && ifExist.length === 0) {

      const user: User = {
        userId: id.userId,
        firstName: id.firstName,
        lastName: id.lastName,
        jobTitle: id.jobTitle,
        profilePictureURL: id.profilePictureURL,
        displayName: id.displayName,
        workingEmail: '',
        ssoId: 0,
        role: ''
      };

      this.editorsOnDuty.push(user);
      this.modifiedUserIds.push({ userId: id.userId, operation: 'ADDED' });
      this.editorText = '';
      this.assignCopy();

    } else if (this.editorsOnDuty.length === 3) {

      this.toastService.createMessage('warning', 'There are already the maximum of 3 editors on duty');

    }

  }

  filterItems(event) {

    if (!event.target.value) {

      this.assignCopy();

    }
    setTimeout(() => {

      this.filteredItems = Object.assign([], this.editorsList).filter(
        (item) => {

          if (item.userId !== null && item.firstName !== null) {

            item.allowAdd = this.functionAbility.fa_add_editor_on_duty ? true : this.checkAccess(item);
            const fullName = item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase();

            if (fullName.includes(this.editorText.toLowerCase())) {

              return item;

            }

          }

        }
      );

    }, 100);

  }

  /*API Endpoints for CRUD Users and Editors on Duty Start */
  getJSONData() {

    this.isLoaded = false;
    const queryStrRole = '?role=SR_EDITOR';

    this.cService.serviceRequestCommon('get', this.getApiURL, queryStrRole).subscribe((res: any) => {

      if (res) {

        if (this.customHomePage === true) {

          this.cService.customHomePage = true;
          res = res.sort((a, b) => {

            return a.columnNumber - b.columnNumber;

          });
          res.forEach(async (element, index) => {

            if (element.categoryName === 'Activity') {

              console.log(index);
              console.log(new Date());
              const queryStr2 = `?ids=${element.column.storyIds}&tags=${element.column.tags}&` +
                `labels=${element.column.labels}&postType=${element.column.postType}&` +
                `contentPage=1&topics=${element.column.topics}&searchString=`;

              this.cService.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr2).subscribe((subres: any) => {

                res[index].stories = subres.stories;

              }, () => {

                this.isLoaded = true;

              });

            }

          });
          this.CustomArray = res;
          this.isLoaded = true;
          console.log('Custom Array:', this.CustomArray);

        } else {

          this.cService.customHomePage = false;
          if (res.workingStories) {

            this.workingArray = [...res.workingStories.stories];
            this.workingCount = res.workingStories.totalCount;

          }
          if (res.submittedStories) {

            this.submittedArray = [...res.submittedStories.stories];
            this.submittedCount = res.submittedStories.totalCount;

          }
          if (res.readyStories) {

            this.readyArray = [...res.readyStories.stories];
            this.readyCount = res.readyStories.totalCount;

          }

        }

      }
      this.getEditorsOnDuty();

    }, err => {

      this.toastService.createMessage('error', err);

    }).add(() => {

      this.isLoaded = true;
      this.isInitLoaded = true;

    });

  }

  showUserProfile(userId) {

    this.router.navigate(['ncx/profile/:' + userId]);

  }

  getEditorsOnDuty() {

    this.cService.serviceRequestCommon('get', this.getEditorURL).subscribe((res: any) => {

      this.editorsOnDuty = JSON.parse(JSON.stringify(res));
      this.editorsOnDutyInit = JSON.parse(JSON.stringify(res));
      console.log('editors on duty', this.editorsOnDuty);

    }, () => {

      console.log(`ERR: ${this.getEditorURL}`);

    });

  }

  getGetUsers() {

    this.isLoaded = false;
    const queryStrRole = '?onDuty=false';

    this.cService.serviceRequestCommon('get', this.getEditorURL, queryStrRole).subscribe((res: any) => {

      console.log('users', res);
      this.isLoaded = true;
      this.editorsList = JSON.parse(JSON.stringify(res));
      this.editorsListInit = JSON.parse(JSON.stringify(res));
      this.editorsListInit.filter(user => {

        user.allowAdd = this.functionAbility.fa_add_editor_on_duty ? true : this.checkAccess(user);

      });
      this.assignCopy();

    }, () => {

      console.log(`ERR: ${this.getEditorURL}`);
      this.isLoaded = true;

    });

  }

  saveEditorList() {

    console.log('saveEditorList : ', this.modifiedUserIds);
    if (this.modifiedUserIds && this.modifiedUserIds.length === 0) {

      return;

    }
    const apiArray = [];

    const updatedIds = [];

    this.modifiedUserIds.forEach((editor) => {

      // tslint:disable-next-line:radix
      const userId = parseInt(editor.userId);

      updatedIds.push(userId);
      const payLoad = (editor.operation === 'ADDED') ? { onDuty: true, active: true } : { onDuty: false, active: true };

      const api = this.cService.serviceRequestCommon('put', environment.postProfileAPIURL, `${userId}`, payLoad);

      apiArray.push(api);

    });

    forkJoin([...apiArray]).subscribe(([...res]) => {

      if ([...res]) {

        this.toastService.createMessage('success', 'The Editor List has Been Successfully Updated');
        this.isVisible = false;
        this.modifiedUserIds = [];

      }

    }, (err) => {

      this.toastService.createMessage('error', (err) ? err : 'Error in saving Data please try again');

      // this.isVisible = false;

    });

  }

  /*API Endpoints for CRUD Users and Editors on Duty End */

  private checkAccess(user) {

    if (this.functionAbility.fa_add_self_editor_on_duty) {

      return (user.userId === this.userInfoDetails.userId) ? true : false;

    } else {

      return true;

    }

  }

  private updateDashBoard(data) {

    if (this.cService.customHomePage !== true) {

      if (data.story && data.pageCode !== 'STORY_TRENDING') {

        let checkAdd = false;

        let privateStoryWdAccess = true;

        // tslint:disable-next-line:max-line-length
        if ((data.story && data.story.storyAccess === 'private') && !(((data.story.createUser &&
          data.story.createUser.userId === Number(localStorage.getItem('userId')))
          || (this.functionAbility.fa_access_private_story)))) {

          privateStoryWdAccess = false;

        }
        if (data.story.storyState === 'SUBMITTED') {

          if (data.story.isDeleted) {

            this.removeStory('submittedArray', data.story.storyId);

          } else {

            if (!this.submittedStateForOthersCheck(data.story)) {

              const index = this.submittedArray.findIndex(story => story.storyId === data.story.storyId);

              if (index === -1) {

                checkAdd = true;

              } else if (index !== -1 && !this.checkPostDeleted(index, data.story.storyPosts, 'submittedArray')) {

                this.submittedArray.splice(index, 1);
                checkAdd = true;

              }

            } else {

              checkAdd = true;

            }
            if (checkAdd && privateStoryWdAccess) {

              this.submittedArray.unshift(data.story);

            }

          }

        } else if (data.story.storyState === 'WORKING') {

          if (data.story.isDeleted) {

            this.removeStory('workingArray', data.story.storyId);

          } else {

            if (!this.workingStateForOthersCheck(data.story)) {

              const index = this.workingArray.findIndex(story => story.storyId === data.story.storyId);

              if (index === -1) {

                checkAdd = true;

              } else if (index !== -1 && !this.checkPostDeleted(index, data.story.storyPosts, 'workingArray')) {

                this.workingArray.splice(index, 1);
                checkAdd = true;

              }

            } else {

              checkAdd = true;

            }
            if (checkAdd && privateStoryWdAccess) {

              this.workingArray.unshift(data.story);

            }

          }

        } else if (data.story.storyState === 'READY') {

          if (data.story.isDeleted) {

            this.removeStory('readyArray', data.story.storyId);

          } else {

            if (!this.readyStateForOthersCheck(data.story)) {

              const index = this.readyArray.findIndex(story => story.storyId === data.story.storyId);

              if (index === -1) {

                checkAdd = true;

              } else if (index !== -1 && !this.checkPostDeleted(index, data.story.storyPosts, 'readyArray')) {

                this.readyArray.splice(index, 1);
                checkAdd = true;

              }

            } else {

              checkAdd = true;

            }
            if (checkAdd && privateStoryWdAccess) {

              this.readyArray.unshift(data.story);

            }

          }

        }
        this.toastService.createMessage('success', 'New Story Updated/Added/Deleted');
        this.isLoaded = true;

      }
      if (data.pageCode === 'STORY_TRENDING' && this.pagecode === 'STORY_TRENDING') {

        // tslint:disable-next-line:max-line-length
        if (data.readyTrendingStoriesDto.detailsDtos && data.readyTrendingStoriesDto.detailsDtos.length > 0 && this.isReadyTrending) {

          if (this.type === 'READY' && this.radioValue === 'FOLLOWING') {

            const tempReadyArray = data.readyTrendingStoriesDto.detailsDtos;

            tempReadyArray.forEach((story) => {

              const index = story.storyFollower.findIndex(userid => userid === this.userInfoDetails.userId);

              if (index === -1) {

                tempReadyArray.splice(index, 1);

              }

            });
            this.readyArray = tempReadyArray;

          } else {

            this.readyArray = data.readyTrendingStoriesDto.detailsDtos;

          }
          this.toastService.createMessage('success', 'Trending Stories Updated for Ready State');

        }

        // tslint:disable-next-line:max-line-length
        if (data.workingTrendingStoriesDto.detailsDtos && data.workingTrendingStoriesDto.detailsDtos.length > 0 &&
          this.isWorkingTrending) {

          if (this.type === 'WORKING' && this.radioValue === 'FOLLOWING') {

            const tempWorkingArray = data.workingTrendingStoriesDto.detailsDtos;

            tempWorkingArray.forEach((story) => {

              const index = story.storyFollower.findIndex(userid => userid === this.userInfoDetails.userId);

              if (index === -1) {

                tempWorkingArray.splice(index, 1);

              }

            });
            this.workingArray = tempWorkingArray;

          } else {

            this.workingArray = data.workingTrendingStoriesDto.detailsDtos;

          }

          // this.workingArray.push(data.workingTrendingStoriesDto.detailsDtos);
          this.toastService.createMessage('success', 'Trending Stories Updated for Working State');

        }
        this.isLoaded = true;

      }

    } else {
      if (data.pageCode === 'STORY' || data.pageCode.indexOf('GROUPS_') === 0) {
        this.updateReadyWorkingAndCustomColumnsInDashboard(data);
      }

    }

  }

  //to find whether the story exists in the column
  isStoryExistsInColumn(item, data) {

    for (let i = 0; i < item.stories.length; i++) {

      const element = item.stories[i];

      if (element.storyId === data.story.storyId) {

        return true;

      }

    }
    return false;

  }

  //to remove the existing story from the corresponding column
  removeExistingStoryInColumn(item, data) {

    setTimeout(() => {

      item.stories = item.stories.filter(element => element.storyId !== data.story.storyId);

    }, 500);

  }

  //to remove the existing story from another column
  removeExistingStoryFromAnotherColumn(columnName, data) {

    const anotherColName = columnName === 'READY' ? 'WORKING' : 'READY';

    const item = this.CustomArray.find(col => anotherColName === col.columnName.toUpperCase());

    this.removeExistingStoryInColumn(item, data);

  }

  /* to update the story in the existing column
   * if the post is deleted and no more posts exists in the story then a ws update is triggered from story with empty posts  // data.story.storyPosts.length == 0
   * if the post is deleted and one or more posts exists in the story then a ws update is triggered from story with the latest post in the story // data.story.storyPosts[0]?.isDeleted == false
   * Also a separate ws update is triggered from the post with the isDeleted flag as true. it should not be updated in ready/working story column. This ws update should be processed only in custom column to remove the post from the column.
   */
  updateExistingStoryInColumn(item, data, privateStoryWdAccess) {

    item.stories.forEach((element, index) => {

      if (element.storyId === data.story.storyId && (data.story.storyPosts.length == 0 || data.story.storyPosts[0]?.isDeleted == false) && privateStoryWdAccess) {

        item.stories.splice(index, 1);
        item.stories.unshift(data.story);

      }

    });

  }

  //to find whether the post exists in the column
  isPostExistsInColumn(item, data) {

    for (let i = 0; i < item.stories?.length; i++) {

      const element = item.stories[i];

      if (element.storyPosts[0].postVersionGroupId === data.story.storyPosts[0].postVersionGroupId) {

        return true;

      }

    }
    return false;

  }

  //to remove the existing posts in the corresponding column
  //Not used. This function can be removed later
  // removeExistingPostsInColumn(item, data) {

  //   data.story.storyPosts.forEach((postInWS) => {

  //     item.stories.forEach((element, index) => {

  //       if (element.storyPosts[0].postVersionGroupId === postInWS.postVersionGroupId && postInWS.isDeleted === true) {

  //         item.stories.splice(index, 1);

  //       }

  //     });

  //   });

  // }

  //to update the existing posts in the corresponding column
  updateExistingPostsInColumn(item, data) {

    // data.story.storyPosts.forEach((postInWS, postInWSIndex) => {

    item.stories.forEach((element, index) => {

      if (element.storyPosts[0].postVersionGroupId === data.story.storyPosts[0].postVersionGroupId) {

        item.stories.splice(index, 1);
        if (data.story.storyPosts[0].isDeleted === false) {

          const postToBeUpdated = data.story.storyPosts[0];

          const updatedStory = {
            ...data.story,
            storyPosts: [postToBeUpdated]
          };

          item.stories.unshift(updatedStory);

        }

      }

    });

    // });

  }

  customColumnsPostChanges(item, data) {

    if (data.story?.isDeleted == true) {

      //If the story is deleted then all the posts corresponds to the story should be removed
      this.removeExistingStoryInColumn(item, data);

    }

    if (data.story.storyPosts?.length > 0) {

      //if the deleted flag is true then it should not be added because multiple web socket updates are coming in.
      //after the first ws update removes the post and if the deletion condition is not verified then the second ws update will insert the post
      if (!this.isPostExistsInColumn(item, data) && data.story.storyPosts[0].isDeleted === false) {

        //Add Post
        item.stories.unshift(data.story);

      } else {

        //When a story was updated then it will hold all the corresponding posts inside data.story.storyPosts
        //Loop through the posts and update all the story related details
        //Removing a post from column at the time of deletion is handled inside this function
        this.updateExistingPostsInColumn(item, data);

      }

    }

  }

  customColumnsGroupChanges(item, data) {

    if (data.group?.isDeleted == true) {

      //Delete Group. If the group is deleted then all the discussions corresponds to the group should be removed
      this.removeExistingGroupInColumn(item, data);

    }

    if (data.group.groupDiscussions?.length > 0) {

      //if deleted flag is true then it should not be added because multiple web socket updates are coming in.
      //if the first ws update removes the discussion and if the deletion condition is not verified then the second ws update will insert the
      // discussion
      if (!this.isDiscussionExistsInColumn(item, data) && data.group.groupDiscussions[0].isDeleted === false) {

        //Add Group
        item.discussions.unshift(data.group.groupDiscussions[0]);

      } else {

        this.updateExistingGroupsInColumn(item, data);

      }

    }

  }

  //to remove the existing group from the corresponding column
  removeExistingGroupInColumn(item, data) {

    setTimeout(() => {

      item.discussions = item.discussions?.filter(element => element.groupId !== data.group.groupId);

    }, 500);

  }

  //to find whether the discussion exists in the column
  isDiscussionExistsInColumn(item, data) {

    for (let i = 0; i < item.discussions?.length; i++) {

      const element = item.discussions[i];

      if (element.discussionVersionGroupId === data.group.groupDiscussions[0].discussionVersionGroupId) {

        return true;

      }

    }
    return false;

  }

  //to update the existing groups in the corresponding column
  updateExistingGroupsInColumn(item, data) {

    data.group.groupDiscussions.forEach((discussionInWS) => {

      item.discussions.forEach((element, index) => {

        if (element.discussionVersionGroupId === discussionInWS.discussionVersionGroupId) {

          item.discussions.splice(index, 1);
          discussionInWS.groupName = data.group.groupName;
          if (discussionInWS.isDeleted === false) {

            item.discussions.unshift(discussionInWS);

          }

        }

      });

    });

  }

  updateReadyWorkingAndCustomColumnsInDashboard(data) {

    try {

      let privateStoryWdAccess = true;

      // tslint:disable-next-line:max-line-length
      if ((data.story && data.story.storyAccess === 'private') && !(((data.story.createUser &&
        data.story.createUser.userId === Number(localStorage.getItem('userId')))
        || (this.functionAbility.fa_access_private_story)))) {

        privateStoryWdAccess = false;

      }
      this.CustomArray.forEach((item) => {

        if (item.categoryName === 'Story' && data.story?.storyState.toUpperCase() === item.columnName.toUpperCase()) {

          //if the state of the story is changed from Ready to Working or Vice versa then it has to be removed from the other column first
          this.removeExistingStoryFromAnotherColumn(item.columnName.toUpperCase(), data);

          //if it is a new story to this column the it has to be added to the corresponding column
          if (!this.isStoryExistsInColumn(item, data) && privateStoryWdAccess) {

            item.stories.unshift(data.story);

          } else {

            //if the story is deleted then it has to be removed from the corresponding column
            if (data.story?.isDeleted == true) {

              this.removeExistingStoryInColumn(item, data);

            } else {

              //if the story is updated then it has to be updated in the corresponding column
              this.updateExistingStoryInColumn(item, data, privateStoryWdAccess);

            }

          }

        }
        if (item.categoryName === 'Activity' && data.story) { //custom Column for post filter

          const activityFilters = this.frameActivityFilter(item.column);

          const activityFilter = this.getfilterActivityMethod(item.column, activityFilters);

          if (activityFilter(data)) {

            this.customColumnsPostChanges(item, data);

          }

        } else if (item.categoryName === 'Person' && data.story) { //custom column for person filter

          const personFilters = this.framePersonFilter(item.column);

          const personFilter = this.getfilterPersonMethod(item.column, personFilters);

          if (personFilter(data)) {

            this.customColumnsPostChanges(item, data);

          }

        } else if (item.categoryName === 'Group' && data.group !== null && data.group !== '' && data.group !== undefined) {

          const groupFilters = this.frameGroupFilter(item.column);

          const groupFilter = this.getfilterGroupMethod(item.column, groupFilters);

          if (groupFilter(data)) {

            this.customColumnsGroupChanges(item, data);

          }

        }

      });

    } catch (ex) {

      console.error(ex);

    }

  }

  private checkPostDeleted(index, post, statusArray) {

    if (post.length > 0 && post[0].isDeleted) {

      const postIndex = this[statusArray][index].storyPosts.findIndex(postS => postS.postVersionGroupId === post[0].postVersionGroupId);

      if (postIndex !== -1) {

        this[statusArray][index].storyPosts.splice(postIndex, 1);
        return true;

      } else if (this[statusArray][index].storyPosts.length > 0) {

        return true;

      }
      return false;

    }
    return false;

  }

  private removeStory(statusArray, storyId) {

    const index = this[statusArray].findIndex(story => story.storyId === storyId);

    if (index !== -1) {

      this[statusArray].splice(index, 1);

    }

  }

  private submittedStateForOthersCheck(incomeStory) {

    let returnCheck = false;

    if (incomeStory.storyState === 'SUBMITTED') {

      const index = this.workingArray.findIndex(story => story.storyId === incomeStory.storyId);

      if (index !== -1) {

        this.workingArray.splice(index, 1);
        returnCheck = true;

      } else {

        const indexReady = this.readyArray.findIndex(story => story.storyId === incomeStory.storyId);

        if (indexReady !== -1) {

          this.readyArray.splice(indexReady, 1);
          returnCheck = true;

        }

      }

    }
    return returnCheck;

  }

  private workingStateForOthersCheck(incomeStory) {

    let returnCheck = false;

    if (incomeStory.storyState === 'WORKING') {

      const index = this.submittedArray.findIndex(story => story.storyId === incomeStory.storyId);

      if (index !== -1) {

        this.submittedArray.splice(index, 1);
        returnCheck = true;

      } else {

        const indexReady = this.readyArray.findIndex(story => story.storyId === incomeStory.storyId);

        if (indexReady !== -1) {

          this.readyArray.splice(indexReady, 1);
          returnCheck = true;

        }

      }

    }
    return returnCheck;

  }

  private readyStateForOthersCheck(incomeStory) {

    let returnCheck = false;

    if (incomeStory.storyState === 'READY') {

      const index = this.submittedArray.findIndex(story => story.storyId === incomeStory.storyId);

      if (index !== -1) {

        this.submittedArray.splice(index, 1);
        returnCheck = true;

      } else {

        const indexWorking = this.workingArray.findIndex(story => story.storyId === incomeStory.storyId);

        if (indexWorking !== -1) {

          this.workingArray.splice(indexWorking, 1);
          returnCheck = true;

        }

      }

    }
    return returnCheck;

  }

  openCustomColumnDrawer() {

    if (this.CustomArray.length > 12) {

      this.toastService.createMessage('error', 'You have reached max coustom column limit');
      return;

    }

    this.customColumnDrawerOpen = true;

    let top = document.getElementById('target');

    if (top !== null) {

      top.scrollIntoView();
      top = null;

    }

  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.isPointerOverContainer) {

      if (event.previousContainer === event.container) {

        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );

        const userId = localStorage.getItem('userId');

        const queryStrUserID = `/${userId}`;

        const personalizedColumnsList = event.container.data;

        personalizedColumnsList.forEach((item, index) => {

          // tslint:disable-next-line:no-string-literal
          personalizedColumnsList[index]['columnNumber'] = index + 1;

        });

        this.cService.serviceRequestCommon('get', environment.getProfileAPIURL, queryStrUserID).subscribe((res: any) => {

          res.personalizedColumns = res.personalizedColumns.sort((a, b) => {

            return a.columnNumber - b.columnNumber;

          });
          const PreColNum = res.personalizedColumns[event.previousIndex].columnNumber;

          const CurColNum = res.personalizedColumns[event.currentIndex].columnNumber;

          res.personalizedColumns[event.currentIndex].columnNumber = PreColNum;
          res.personalizedColumns[event.previousIndex].columnNumber = CurColNum;

          res.personalizedColumns.forEach((item, index) => {

            personalizedColumnsList.forEach((subitem) => {

              // tslint:disable-next-line:no-string-literal
              if (item.columnName === subitem['columnName']) {

                // tslint:disable-next-line:no-string-literal
                res.personalizedColumns[index].columnNumber = subitem['columnNumber'];

              }

            });

          });

          this.cService.serviceRequestCommon('put', environment.getProfileAPIURL, queryStrUserID, res).subscribe(() => {
          }, () => {

            this.isLoaded = true;

          });

        });

      } else {

        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        console.log('dd2');

      }

    } else {

      console.log('Dropped outside area.');

    }

  }

  dragDropped(event: CdkDragDrop<string[]>, item) {

    this.isPointerOverContainer = event.isPointerOverContainer;
    console.log('dragDropped');
    console.log(item);

  }

  pageSizeChanged(event) {

    console.log(event);

  }

  pageIndexfun(res: boolean = false) {

    if (res) {

      this.pageIndex = 1;

    }
    console.log(this.pageIndex);
    console.log(this.pageSize);
    this.listOfData = this.CustomArray;

  }

  frameActivityFilter(activityColumn: any) {

    const filter = { isStoryIncluded: false, isPostTypeIncluded: false, isTopicIncluded: false, isLabelIncluded: false, isTagIncluded: false };

    filter.isStoryIncluded = activityColumn.storyIds?.length > 0 ? true : false;

    filter.isPostTypeIncluded = activityColumn.postType !== null ? true : false;

    filter.isTopicIncluded = activityColumn.topics?.length > 0 ? true : false;

    filter.isLabelIncluded = activityColumn.labels?.length > 0 ? true : false;

    filter.isTagIncluded = activityColumn.tags?.length > 0 ? true : false;

    return filter;

  }

  /*
   * getfilterActivityMethod() will return the complete code inside with the values of activityColumn and filter in the variable activityFilter
   * when the activityFilter condition is validated then the code will be executed by applying the values that is passed and return a boolean value
   * by default all the filter variables are set to true
   * if the selected/included filter condition is true then the variable is set to false which means the filter is selected but the existence of value has to be verified. Then based on the existence of selected filter value the variable is set to true.
   * if all the values are true then the web socket update belongs to this column
   */
  getfilterActivityMethod(activityColumn: any, filter: any) {

    const activityFilter = (data: any): boolean => {

      let {
        isFilteredStoryExist,
        isFilteredPostTypeExist,
        isFilteredTopicExist,
        isFilteredLabelExist,
        isFilteredTagExist
      } = {
        isFilteredStoryExist: true,
        isFilteredPostTypeExist: true,
        isFilteredTopicExist: true,
        isFilteredLabelExist: true,
        isFilteredTagExist: true
      };

      if (filter.isStoryIncluded && activityColumn.storyIds?.length > 0) {

        isFilteredStoryExist = false;
        isFilteredStoryExist = activityColumn.storyIds.includes(data.story.storyId.toString());

      }

      if (filter.isPostTypeIncluded && data.story.storyPosts?.length > 0) {

        isFilteredPostTypeExist = false;
        isFilteredPostTypeExist = (activityColumn.postType === data.story.storyPosts[0].postType || activityColumn.postType === 'All') ? true : false;

      }
      if (filter.isTopicIncluded && activityColumn.topics?.length > 0) {

        isFilteredTopicExist = false;
        for (const element of activityColumn.topics) {

          isFilteredTopicExist = data.story.storyPosts[0].topics?.hasOwnProperty(element.toString())
          if (isFilteredTopicExist == true) {

            break;

          } //if atleast one value exist then it is true and loop has to be stopped

        }

      }
      if (filter.isLabelIncluded && activityColumn.labels?.length > 0) {

        isFilteredLabelExist = false;
        for (const element of activityColumn.labels) {

          isFilteredLabelExist = data.story.storyPosts[0].postLegal?.includes(element.toString()) ||
            data.story.storyPosts[0].editorialStatus?.includes(element.toString());
          if (isFilteredLabelExist == true) {

            break;

          } //if atleast one value exist then it is true and loop has to be stopped

        }

      }
      if (filter.isTagIncluded && activityColumn.tags?.length > 0) {

        isFilteredTagExist = false;
        for (const element of activityColumn.tags) {

          isFilteredTagExist = data.story.storyPosts[0].tags?.includes(element.toString()) ||
            data.story.storyPosts[0].postContentTags?.includes(element.toString());
          if (isFilteredTagExist == true) {

            break;

          } //if atleast one value exist then it is true and loop has to be stopped

        }

      }
      return (isFilteredStoryExist && isFilteredPostTypeExist && isFilteredTopicExist && isFilteredLabelExist && isFilteredTagExist);

    };

    return activityFilter;

  }

  framePersonFilter(personColumn: any) {

    const filter = { isPersonIncluded: false };

    filter.isPersonIncluded = personColumn.personIds?.length > 0 ? true : false;
    return filter;

  }

  getfilterPersonMethod(personColumn: any, filter: any) {

    const personFilter = (data: any): boolean => {

      let isFilteredPersonExist = false;

      if (filter.isPersonIncluded && personColumn.personIds?.length > 0) {

        isFilteredPersonExist = personColumn.personIds.includes(data.story.storyPosts[0]?.createUser.ssoId.toString()) ||
          personColumn.personIds.includes(data.story.storyPosts[0]?.updateUser.ssoId.toString());

      }
      return isFilteredPersonExist;

    };

    return personFilter;

  }

  frameGroupFilter(groupColumn: any) {

    const filter = { isGroupIncluded: false };

    filter.isGroupIncluded = groupColumn.groupIds?.length > 0 ? true : false;
    return filter;

  }

  getfilterGroupMethod(groupColumn: any, filter: any) {

    const groupFilter = (data: any): boolean => {

      let isFilteredGroupExist = false;

      if (filter.isGroupIncluded && groupColumn.groupIds?.length > 0) {

        isFilteredGroupExist = groupColumn.groupIds.includes(data.group.groupId.toString());

      }
      return isFilteredGroupExist;

    };

    return groupFilter;

  }

  frameStoryFilter(dashBoardColumn: any) {

    const filterObject = {
      isPostTypeFilterNeeded: false,
      isTopicFilterNeeded: false,
      isLabelFilterNeeded: false,
      isTagFilterNeeded: false
    };

    if (dashBoardColumn.column) {

      if (dashBoardColumn.column.postType && dashBoardColumn.column.postType !== null) {

        filterObject.isPostTypeFilterNeeded = true;

      }
      if (dashBoardColumn.column.topics && dashBoardColumn.column.topics.length > 0) {

        filterObject.isTopicFilterNeeded = true;

      }
      if (dashBoardColumn.column.labels && dashBoardColumn.column.labels.length > 0) {

        filterObject.isLabelFilterNeeded = true;

      }
      if (dashBoardColumn.column.tags && dashBoardColumn.column.tags.length > 0) {

        filterObject.isTagFilterNeeded = true;

      }

    }
    return filterObject;

  }

  getfilterStoryMethod(dashBoardColumn: any, filterObject: any) {

    const storyFilter = (data: any): boolean => {

      let postTypeFilterResult = true;

      let topicFilterResult = true;

      let labelsFilterResult = true;

      let tagsFilterResult = true;

      if (dashBoardColumn.column) {

        if (filterObject.isPostTypeFilterNeeded && data.story && data.story.storyPosts[0]) {

          postTypeFilterResult = false;
          if (data.story.storyPosts[0]) {

            if (dashBoardColumn.column.postType === null || dashBoardColumn.column.postType.toUpperCase() === 'ALL' ||
              dashBoardColumn.column.postType === data.story.storyPosts[0].postType) {

              postTypeFilterResult = true;

            }

            // else {
            //   postTypeFilterResult = false;
            // }

          }

        }
        if (filterObject.isTopicFilterNeeded) {

          if (dashBoardColumn.column.topics && dashBoardColumn.column.topics.length > 0) {

            topicFilterResult = false;
            dashBoardColumn.column.topics.forEach((element) => {

              if (data.story.storyPosts[0]) {

                if (data.story.storyPosts[0] && data.story.storyPosts[0].topics && data.story.storyPosts[0].topics !== null) {

                  const keys = Object.keys(data.story.storyPosts[0].topics);

                  keys.forEach((item) => {

                    if (item.toString() === element.toString()) {

                      topicFilterResult = true;

                    }

                  });

                }

              }

            });

          }

        }
        if (filterObject.isLabelFilterNeeded) {

          if (dashBoardColumn.column.labels && dashBoardColumn.column.labels.length > 0) {

            labelsFilterResult = false;
            dashBoardColumn.column.labels.forEach((element) => {

              if (data.story.storyPosts[0]) {

                if ((data.story.storyPosts[0].postLegal && data.story.storyPosts[0].postLegal.includes(element.toString())) ||
                  (data.story.storyPosts[0].editorialStatus &&
                    data.story.storyPosts[0].editorialStatus.includes(element.toString()))) {

                  labelsFilterResult = true;

                }

              }

              // else {
              //   labelsFilterResult = false;
              // }

            });

          }

        }
        if (filterObject.isTagFilterNeeded) {

          if (dashBoardColumn.column.tags && dashBoardColumn.column.tags.length > 0) {

            tagsFilterResult = false;
            dashBoardColumn.column.tags.forEach((element) => {

              if (data.story.storyPosts[0]) {

                if ((data.story.storyPosts[0].tags && data.story.storyPosts[0].tags.includes(element.toString())) ||
                  (data.story.storyPosts[0].postContentTags &&
                    data.story.storyPosts[0].postContentTags.includes(element.toString()))) {

                  tagsFilterResult = true;

                }

                // else {
                //   tagsFilterResult = false;
                // }

              }

            });

          }

        }

      }
      return (postTypeFilterResult && topicFilterResult && labelsFilterResult && tagsFilterResult);

    };

    return storyFilter;

  }

  /**
   * Display 'Who's On' Editor Name
   *
   */
  editorName(editor: User, isLast: boolean): string {

    const name = Common.formatName(editor, true);

    return isLast ? `${name}` : `${name},\xa0`;

  }

  closeCustomColumnDrawer() {

    this.customColumnDrawerOpen = false;

  }

  closeCustomColumnEditDrawer() {

    this.customColumnEditDrawerOpen = false;

  }

  openCustomColumnEditDrawer(columnNumber: number) {

    this.columnEditing = columnNumber;
    this.customColumnEditDrawerOpen = true;

  }

  getUserName(user: User): string {

    return Common.formatName(user, true);

  }

  get isNavDrawerClosed(): boolean {

    return this.cService.isNavDrawerClosed.value;

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }


}
