import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngleSearchResult } from '@models/ncx/global-search';
import { BreakpointService } from '@services/breakpoint.service';
import { NavigationService } from '@services/navigation-service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Time } from '@utilities/time';

@Component({
  selector: 'ncx-angle-search-new',
  templateUrl: './angle-search.component.html',
  styleUrls: ['../../cards-common.scss', './angle-search.component.scss']
})

export class AngleSearchNewComponent {

  @Input() angleDetail!: AngleSearchResult;

  @Output() viewAngle = new EventEmitter();

  constructor(
    private breakpointService: BreakpointService,
    private urlRedirectService: UrlRedirectService,
    private navigationService: NavigationService
  ) { }

  /**
   * Return 'true' if a mobile app in order for the link to open up into
   * the same window.  If on a browser, open into a new window
   *
   */
  onViewAngle() {

    if (this.navigationService.isIOSMobileApp.value) {

      this.viewAngle.emit(this.angleDetail);
      return true;

    } else {

      this.viewAngle.emit(this.angleDetail);
      window.open(this.urlRedirectService.getRedirectURL(this.angleDetail.angleURL), '_blank');
      return false;

    }

  }


  calculateDays(apiDate) {

    return Time.calculateDaysBetweenGivenUtcAndToday(apiDate)

  }

}
